<template>
    <v-card style="white-space: pre-line;">
        <v-card-title class="text-h5 grey lighten-2">
          {{$t('DEVELOPMENTS.INFORMATION')}}
        </v-card-title>

        <v-card-text>
            {{message}}
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
            <v-btn @click="okResponse" small depressed color="secondary" form="type-form" type="submit" class="px-0">
            Ok
        </v-btn>
        </v-card-actions>
            
    </v-card>
</template>

<script>
export default {
    name: 'DialogModalInformation',
    props: {
        message: String,
    },
    methods:{
        okResponse(){
            this.$emit('ok')
        }
    }
}
</script>
