<template>
    
    <v-speed-dial
      v-model="fab"
      :top="top"
      :bottom="bottom"
      :right="right"
      :left="left"
      :direction="direction"
      :open-on-hover="hover"
      :transition="transition"
    >
      <template v-slot:activator>
          
        <v-btn
          v-model="fab"       
          color="transparent"
          tile
          fab
          large
        >
          <v-icon v-if="fab" color="white">
            mdi-close
          </v-icon>
          <v-icon v-else  color="white">
            mdi-cog-outline
          </v-icon>
        </v-btn>
      </template>
       <VueCustomTooltip :label="getHiglighted == 0 ? $t('TOOLTIPS.HIGLIGHT'): $t('TOOLTIPS.HIGLIGHT_REMOVE')" position="is-top">
      <v-btn
        fab
        color="primary"
        large
      >
            <v-icon color="white" v-if="getHiglighted == 0" large @click="highlight(1)">mdi-star-plus</v-icon>  
            <v-icon color="yellow" v-else large @click="highlight(0)">mdi-star-minus</v-icon>  
           
          
      </v-btn>
      </VueCustomTooltip>
      
      <VueCustomTooltip v-if="block != 'condominiums'" :label="getLowCost == 0 ? $t('TOOLTIPS.LOW_COST'): $t('TOOLTIPS.LOW_COST_REMOVE')" position="is-top">
      <v-btn
        fab
        large
        color="primary"
      >
        <v-icon color="white" v-if="getLowCost == 0" large @click="lowCostChange(1)">mdi-sale</v-icon>  
        <v-icon color="green darken-2" v-else large @click="lowCostChange(0)">mdi-currency-usd-off</v-icon>  

      </v-btn>
      </VueCustomTooltip>


    </v-speed-dial>
</template>

<script>
  export default {
    props:{
        highlighted: Number,
        lowCost: Number,
        block: String
    },
    data: () => ({
      direction: 'right',
      fab: false,
      fling: false,
      hover: true,
      tabs: null,
      top: true,
      right: true,
      bottom: false,
      left: false,
      transition: 'scale-transition',
    }),
    methods:{
        highlight(h){
            this.$emit('highlight', h)
        },
        lowCostChange(l){
            this.$emit('low-cost', l)
        }
    },
    computed:{
        getHiglighted(){
            return this.highlighted
        },
        getLowCost(){
            return this.lowCost
        }        
    },

    watch: {
      top (val) {
        this.bottom = !val
      },
      right (val) {
        this.left = !val
      },
      bottom (val) {
        this.top = !val
      },
      left (val) {
        this.right = !val
      },
    },
  }
</script>