<template>
        <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{$t('Export.Caption')}}
        </v-card-title>

        <v-card-text v-if="action == 'export'">
            {{$t('Export.SubTitle')}}
        </v-card-text>
        <v-card-text v-else>
            <h2>{{$t('Export.ATENTION')}}</h2>
            <v-divider class="mb-4"></v-divider>
            {{$t('Export.ATENTION_DESCRIPTION')}}
            <v-spacer class="mb-4"></v-spacer>
            
            {{$t('Export.SubTitleUpdate')}}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn depressed color="secondary" @click="changePlace($t('Export.Imovirtual'))">
                    {{ $t('Export.Imovirtual') }}
            </v-btn>
            <v-btn depressed color="secondary" @click="changePlace($t('Export.Idelista'))" class="ml-2">
                {{ $t('Export.Idelista') }} 
            </v-btn>
            <v-btn depressed color="secondary" @click="changePlace($t('Export.James'))" class="ml-2">
                {{ $t('Export.James') }} 
            </v-btn>
            <v-btn @click="$emit('close-me')" color="#D8D8D8" class="backBTN mr-2" depressed>
                <i class="zwicon-sign-out"></i>{{$t('INBOX.MODAL.CLOSE')}}   
            </v-btn>

        </v-card-actions>

       
      </v-card>
</template>
<script>
export default {
    name: 'PortalExporterModal',
    props:{
        action: String
    },
    data(){
        return{
            placeSelected: '',
            loadingData: true,
            hasLocation: false,
            hasMedia: false
        }
    },
    async created(){
        this.loadingData = false
    },
    methods:{
        changePlace(t){
            this.placeSelected = t
            this.exportProperty()
        },
        exportProperty(){
            this.$emit('export', this.placeSelected)
        }
    }
}
</script>
