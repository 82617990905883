<template>
    <v-container class="pt-0">
        <v-row v-if="dataLoading">
            <v-col xl="10" lg="8" cols="8" >
              <p class="page-title text-capitalize">{{ $t('SERVICES.SERVICE_DESCRIPTION_TITLE') }}</p>
              <p class="page-subtitle"> {{ $t('SERVICES.SERVICE_DESCRIPTION_DESCRIPTION') }}</p>
            </v-col>
        </v-row>
        <v-layout row wrap class="v-manage-property">
            
            <v-col cols="12">
                <v-card flat :loading="loadingItem">
                    
                    <v-toolbar flat class="mode-header" style="background-color: #BE5E3B !important;">
                        <v-toolbar-title
                                v-for="(language, index) in languages"
                                :key="index"
                                class="cursor-pointer mr-5"
                                :class="language.id === locale ? 'white--text font-weight-medium' : 'darkgray--text font-weight-light unselected-language-style'"
                                @click="locale = language.id"
                        >
                            {{ language.name }}
                        </v-toolbar-title>
                    </v-toolbar>
                    <div class="ma-8 mb-0 pb-0">
                        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
                            <form id="service-form" @submit.prevent="handleSubmit(submit)">
                                <v-row>
                                    <!-- Translatable content -->
                                    <v-col cols="12" class="pt-4 pb-0" v-if="loadingItem">
                                        <v-window
                                                v-model="locale"
                                                vertical
                                        >
                                            <v-window-item
                                                    v-for="language in languages"
                                                    :key="language.slug"
                                                    :ref="`window_${language.id}`"
                                                    :class="`window_${language.id}`"
                                            >

                                            <div  v-bind:style="language.slug=='pt' ? 'border-color:#4CAF50;border-top-style:solid;border-radius: 5px;' : ''">
                                                
                                                <!-- Service Title -->
                                                  <input-textarea
                                                          :name="`name[${language.slug}]`"
                                                          :label="$t('SERVICES.DESCRIPTION')"
                                                          :rows="6"
                                                          class="pb-1"
                                                          v-model="service.description[language.slug]"
                                                          :fill="service.description[language.slug]"
                                                          @blur="onBlurAction"
                                                  >
                                                  </input-textarea>
                                                  <!-- end -->
                                                  
                                                  
                                            </div>

                                            </v-window-item>
                                        </v-window>
                                    </v-col>
                                </v-row>
                            </form>
                        </ValidationObserver>
                    </div>
                    <v-card flat>
                    <!-- Saving Action -->
                    <v-card-actions class="light-bg py-4 px-4">
                       <v-spacer></v-spacer>
                        <v-btn depressed color="primary" form="service-form" type="submit" @click="supportValidation">
                            {{$t('SERVICES.SAVE')}}
                        </v-btn>
                    </v-card-actions>
                    <!-- end -->
                  
                  </v-card>
                </v-card>
            </v-col>
        </v-layout>
    </v-container>
</template>
<script>
  import mixin from '@/models/services/ServicesModel'
  import { ServiceDescriptionForm } from '@/store/structs/service'
 export default {
    name: 'ServiceDescription',
    mixins: [mixin],
    data(){
        return{
            dataLoading: false,
            loadingItem: false,
            languages: [],
            locale: 0,
            service: ServiceDescriptionForm
        }
    },
    async created(){
        this.languages = this.$t('LANGUAGES')
        await this.getServiceDescription()
        this.service = new ServiceDescriptionForm(this.$store.state.servicesModule.service_description[0])
        this.dataLoading = true
        this.loadingItem = true
    },
    methods:{
        supportValidation() {
        let f = this.service
        let l = null
        this.languages.forEach(function(entry) {
          if (f.title[entry.slug].length < 5) {
            l = entry.id
          }
        })
        this.locale = l
      },      
      onBlurAction(){
        this.submit()
      },
      async submit() {
          const isValid = await this.$refs.observer.validate()
        if (isValid) {
          await this.saveServiceDescription(this.service)
        }
      }
    },
    computed:{
        getTitle(){
            return this.$route.meta.title.toLowerCase()
        },
        getSubtitle(){
            return this.$route.meta.subtitle
        },
    }
 }
</script>
