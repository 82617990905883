<template>
    <v-card flat class="mt-3">
        <div class="py-3 px-4">
            <v-card-title class="subtitle-2 font-weight-medium">{{$t('DASHBOARD.RECENTLY_DEVELOPMENTS')}}</v-card-title>
            <v-card-subtitle class="v-card__subtitle caption text-left font-weight-medium secondaryCard">
            </v-card-subtitle>

            <div class="px-5 pb-4">
                <v-data-table
                        class="table__minimal recent-properties elevation-0"
                        :loading="dataTableLoading"
                        :items="items"
                        :options.sync="pagination"
                        :headers="headers"
                        loading-text="Loading... Please wait"
                        :hide-default-footer="true"
                        :calculate-widths="true"
                        :items-per-page="5"
                >
                    <template v-slot:[`item.image`]="{ item }">
                        <v-img
                                :src="$mediaFile('condominiums', item.id, item.cover)"
                                :lazy-src="$mediaFile('condominiums', item.id, item.cover)"
                                height="125"
                                width="125"
                                class="grey lighten-2 d-block rounded"
                        ></v-img>
                    </template>
                    <template v-slot:[`item.information`]="{ item }">
                        <div class="main__info">
                            <p class="mb-0">{{ item.information.title.en }}</p>
                            <p class="mb-0 font-weight-medium black--text listing-sub-sub-title">{{ $processLanguage(item.information.description).slice(0, 150) }} (...)</p>  
                          </div>
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                         <div class="main__info" style="width: auto;">
                            <p class="mb-0 caption">
                                <span class="black--text">{{$t('DASHBOARD.PRICE')}}</span>
                            </p>
                            <p class="mb-0 text-limit-liner black--text">{{ $currency(item.starting_price)  }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.view`]="{ item }">
                        <v-btn :to="`condominiums/edit/${item.id}/general`" class="secondary pa-0 ma-0" depressed small>
                            <i class="zwicon-pencil"></i>
                        </v-btn>
                    </template>
                </v-data-table>
            </div>
        </div>
    </v-card>
</template>

<script>
  import mixin from '@/models/condominium/CondominiumsModel'
  import { Condominium } from '@/store/structs/condominium'

  export default {
    name: 'LastCondominiums',

    mixins: [mixin],

    data: () => ({
      dataTableLoading: true,
      pagination: {},
      headers: [
        { value: 'image', width: 50, align: 'img' },
        { value: 'information', align: 'left',  },
        { value: 'price', align: 'left' },
        { value: 'view', width: 70, align: 'img' }
      ]
    }),

    watch: {
      pagination: {
        async handler() {
          try {
            this.dataTableLoading = true
            await this.getCondominiums()
            this.dataTableLoading = false
          } catch (error) {
            this.dataTableLoading = false
          }
        },
        deep: true
      }
    },

    computed: {
      items() {
        let retrievedData = this.$store.state.condominiumsModule.condominiums
        return Object.keys(retrievedData).map(k =>
          new Condominium(retrievedData[k])
        )
      }
    }
  }
</script>
