<template>
<v-container fluid class="pt-0 mr-10 ml-10">
        <v-layout row wrap class="v-manage-property">
            <v-col cols="12">
                <v-card flat>

                    <v-toolbar flat class="mode-header" style="background-color: #BE5E3B !important;">
                      <v-col xl="10" lg="4" cols="4" >
                          <v-toolbar-title class="pl-0 mt-4 pt-0 text-left cursor-pointer mr-5 white--text text-capitalize" >
                            {{ $t('USERS.TITLE') }}
                          </v-toolbar-title>
                          <v-card-subtitle class="pl-0 page-subtitle lightgrey--text">{{ $t('USERS.SUBTITLE') }}</v-card-subtitle>
                        </v-col>
                    </v-toolbar>



                    <div class="ma-8 mb-4 pb-8">
                        <ValidationObserver v-slot="{ handleSubmit }" ref="observer" :key="reloadUser">
                            <form id="agent-form" @submit.prevent="handleSubmit(submit)">
                                <v-row>
                                    <div class="w-full">
                                    <v-col cols="12" class="pb-0 w-full">
                                            <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
                                                <form id="type-form" @submit.prevent="handleSubmit(submit)">
                                                    <v-row>                                    
                                                    <div class="col-6">
                                                    <!-- Name -->
                                                    <v-col cols="14" class="pa-0">
                                                        <input-text
                                                                name="name"
                                                                :label="$t('USERS.NAME')"
                                                                id="name"
                                                                rules="required|min:5"
                                                                v-model="form.name"
                                                                :fill="form.name"
                                                        >
                                                        </input-text>
                                                    </v-col>
                                                    <!-- end -->
                                                    <!-- Email -->
                                                    <v-col cols="14" class="pa-0">
                                                        <input-text
                                                                name="email"
                                                                :label="$t('USERS.EMAIL')"
                                                                id="email"
                                                                rules="required|email"
                                                                v-model="form.email"
                                                                :fill="form.email"
                                                        >
                                                        </input-text>
                                                    </v-col>
                                                    <!-- end -->
                                                    <!-- Initials -->
                                                    <v-col cols="14" class="pa-0">
                                                        <input-text
                                                                name="initials"
                                                                :label="$t('USERS.INITIALS')"
                                                                id="initials"
                                                                rules="required"
                                                                v-model="form.initials"
                                                                :fill="form.initials"
                                                        >
                                                        </input-text>
                                                    </v-col>
                                                    <!-- end -->
                                                    <!-- Password -->
                                                    <v-col cols="14" class="pa-0">
                                                        <input-text
                                                                name="password"
                                                                :label="$t('USERS.PWD')"
                                                                id="password"
                                                                :rules="form.id ? 'min:8' : 'required|min:8'"
                                                                v-model="form.password"
                                                                :fill="form.password"
                                                                type="password"
                                                        >
                                                        </input-text>
                                                    </v-col>
                                                    <!-- end -->
                                                    </div>
                                                    <v-card-subtitle>
                                                        <p class="black--text font-weight-bold">Avatar:</p>
                                                    </v-card-subtitle>
                                                    <v-col cols="4" class="pt-0"> 
                                                        <!-- Cover Photo -->
                                                        <div class="v-files-list-info position-relative v-expansion-panel-header-box" v-if="form.avatar && typeof form.avatar !== 'object' && form.id">
                                                            <v-img
                                                                    height="250"
                                                                    :src="$mediaFile('users', form.id, form.avatar)"
                                                                    :lazy-src="$mediaFile('users', form.id, form.avatar)"
                                                                    class="align-end text-left pl-3 pb-3 rounded"
                                                            >
                                                                <template v-slot:default class="h-full">
                                                                    <div class="footer pa-5 d-flex justify-center">
                                                                        <!-- Actions -->
                                                                        <v-btn class="remove pa-0" @click="removeAvatar">
                                                                            <i class="zwicon-trash white--text"></i>
                                                                        </v-btn>
                                                                        <!-- end -->
                                                                    </div>
                                                                </template>
                                                                <template v-slot:placeholder>
                                                                    <v-row
                                                                            class="fill-height ma-0"
                                                                            align="center"
                                                                            justify="center"
                                                                    >
                                                                        <v-progress-circular
                                                                                indeterminate
                                                                                color="grey lighten-5"
                                                                        ></v-progress-circular>
                                                                    </v-row>
                                                                </template>
                                                            </v-img>
                                                        </div>
                                                        <avatar-media @input="addMediaFile" :fill="form.avatar" v-model="form.avatar" v-if="typeof form.avatar === 'object' || !form.avatar "/>
                                                        <!-- end -->
                                                    </v-col>
                                                    </v-row>
                                                </form>
                                            </ValidationObserver>
                                        </v-col>
                                    </div>
                                </v-row>
                            </form>
                        </ValidationObserver>
                        <v-card-actions class="light-bg py-4 px-0">
                            <v-spacer></v-spacer>
                            <v-btn depressed color="secondary" form="type-form" type="submit">{{$t('USERS.SAVE')}}</v-btn>
                        </v-card-actions>
                        <InformationModal v-show="showModal" 
                        @ok="okModal"
                        :message="generateInformationMessage"/>
                    </div>
                </v-card>
            </v-col>
        </v-layout>
    </v-container>
</template>

<script>
import InformationModal from '@/components/Modals/InformationModal'
  import { User } from '@/store/structs/user'               
  import UserForm from '@/store/structs/user_form'
  import mixin from '@/models/system/UserModel'

export default {
    name: 'SetUser',
    mixins: [mixin],
    components: { InformationModal },
    data() {
      return {
        activeUser: User,
        files: [],
        form: UserForm,
        reloadUser: 0,
        showModal: false
      }
    },
    async created(){
      //console.log(this.$store.getters.user)
      this.activeUser = new User(this.$store.getters.user)
      this.form = new UserForm(this.$store.getters.user)
      await this.getUsers()
      this.makeSureToUpdateUser()
    },
    methods:{
        okModal(){
          this.showModal = false
        },
        makeSureToUpdateUser(){
          let updateUser = this.$store.state.systemUsersModule.users
          updateUser.forEach(element => {
            if(element.id == this.form.id){
              this.form.avatar = element.avatar
            }
          });
        },
        async addMediaFile(e){
          await this.save(this.form)
          let updateUser = this.$store.state.systemUsersModule.users
          updateUser.forEach(element => {
            if(element.id == this.form.id){
              this.form.avatar = element.avatar
            }
          });
          this.showModal = true
        },
        removeAvatar(){
            this.form.avatar = ''
        },
      async submit() {
          
        const isValid = await this.$refs.observer.validate()
        if (isValid) {          
            this.save(this.form)
        }
      }
    },
    watch: {
      value(val) {
        if (val) {
          if (this.typeData !== null) {
            this.form = { ...this.typeData }
          }
        }
      },
      typeData: function() {
        return new UserForm(this.typeData)
      }
    },

    computed: {
        generateInformationMessage(){
            return this.$t('LOGOFF_PHOTO')
        },
        getTitle(){
            return this.$route.meta.title.toLowerCase()
        },
        getSubtitle(){
            return this.$route.meta.subtitle
        },
      userID() {
        return this.activeUser.id
      },
      formTitle() {
        return this.typeData && this.typeData.name
          ? this.$t('users.form.EDIT') + this.typeData.name
          : this.$t('users.form.NEW')
      }
    }
}
</script>
