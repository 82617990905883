<template>
  <v-container>
    <v-row>
            <v-col xl="10" lg="8" cols="8" >
              <p class="page-title text-capitalize">{{ $t('KEYS.LISTINGS_TYPE') }}</p>
              <p class="page-subtitle">{{ $t('KEYS.LISTINGS_DESCRIPTION') }}</p>
            </v-col>
        </v-row>
    <v-card flat>
        <v-toolbar flat class="mode-header">
            <v-toolbar-title>
                {{ total }} <span class="text-capitalize">{{ $t('KEYS.LISTINGS') }}</span>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn depressed color="secondary" class="square" @click="launchDialog(true, 0)">
                <i class="zwicon-plus"></i>
            </v-btn>
        </v-toolbar>

        <v-main>
            <v-data-table
                    class="table__minimal elevation-0"
                    :loading="dataTableLoading"
                    :items="items"
                    :headers="headers"
                    :options.sync="pagination"
                    :loading-text="$t('Developments.loading')"
                    :hide-default-footer="false"
                    :calculate-widths="true"
                    :items-per-page="5"
            >
                <template v-slot:[`item.icon`]="{ item }">
                    <v-avatar color="#f7f7f7"
                              size="35"
                              class="ml-2">
                        <span class="caption">{{ item.id }}</span>
                    </v-avatar>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                    <div class="main__info black--text">
                        <p class="mb-0">{{ item.name.en }}</p>
                        <small class="d-block font-weight-bold">{{$t('KEYS.CREATED') }} {{ item.created }}</small>
                    </div>
                </template>
                <template v-slot:[`item.filterable`]="{ item }">
                    <v-chip
                            v-if="item.filterable"
                            color="transparent"
                            label
                            text-color="green lighten-3"
                            class="font-weight-medium"
                    >{{$t('KEYS.FILTERABLE') }}
                    </v-chip>
                    <v-chip
                            v-else
                            color="red lighten-2"
                            label
                    >{{$t('KEYS.UNFILTERABLE') }}
                    </v-chip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                    color="transparent"
                                    elevation="0"
                                    light
                                    v-bind="attrs"
                                    v-on="on"
                                    class="pa-0"
                            >
                                <i class="zwicon-more-h"></i>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-btn depressed color="transparent" block class="px-0"
                                       @click="launchDialog(true, item)">{{ $t('KEYS.EDIT_KEY') }}
                                </v-btn>
                            </v-list-item>
                            <v-list-item>
                                <v-btn depressed color="transparent" block class="text-left px-0"
                                       @click="deleteItem(item)">{{ $t('KEYS.DELETE') }}
                                </v-btn>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-main>

        <!-- Dialog -->
        <FormKeys v-model="dialogStatus" :type-data="selectedItem" title="Lifestyle" @form-data="formData" />
        <!-- end -->

    </v-card>
  </v-container>
</template>


<script>
  import Key from '@/store/structs/key'
  import { mapActions } from 'vuex'
  import { buildPayloadPagination, getFormat } from '@/utils/utils'
  import mixin from '@/models/keys/KeyModel'

  export default {
    name: 'Listings',

    mixins: [mixin],

    data() {
      return {
        dialogStatus: false,
        dataTableLoading: true,
        pagination: {},
        selectedItem: null,
        target: 'listing',
        headers: [
          { text: 'Icon', value: 'icon', width: '15%' },
          { value: 'name', width: '45%' },
          { text: 'Filterable', value: 'filterable', width: '30%', align: 'center' },
          { text: 'Actions', value: 'actions', width: '10%' }
        ]
      }
    },

    methods: {
      launchDialog(mode, object) {
        this.dialogStatus = mode
        this.selectedItem = new Key(object)
      },

      buildSearch() {
        return this.search
          ? { query: this.search, fields: this.fieldsToSearch.join(',') }
          : {}
      },

      formData: function(form) {
        this.save(form)
      },

      close() {
        this.dialogStatus = false
      }
    },

    watch: {
      pagination: {
        async handler() {
          try {
            this.dataTableLoading = true
            await this.getKeyItems(
              buildPayloadPagination(this.pagination, this.buildSearch(), this.target),
            )
            this.dataTableLoading = false
            // eslint-disable-next-line no-unused-vars
          } catch (error) {
            this.dataTableLoading = false
          }
        },
        deep: true
      }
    },

    computed: {
      items() {
        return this.$store.state.keysModule.keys
      },
      total() {
        return this.$store.state.keysModule.totalKeys
      },
      getTitle(){
        return this.$route.meta.title.toLowerCase()
      },
      getSubtitle(){
        return this.$route.meta.subtitle
      }
    }

  }
</script>
