<template>
    <div class="ma-8 mb-4 pb-8">
        <!-- Upload & Browse -->
        <v-card
                flat
                color="#f7f7f7"
                class="custom-color v-seo-preview text-left"
        >
            <browse-files :counterItems="items.length" :key="reloadBrowsing" @reload-me="reloadFiles" @process-server="saveMediaFiles"></browse-files>
        </v-card>
        <!-- end -->
        
        <!-- List files -->
        <v-card
                flat
                class="mt-5"
        >
            <v-card-title class="pl-0 text-left subtitle-1 font-weight-bold">{{$t('DEVELOPMENTS.GENERAL_MEDIA_TITLE')}}</v-card-title>
            <v-card-subtitle class="pl-0 text-left caption mb-2 black--text">{{$t('DEVELOPMENTS.GENERAL_MEDIA_SUBTITLE')}}
            </v-card-subtitle>

            <!-- <list-files 
              :key="renderComponent" 
              @remove-image="removeImage" 
              @generated-file="saveFileGenerated" 
              @change-cover="changeCover"
              :files="items" 
              :ID="ID"
              :location="location"
             ></list-files>  -->

             <drag-grid 
              @reordered="reorderMedia"
              @remove-image="removeImage" 
              @generated-file="saveFileGenerated" 
              @change-cover="changeCover"
              @change-plant="changePlant"
              @remove-all-media="removeAllMedia"
            
            :items="items" :ID="parseInt(ID)" location="condominiums"></drag-grid>
             
        </v-card>
        <!-- end -->
        <LoadingModal v-if="loadingModal" :message="processModalMessage"/>
    </div>
</template>

<script>
  import mixin from '@/models/condominium/CondominiumsModel'
  import mixinCondominiums from '@/models/condominium/CondominiumsModel'
  import LoadingModal from '@/components/Modals/LoadingModal'
  
  export default {
    name: 'Condominium_Media',

    mixins: [mixin, mixinCondominiums],
    components: {LoadingModal},

    data: () => ({
      block: 'condominium_media',
      files: [],
      ID: Number,
      location: String,
      renderComponent: 0,
      loadingModal: false,
      uploadQuantity: 0,
      reloadBrowsing: 0 + 'A',
      processMessage: '',
      filesForUpload: []
    }),

    async created() {
      this.files = [];
      if (typeof this.$route.params.slug !== 'undefined') {
        this.getCondominiumBlock({ id: this.$route.params.slug, block: this.block })
        await this.getPropertiesFromCondominium({ id: this.$route.params.slug })
      }
      this.processMessage = `${this.$t('loadingModal.WAIT_MESSAGE')}`
    },

    computed: {
      items() {
        let myItems = this.$store.state.condominiumsModule.block 

        console.log("ITEMS")
        console.log(myItems)
        
        if(!Array.isArray(myItems))
          return []

        myItems = myItems.sort((a, b) => a.ordering - b.ordering );
        //console.log(myItems);
        return myItems
      },
      processModalMessage(){
        return this.processMessage
      }
    },
    watch: {
      items(val) {
        this.files = val
        this.ID = this.$route.params.slug
        this.location = "condominiums"
      },
    },
    methods:{
      reorderMedia: async function (e){
        //console.log(e)
        await this.reorderImage(e)
        //this.renderComponent = this.renderComponent + 1;
      },
      sleep: function(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
      reloadFiles: function(){
        this.loadingModal = false
        this.reloadBrowsing += 1
        this.uploadQuantity = 0
        this.renderComponent = this.renderComponent + 1;
      },
      saveMediaFiles: async function (file, loadingBar, filePos){
        this.loadingModal = !loadingBar


        var id = this.$route.params.slug;
        const formData = new FormData()
        formData.append('image', file)      
        formData.append('filePos', filePos)          
        this.filesForUpload.push({filesPos: filePos, formData: formData});

        // Saving File
        //await this.saveMediaFilesFromCondominium({ id: id, data: formData});

        
        // Update View
        //await this.getCondominiumBlock({ id: this.$route.params.slug, block: this.block })


        if(this.loadingModal == false){
          this.processMessage = `${this.$t('loadingModal.FINISHING_PROCESS')}`
          this.loadingModal = true

          this.filesForUpload.sort((a, b) => a.filesPos - b.filesPos );

          let parent = this
          

          let flag = true
          let i = 0
          
          while(flag){
            await parent.saveMediaFilesFromCondominium({ id: id, data: parent.filesForUpload[i].formData});
            
            console.log("AQUI AQUI");
            console.log(parent.filesForUpload[i].formData)
            await parent.updatePropertiesPhotos(parent.filesForUpload[i].formData)
            
            //await parent.saveMediaFilesFromProperty({id: 418, data: parent.filesForUpload[i].formData})
            if(i == parent.filesForUpload.length-1)
              flag = false

            i++
          }
          
          await this.sleep(2500)

          await this.getCondominiumBlock({ id: this.$route.params.slug, block: this.block })
          

          this.filesForUpload = []


          this.loadingModal = false
          this.reloadBrowsing += 1
          this.uploadQuantity = 0
          this.renderComponent = this.renderComponent + 1;
        }else
          this.uploadQuantity++
      },
      async updatePropertiesPhotos(data){
        let propertiesData = this.$store.state.propertiesModule.properties
        let parent = this
        propertiesData.forEach(element => {
          parent.saveMediaFilesFromProperty({id: element.id, data: data})
        });
      },
      saveFileGenerated: async function(file, id, media_id, image_name){
        // to replace: '/{id}/{media_id}/media/replace'
        var id = this.$route.params.slug;
        const formData = new FormData()
        formData.append('image', file)
        formData.append('name', image_name)        
        
        // Saving Edited file
        await this.saveFileGeneratedFromCondominium({ id: id, media_id: media_id, data: formData})

        
        // Update View
        this.getCondominiumBlock({ id: this.$route.params.slug, block: this.block })
        this.renderComponent = this.renderComponent + 1;
      },
      changeCover: async function(file, id, media_id, image_name){
        // THE PATH IN THE API
        // {id}/{media_id}/media/cover
        
        // Filling the form with the needed data
        const formData = new FormData()
        formData.append('image', file)
        formData.append('name', image_name)
        
        // Change Cover
        await this.changeCoverImage({ id: id, media_id: media_id, data: formData});

        
        // Update View
        this.getCondominiumBlock({ id: this.$route.params.slug, block: this.block })
        this.renderComponent = this.renderComponent + 1;
      },
      changePlant: async function(file, id, media_id, image_name){
        const formData = new FormData()
        formData.append('file', file)
        formData.append('id', id) 
        formData.append('media_id', media_id) 
        formData.append('image_name', image_name) 

        // Change Cover
        await this.attributeCondominiumPlant({ id: id, media_id: media_id, data: formData});

        this.getCondominiumBlock({ id: this.$route.params.slug, block: this.block });
        this.renderComponent = this.renderComponent + 1;
      },
      removeImage: async function(location, fileName, media_id){
        // /{id}/media/remove

        var id = this.$route.params.slug;
        const formData = new FormData()
        formData.append('fileName', fileName)
        formData.append('mediaID', media_id)

        // Removing image
        await this.removeImageFromCondominium({ id: id, data: formData});

        
        // Update View
        this.getCondominiumBlock({ id: this.$route.params.slug, block: this.block })
        this.renderComponent = this.renderComponent + 1;
      },
      async removeAllMedia(){
        var id = this.$route.params.slug;
        await this.removeAllImagesFromCondominium(id)
        this.getCondominiumBlock({ id: this.$route.params.slug, block: this.block });
        this.renderComponent = this.renderComponent + 1;
      }
    }

  }
</script>