var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"xl":"10","lg":"8","cols":"8"}},[_c('p',{staticClass:"page-title text-capitalize"},[_vm._v(_vm._s(_vm.$t('KEYS.TYPE_TITLE')))]),_c('p',{staticClass:"page-subtitle"},[_vm._v(_vm._s(_vm.$t('KEYS.TYPE_DESCRIPTION')))])])],1),_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{staticClass:"mode-header",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.total)+" "),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t('KEYS.TYPE')))])]),_c('v-spacer'),_c('v-btn',{staticClass:"square",attrs:{"depressed":"","color":"secondary"},on:{"click":function($event){return _vm.launchDialog(true, 0)}}},[_c('i',{staticClass:"zwicon-plus"})])],1),_c('v-main',[_c('v-data-table',{key:_vm.dataTableKey,staticClass:"table__minimal elevation-0",attrs:{"loading":_vm.dataTableLoading,"items":_vm.items,"headers":_vm.headers,"options":_vm.pagination,"loading-text":_vm.$t('Developments.loading'),"hide-default-footer":false,"calculate-widths":true,"items-per-page":5},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"ml-2",attrs:{"color":"#f7f7f7","size":"35"}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.id))])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"main__info black--text"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$processLanguage(item.name)))]),_c('small',{staticClass:"d-block font-weight-bold"},[_vm._v(_vm._s(_vm.$t('KEYS.CREATED'))+" "+_vm._s(item.created))])])]}},{key:"item.filterable",fn:function(ref){
var item = ref.item;
return [(item.filterable)?_c('v-chip',{staticClass:"font-weight-medium",attrs:{"color":"transparent","label":"","text-color":"green lighten-3"}},[_vm._v(_vm._s(_vm.$t('KEYS.FILTERABLE'))+" ")]):_c('v-chip',{attrs:{"color":"red lighten-2","label":""}},[_vm._v(_vm._s(_vm.$t('KEYS.UNFILTERABLE'))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-0",attrs:{"color":"transparent","elevation":"0","light":""}},'v-btn',attrs,false),on),[_c('i',{staticClass:"zwicon-more-h"})])]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-btn',{staticClass:"px-0",attrs:{"depressed":"","color":"transparent","block":""},on:{"click":function($event){return _vm.launchDialog(true, item)}}},[_vm._v(_vm._s(_vm.$t('KEYS.EDIT_KEY'))+" ")])],1),_c('v-list-item',[_c('v-btn',{staticClass:"text-left px-0",attrs:{"depressed":"","color":"transparent","block":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(_vm._s(_vm.$t('KEYS.DELETE'))+" ")])],1)],1)],1)]}}],null,true)})],1),_c('FormKeys',{attrs:{"type-data":_vm.selectedItem,"title":_vm.$t('KEYS.TYPE_TITLE')},on:{"form-data":_vm.formData},model:{value:(_vm.dialogStatus),callback:function ($$v) {_vm.dialogStatus=$$v},expression:"dialogStatus"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }