import { mapActions } from 'vuex'

const mixin = {

  created: function() {
    console.debug(`Dashboard action called.`)
  },

  methods: {
    ...mapActions(['downloadErrorFile', 'updateToPlatform', 'exportToPlatform', 'checkInsertion', 'deleteFromPlatform', 'imovirtualGetAuthorizationCode', 'registerEvent']),
    
    async exportationProcess(form) {     
      await this.exportToPlatform(form)
      await this.getProperties()
    },
    async updateExportationProcess(form) {     
      await this.updateToPlatform(form)
      await this.getProperties()
    },
  
  }
  
}

export default mixin