<template>
    <div>
      <div style="right: -16px; z-index: 9999; top: -16px; position: absolute;">
          
          
          <SpeedDial block="condominiums" :lowCost="null" :highlighted="getHiglighted" @highlight="higlightForm"/>
        </div>
        <div class="ma-8 mb-4 pb-5">
            <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
                <form id="condominium-form" @submit.prevent="handleSubmit(submit)">
                    <v-row>
                        <v-col cols="7" class="text-left py-0">
                          <v-card-title class="subtitle-2 font-weight-bold pt-2 px-0">{{$t('DEVELOPMENTS.GENERAL')}}
                          </v-card-title>
                          <v-card-subtitle class="caption font-weight-light text-left mb-2 pb-4 px-0">
                          </v-card-subtitle>
                          <!-- For Sale or Rent -->
                                   
                              <p class="font-weight-bold black--text ">
                              <toggle-button
                                  v-if="getForm.for_sale != null && dataLoading==true"
                                  :value="getForSale"
                                  class="v-label theme--light mb-2 "
                                  :width=80                                   
                                  :colors="{checked: '#C48F56', unchecked: '#C48F56', disabled: '#CCCCCC'}"
                                  name="hidden_price"
                                  @input="forSaleToggle"
                                  :key="toggleKey"
                                  :labels="{checked: $t('DEVELOPMENTS.FOR_RENT'), unchecked: $t('DEVELOPMENTS.FOR_SALE')}"/>
                              </p>
                            
                            <!-- end -->  
                          <group-condominiumtags @update-tag-id="updateTagID" :form="form"></group-condominiumtags>
                            <!-- Translatable -->
                            <group-condominiums-translatable @form-condominium-translatable="processTranslatable" :form="form.i18n"
                                                :locale="window"
                                                @blur="onBlurAction">
                            </group-condominiums-translatable>
                            <!-- end -->
                            <!-- Where -->
                            <input-text
                                    name="condo_where"
                                    :label="$t('DEVELOPMENTS.LOCATION')"
                                    id="condo_where"
                                    v-model="form.condo_where" :fill="form.condo_where"
                                    @blur="onBlurAction"
                            >
                            </input-text>
                            <!-- end -->
                        </v-col>

                        <v-col cols="5" class="text-left py-0">
                          <div class="v-window v-item-group theme--light">
                            <div class="v-window__container">
                            <v-card flat class="short-information">
                                <v-card-title style="" class="subtitle-2 font-weight-bold pt-2 px-0">
                                </v-card-title>
                                <v-card-subtitle class="caption font-weight-light text-left mb-2 pb-4 px-0">
                                </v-card-subtitle>

                                <v-card-text class="all-inputs pb-0 px-0">
                                   <!-- Hide Price -->
                                   
                                     <p class="font-weight-bold black--text w-1-2">{{$t('DEVELOPMENTS.PRIVE_VISIBILITY')}}
                                      <toggle-button 
                                          v-if="getForm.hide_price != null && dataLoading==true"
                                          :value="getHidePrice"
                                          class="v-label theme--light mb-2"
                                          style="margin-left: 20px;"
                                          :width=60                                        
                                          :colors="{checked: '#00FF00', unchecked: '#FF0000', disabled: '#CCCCCC'}"
                                          name="hidden_price"
                                          @input="priceToggle"
                                          :key="toggleKey"
                                          :labels="{checked: $t('DEVELOPMENTS.SHOW'), unchecked: $t('DEVELOPMENTS.HIDE')}"/>
                                      </p>
                                   
                                    <!-- end --> 
                                    <!-- Price -->   
                                  <div class=" w-1-2 v-input v-input-text v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--prefix v-text-field--single-line v-text-field--filled v-text-field--is-booted v-text-field--enclosed v-text-field--placeholder"><div class="v-input__control"><div class="v-input__slot"><div class="v-text-field__slot"><div class="v-text-field__prefix">€</div>
                                          
                                          <currency-input
                                              class="v-input-text"
                                              v-model="form.starting_price" 
                                              name="starting_price"
                                              :placeholder="$t('DEVELOPMENTS.PRICE')"
                                              prefix="€"
                                              id="starting_price"
                                              :fill="form.starting_price"
                                              autocomplete="off"
                                              rules="numeric"
                                              @blur="onBlurAction"
                                            >
                                            </currency-input>
                                          </div></div>
                                        <div class="v-text-field__details"><div class="v-messages theme--dark"><div class="v-messages__wrapper"></div></div></div></div></div>
                                  
                                    <!-- end -->
                                                                 
                                </v-card-text>

                                <!-- Bedrooms -->
                                <v-row>
                                  <v-col cols="6" class="text-left">
                                <p class="font-weight-bold black--text">{{$t('DEVELOPMENTS.FROM')}} </p>
                                <special-input-number v-model="form.from_gross_area" :fill="String(form.from_gross_area)"
                                    :label="$t('DEVELOPMENTS.GROSS')"
                                    @on-blur-event="onBlurAction"
                                ></special-input-number>

                                <p class="font-weight-bold black--text">{{$t('DEVELOPMENTS.FROM')}} </p>
                                <special-input-number v-model="form.from_bedrooms" :fill="String(form.from_bedrooms)"
                                    :label="$t('DEVELOPMENTS.BEDROOMS')"
                                    @on-blur-event="onBlurAction"
                                ></special-input-number>

                                <p class="font-weight-bold black--text">{{$t('DEVELOPMENTS.FROM')}} </p>
                                <special-input-number v-model="form.from_parking" :fill="String(form.from_parking)"
                                    :label="$t('DEVELOPMENTS.PARKING')"
                                    @on-blur-event="onBlurAction"
                                ></special-input-number>
                                
                                  </v-col>
                                  
                                  <v-col cols="6" class="text-left">
                                <p class="font-weight-bold black--text">{{$t('DEVELOPMENTS.TO')}} </p>
                                <special-input-number v-model="form.to_gross_area" :fill="String(form.to_gross_area)"
                                    :label="$t('DEVELOPMENTS.GROSS')"
                                    @on-blur-event="onBlurAction"
                                ></special-input-number>

                                

                                <p class="font-weight-bold black--text">{{$t('DEVELOPMENTS.TO')}} </p>
                                <special-input-number v-model="form.to_bedrooms" :fill="String(form.to_bedrooms)"
                                    :label="$t('DEVELOPMENTS.BEDROOMS')"
                                    @on-blur-event="onBlurAction"
                                ></special-input-number>

                                

                                <p class="font-weight-bold black--text">{{$t('DEVELOPMENTS.TO')}} </p>
                                <special-input-number v-model="form.to_parking" :fill="String(form.to_parking)"
                                    :label="$t('DEVELOPMENTS.PARKING')"
                                    @on-blur-event="onBlurAction"
                                ></special-input-number>
                                  </v-col>
                                </v-row>
                                <!-- end -->
                            </v-card>
                            </div>
                          </div>
                        </v-col>
                    </v-row>

                    <!-- Groups -->
                    <v-alert
                          v-if="$route.params.slug == undefined"
                          border="top"
                          colored-border
                          type="info"
                          elevation="1"
                          class="mt-2 alert-message-box"
                        >
                          {{$t('DEVELOPMENTS.WARNING')}}
                      </v-alert>
                    <group-condominiums-list-properties v-if="loaded" :form="form"></group-condominiums-list-properties>
                    <group-condominiums-keys :disabled="$route.params.slug == undefined ? true : false" @form-types="processTypes" :form="form.types" @blur="onBlurAction"></group-condominiums-keys>
                    <group-condominiums-agents :disabled="$route.params.slug == undefined ? true : false" @form-agents="processAgents" :form="form.agent_id"></group-condominiums-agents>
                    <group-condominiums-search :disabled="$route.params.slug == undefined ? true : false" 
                            @form-search="processSearch" 
                            @form-district="processDistrict"            
                            :form="form.search"
                            :district="form.district_id"
                            @blur="onBlurAction"
                    ></group-condominiums-search>

                    <!-- end -->

                </form>
            </ValidationObserver>
        </div>
        <!-- end -->

        <v-card flat>
            <!-- Saving Action -->
            <v-card-actions class="light-bg py-4 px-0">
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" form="condominium-form" type="submit" @click="supportValidation">Save
                </v-btn>
            </v-card-actions>
            <!-- end -->
        </v-card>
        
        <InformationModal 
          :message="informationModal.message" 
          v-if="informationModal.show"
          @ok="informationModal.show = false"

        />
    </div>
</template>

<script>
  import { CondominiumForm } from '@/store/structs/condominium'
  import mixin from '@/models/condominium/CondominiumsModel'
  import SpeedDial from '@/components/common/SpeedDial'
  import InformationModal from '@/components/Modals/InformationModal'

  export default {
    name: 'General',

    mixins: [mixin],
    components:{ SpeedDial, InformationModal },

    props: {
      lang: {
        type: Number,
        default: 0
      }
    },

    data: () => ({
      dataLoading: true,
      loaded: false,
      block: 'general',
      window: 0,
      separator: 1,
      informationModal:{
        show: false,
        message:''
      },
      toggleKey: 0,
      form: CondominiumForm,
    }),

    created() {
      this.dataLoading = false
      let itemID = this.$route.params.slug
      if (typeof itemID !== 'undefined') {
        this.getCondominiumBlock({ id: itemID, block: this.block})
        this.loaded = true;
      }
      this.form = new CondominiumForm({})      
      
      
      if(!this.$route.path.includes('edit')){
        this.dataLoading = true
        this.form.for_sale = 0
        this.form.hide_price = 0
      }
    },

    computed: {
      getHiglighted(){
        return this.form.highlighted
      },
      items() {
        return this.$store.state.condominiumsModule.block
      },
      getForm(){
        return this.form
      },
      getForSale(){
        let flag = false
        
        if(this.form.for_sale == 1)
          flag = true

        return flag
      },
      getHidePrice(){
        let flag = false
        if(this.form.hide_price == 1)
          flag = true

        return flag
        
      }
    },

    watch: {
      items(val) {
        this.form = new CondominiumForm(val)
        console.log(this.form)
        this.dataLoading = true
      },
      lang: {
        handler: function(val) {
          this.window = val
        },
        deep: true,
        immediate: true
      }
    },

    methods: {
      async higlightForm(h){
        await this.getCondominiums()
        let counter = 0
        this.$store.state.condominiumsModule.condominiums.forEach(element => {
          if(element.highlighted == 1)
            counter++
        });
        if(counter >= 12){
          this.informationModal.show = true
          this.informationModal.message = this.$t('HIGHLIGHT_EXCESS.ERROR')
          return
        }
        this.form.highlighted = h
        this.onBlurAction()
      },
      updateTagID: function(id){
        this.form.tag_id = id
        this.onBlurAction()
      },
      processTranslatable(e) {
        this.form.i18n = e
      },
      processTypes(e) {
        this.form.types = e
      },
      processSearch(e) {
        this.form.search = e
      },
      processAgents(e) {
        this.form.agent_id = e
        this.onBlurAction()
      },
      processDistrict(e) {
        this.form.district_id = e
      },
      priceToggle(e){
        this.form.hide_price = e
        this.onBlurAction()
      },
      forSaleToggle(e){
        this.form.for_sale = e
        this.onBlurAction()
      },
      supportValidation() {
        let f = this.form
        let l = null
        this.$t('LANGUAGES').forEach(function(entry) {
          if (f.i18n.title[entry.slug].length < 5) {
            l = entry.id
          }
        })
        this.window = l
      },
      onBlurAction(){
        let toReload = this.form
        if(toReload.id != null)
          this.submit()
      },

      async submit() {
        // Prefix
        this.form.types['loaded'] = 1
        this.form.search['loaded'] = 1

        const isValid = await this.$refs.observer.validate()
        let toReload = this.form

        if (isValid) {
          await this.save(this.form)
        }

        if(toReload.id == null){
          let itemID = this.$route.params.slug
          if (typeof itemID !== 'undefined') {
            this.getCondominiumBlock({ id: itemID, block: this.block})
          }
        }
      }
    }

  }
</script>
