export default {
  getRightMenu(caller){
    return [
      {
        name: caller.$t('RIGHT_MENU.general'),
        path: 'new',
        alternative: 'general'
      },
      {
        name: caller.$t('RIGHT_MENU.media'),
        path: 'media',
        match: {
          route: 'new'
        }
      },
      {
        name: caller.$t('RIGHT_MENU.location'),
        path: 'location',
        match: {
          route: 'new'
        }
      },
      {
        name: caller.$t('RIGHT_MENU.SEO'),
        path: 'seo',
        match: {
          route: 'new'
        }
      },
      {
        name: caller.$t('RIGHT_MENU.Attachments'),
        path: 'attachments',
        match: {
          route: 'new'
        }
      }
    ]
  }
} 