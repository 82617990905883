<template>
    <v-container>
      <v-row v-if="dataLoading">
            <v-col xl="10" lg="10" cols="10" >
              <p class="page-title">{{$t('Listings.sold_listings')}}</p>
              <p class="page-subtitle">{{$t('Listings.sold')}}</p>
            </v-col>
        </v-row>
        <v-row v-if="dataLoading">
          <v-card flat class="mt-3 w-full">
            <div class="py-3 px-4 w-full">
              <v-toolbar flat class="mode-header">
                <v-toolbar-title>
                  {{$t('Listings.total_of')}} {{ items.length }} <span class="text-capitalize"> {{$t('Listings.properties')}}</span>
                </v-toolbar-title>

                <v-spacer></v-spacer>
                <VueCustomTooltip class="ml-4" :label="$t('TOOLTIPS.ADD_PROPERTY')" position="is-left">
                <v-btn depressed color="secondary" class="square" to="/properties/new">
                    <i class="zwicon-plus"></i>
                </v-btn>
                </VueCustomTooltip>
              </v-toolbar>
              <div class="px-5 pb-4 listing-div w-full">
                <v-data-table
                        :key="reloadDataTable"
                        class="table__minimal recent-properties elevation-0 td-data-table"
                        :loading="dataTableLoading"
                        :items="items"
                        :options.sync="pagination"
                        :headers="headers"
                        :loading-text="$t('Listings.loading')"
                        :hide-default-footer="false"
                        :calculate-widths="true"
                        :items-per-page="5"
                >
                    <template v-slot:[`item.image`]="{ item }">
                        <v-img
                                :src="$mediaFile('properties', item.id, item.media.cover)"
                                :lazy-src="$mediaFile('properties', item.id, item.media.cover)"
                                height="125"
                                width="125"
                                class="grey lighten-2 d-block rounded"
                        ></v-img>
                    </template>
                    <template v-slot:[`item.information`]="{ item }" >
                        <div class="main__info">
                            <p class="mb-0">{{item.reference}}</p>
                            <p class="mb-0 font-weight-medium black--text listing-sub-sub-title xl:xl-listing-sub-sub-title">{{ item.information.title }}</p>
                          <p class="mb-0 mt-0 font-weight-medium black--text listing-sub-sub-title-address" v-if="getAdress(item) != ''">({{getAdress(item)}})</p>

                          <div 
                            v-if="
                            item.type_id == 7 || 
                            item.type_id == 8 || 
                            item.type_id == 9 || 
                            item.type_id == 10 || 
                            item.type_id == 11 || 
                            item.type_id == 12 ||
                            item.type_id == 13 ||
                            item.type_id == 14"
                          class="mt-2">
                            <div
                              v-if="
                            item.type_id == 8 || 
                            item.type_id == 9 || 
                            item.type_id == 10">
                            <p class="mb-0 mt-0 font-weight-medium black--text listing-sub-sub-title" >                            
                              <span class="span-bullet">&#11044;</span> {{$t('DEVELOPMENTS.LOTE_SIZE')}}:
                              {{ item.lote.area }} {{ item.lote.unit == 'm2'  ? $i18n.locale == 'pt' ? 'm2' : 'sq.ft' : item.lote.unit }} 
                              
                            </p>
                            <p class="mb-0 mt-0 font-weight-medium black--text listing-sub-sub-title" >                            
                              <span class="span-bullet font-weight-medium">&#11044;</span> {{$t('DEVELOPMENTS.LAND_SIZE')}}:
                              {{ item.land.area }} {{ item.land.unit == 'm2'  ? $i18n.locale == 'pt' ? 'm2' : 'sq.ft' : item.land.unit }} 
                              
                            </p>
                            </div>
                            <div
                              v-if="item.type_id == 7 || item.type_id == 14">
                              <p class="mb-0 mt-0 font-weight-medium black--text listing-sub-sub-title" >                            
                              <span class="span-bullet">&#11044;</span> {{$t('DEVELOPMENTS.FLOOR')}}:
                              {{ item.floor }}
                              
                            </p>
                            <p class="mb-0 mt-0 font-weight-medium black--text listing-sub-sub-title" >                            
                              <span class="span-bullet font-weight-medium">&#11044;</span> {{$t('DEVELOPMENTS.LAND_SIZE')}}:
                              {{ item.kpis.m2 }} {{ $i18n.locale == 'pt' ? 'm2' : 'sq.ft' }} 
                              
                            </p>
                            </div>
                            <div v-if="item.type_id == 11 || item.type_id == 12 || item.type_id == 13" class="mt-2">
                            <p class="mb-0 mt-0 font-weight-medium black--text listing-sub-sub-title" >                            
                              <span class="span-bullet">&#11044;</span> {{$t('DEVELOPMENTS.GROSS')}}:
                              {{ item.kpis.m2 }}
                              
                            </p>
                            <p class="mb-0 mt-0 font-weight-medium black--text listing-sub-sub-title" >                            
                              <span class="span-bullet font-weight-medium">&#11044;</span> {{$t('DEVELOPMENTS.EXTERIOR')}}:
                              {{ item.kpis.exterior }}
                              
                            </p>
                          </div>
                          </div>
                          
                          <p class="listing-kpis mt-2" v-else>
                            {{ item.kpis.beds }} 
                            <v-icon class="mr-4" v-if="$screenWidth()=='xl'">mdi-bed-king-outline</v-icon>
                            <v-icon class="mr-4" small v-else>mdi-bed-king-outline</v-icon>  
                            {{ item.kpis.baths }}
                            <v-icon class="mr-4" v-if="$screenWidth()=='xl'">mdi-shower</v-icon> 
                            <v-icon class="mr-4" v-else small>mdi-shower</v-icon> 
                            {{ item.kpis.parking }} 
                            <v-icon v-if="$screenWidth()=='xl'">mdi-car-outline</v-icon>
                            <v-icon v-else small>mdi-car-outline</v-icon>
                          </p>
                        </div>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                        <div class="main__info" style="width: auto;">
                            <p class="mb-0 caption secondaryCard">
                                <span class="secondaryCard listing-secondary-objects">{{$t('Listings.type')}}</span>
                            </p>
                            <p v-if="item.information.location.type == ''">-</p>
                            <p v-else class="mb-0 text-limit-liner secondaryCard listing-sub-sub-title">{{ item.information.location.type }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                          <div class="main__info" style="width: auto;">
                            <p class="mb-0 caption">
                                <span class="secondaryCard listing-secondary-objects">{{$t('Listings.price')}}</span>
                            </p>
                            <p class="mb-0 text-limit-liner secondaryCard listing-sub-sub-title">{{ $currency(item.price)  }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.agent`]="{ item }" >
                        <p class="mb-0 caption">
                            <span class="secondaryCard listing-secondary-objects">{{$t('Listings.agent')}}</span>
                        </p>
                        <p v-if="item.agent == undefined || item.agent.name.first == ''">-</p>
                        <p v-else class="mb-0 text-limit-liner secondaryCard listing-sub-sub-title">{{ item.agent.name.first }} {{ item.agent.name.last }}</p>
                    </template>
                    <template v-slot:[`item.view`]="{ item }">
                        <VueCustomTooltip :label="$t('TOOLTIPS.EDIT_PROP')" position="is-left">
                        <v-btn :to="`/properties/edit/${item.id}/general`" color="#D8D8D8" class="pa-0 ma-0" depressed small>
                            <i class="zwicon-pencil"></i>
                        </v-btn>
                        </VueCustomTooltip>
                        <VueCustomTooltip :label="$t('TOOLTIPS.ARCHIVE_PROP')" position="is-left">
                        <v-btn @click="archiveThisProperty(item.id)" color="#D8D8D8" class="pa-0 ma-0 mt-2" depressed small>
                            <i class="zwicon-archive"></i>
                        </v-btn>
                        </VueCustomTooltip>
                        <VueCustomTooltip :label="$t('TOOLTIPS.REMOVE_PROP')" position="is-left">
                        <v-btn @click="confirmModal(item)" color="#D8D8D8" label class="pa-0 ma-0 mt-2" depressed small>
                          <i class="zwicon-trash"></i>
                        </v-btn>
                        </VueCustomTooltip>
                    </template>
                </v-data-table>
              </div>
            </div>
          </v-card>
        </v-row>
        <ConfirmationModal v-show="showModal" 
          @yes="removeProperty"
          @no="closeModal"
          :title="confirmationModal.title"
          :message="confirmationModal.message"
        />
    </v-container>    
</template>

<script>
  import mixin from '@/models/properties/PropertiesModel'
  import { Property } from '@/store/structs/properties'
  import ConfirmationModal from '@/components/Modals/ConfirmationModal'

  export default {
    name: 'Listings',
    components: { ConfirmationModal },

    mixins: [mixin],
    props: ["applyFilters"],
    data: () => ({
      dataLoading: true,
      dataTableLoading: true,
      pagination: {},
      headers: [
        { value: 'image', width: '6%', align: 'img' },
        { value: 'information', width: '50%', align: 'left'  },
        { value: 'description', width: '10%', align: 'start' },
        { value: 'price', width: '15%', align: 'left' },
        { value: 'agent', width: '19%', align: 'left' },
        { value: 'view', width: '10%', align: 'img' }
      ],
      showModal: false,
      confirmationModal:{
        title: '',
        message: ''
      },
      property: null,
      reloadDataTable: 0
    }),
    async created() {
      this.dataLoading = false
      await this.getProperties()
      this.$emit('properties-count', this.$store.state.propertiesModule.properties.length)

      // Colocar na página certa
      let p = this.$cookies.get('PROPERTY_SOLD_LISTINGS_PAGINATION')
      if(p != null)
        this.pagination = p
      this.dataLoading = true
    },

    watch: {
      pagination: {
        async handler() {
          try {
            this.dataTableLoading = true
            this.$cookies.set('PROPERTY_SOLD_LISTINGS_PAGINATION', this.pagination, "1d");
            await this.getProperties()
            this.dataTableLoading = false
          } catch (error) {
            this.dataTableLoading = false
          }
        },
        deep: true
      }
    },

    computed: {
      items() {
        let retrievedData = this.$store.state.propertiesModule.properties 
        let computeArray = []
        retrievedData.forEach(element => {
          if(element.tag_id===3 && element.archived!=1)
            computeArray.push(element)
        });


        if(this.applyFilters == null){
           return Object.keys(computeArray).map(k =>
            new Property(computeArray[k])
          )
        }
         
        let filtersToApply = this.applyFilters
        
        let list = [] 
        computeArray.map(function(value, key) {
          
          let flag = 1
          if(filtersToApply.baths > 0 && filtersToApply.baths < 6){
            if(value.bathrooms == filtersToApply.baths)
              flag = 0
            else flag = 1
          }else if(filtersToApply.baths >= 6){
            if(value.bathrooms >= filtersToApply.baths)
              flag = 0
            else flag = 1
          }else
            flag = 0

          if(filtersToApply.beds > 0 && filtersToApply.beds < 6){
            if(value.bedrooms == filtersToApply.beds)
              flag = 0
            else flag = 1
          }else if(filtersToApply.beds >= 6){
            if(value.bedrooms >= filtersToApply.beds)
              flag = 0
            else flag = 1
          }else
            flag = 0



          if(flag == 0 && filtersToApply.price != 0){
            if(value.price >= filtersToApply.price )
                flag = 0
            else flag = 1
          }

          
          if(flag == 0 && filtersToApply.name != ''){
            if(value.title != null){
              if(String(value.title).toUpperCase().match(filtersToApply.name))
                flag = 0
              else flag = 1
            }else flag = 1
          }


          if(flag == 0 && filtersToApply.type != 0){
            if(value.types_id != null){
              if(value.types_id == filtersToApply.type)
                flag = 0
              else flag = 1
            }else flag = 1
          }



          if(flag == 0){
            if(value.reference != null && filtersToApply.reference != ''){
              if(String(value.reference).match(filtersToApply.reference))
                flag = 0
              else flag = 1
            }else{
              if(filtersToApply.reference == "" && flag != 1)
                flag = 0
              else
                flag = 1
            }
          }

          if(flag == 0){
            if(filtersToApply.newDevelopment == 1){
              if(value.condominium_id =! null)
                flag = 0
              else flag = 1
            }
          }

          if(flag == 0){
            if(filtersToApply.collectionPrive == 1)
              if(value.collection != null){
                if(value.collection == 1) 
                  flag = 0
                else flag = 1
              }
          }

          if(flag == 0){
            if(filtersToApply.highlighted == 1){
              if(value.highlighted == 1)
                flag = 0
              else flag = 1
            }
          }

          if(flag == 0){
            if(filtersToApply.james_edition == 1){
              if(value.jamesEdition_uuid != '')
                flag = 0
              else flag = 1
            }
          }

          if(flag == 0){
            if(filtersToApply.idealista == 1){
              if(value.idealista_uuid != '')
                flag = 0
              else flag = 1
            }
          }

          if(flag == 0){
            if(filtersToApply.imovirtual == 1){
              if(value.imovirtual_uuid != '')
                flag = 0
              else flag = 1
            }
          }


          // CHECK PROBLEMATIC
          if(flag == 0){
            if(filtersToApply.problems == 1){
              
              if(value.lat == null && value.len == null)
                flag = 0
                else flag = 1
            }
          }

          // END PROBLEMATIC

          if(flag == 0)
            list.push(computeArray[key])
        }
        ); 
        if(filtersToApply.locationUpdate != null){    
          
          let routeLevel = []  
          let areaLeve3Array = []  
          let areaLeve2Array = []  
          let areaDistrict = []
      //console.log(filtersToApply.locationUpdate.address_components)


      // Procurar já a lista que pertence à cidade
      let cityList = []
      list.forEach(value => {
        if(value.route != null){
            filtersToApply.locationUpdate.forEach(addr => {
              // GET DISTRICT LEVEL
                if(addr.types[0] == "administrative_area_level_1"){
                    if(value.gmap_district != null)
                        if(value.gmap_district == addr.long_name || value.gmap_district.indexOf(addr.long_name) !== -1)
                          cityList.push(value)
                }
            })
        }
      })

      cityList.forEach(value => {
        
        // LOCATION LEVELS        
          
            if(value.route != null){
              filtersToApply.locationUpdate.forEach(addr => {
                // GET SMALLEST LEVEL
                if(addr.types[0] == "route" || addr.types[0] == "neighborhood"){
                  if(value.route == addr.long_name || value.route.indexOf(addr.long_name) !== -1)
                    routeLevel.push(value)
                }


                // GET SMALLEST LEVEL
                if(addr.types[0] == "administrative_area_level_3" || addr.types[0] == "locality"){
                    if(value.administrative_area_level_3 != null)
                        if(value.administrative_area_level_3 == addr.long_name || value.administrative_area_level_3.indexOf(addr.long_name) !== -1)
                          areaLeve3Array.push(value)
                }

                // GET SECOND SMALLEST LEVEL
                if(addr.types[0] == "administrative_area_level_2" || addr.types[0] == "locality"){
                    if(value.administrative_area_level_2 != null)
                        if(value.administrative_area_level_2 == addr.long_name || value.administrative_area_level_2.indexOf(addr.long_name) !== -1)
                          areaLeve2Array.push(value)
                }

                // GET DISTRICT LEVEL
                if(addr.types[0] == "administrative_area_level_1"){
                    if(value.gmap_district != null)
                        if(value.gmap_district == addr.long_name || value.gmap_district.indexOf(addr.long_name) !== -1)
                          areaDistrict.push(value)
                }
              })
            }
          
           });

           if(routeLevel.length != 0){
             return Object.keys(routeLevel).map(k =>
                new Property(routeLevel[k])
              )
           }
           if(areaLeve3Array.length != 0){
             return Object.keys(areaLeve3Array).map(k =>
                new Property(areaLeve3Array[k])
              )
           }
           if(areaLeve2Array.length != 0){
             return Object.keys(areaLeve2Array).map(k =>
                new Property(areaLeve2Array[k])
              )
           }
           if(areaDistrict.length != 0){
             return Object.keys(areaDistrict).map(k =>
                new Property(areaDistrict[k])
              )
           }
        }
          // END LOCATION LEVELS
        return Object.keys(list).map(k =>
            new Property(list[k])
          )
      }
    },
    methods: {
      getAdress(i){
        if(i.administrative_area_level_2 == '' || i.administrative_area_level_2 == null)
            return ''
        
        if(i.political != null)
            return i.political + ', ' + i.administrative_area_level_3

        if(i.route != '' && i.route != null)
          return i.route + ', ' + i.administrative_area_level_2 + ', ' + i.administrative_area_level_3

        return i.administrative_area_level_2 + ', ' + i.administrative_area_level_3
      },
      confirmModal(i){
        this.property = i
        this.confirmationModal.title = this.$t('REMOVING_MODAL.TITLE') + i.reference
        this.confirmationModal.message = this.$t('REMOVING_MODAL.DESCRIPTION') + "\n\n" + this.$t('REMOVING_MODAL.REFERENCE') + i.reference +"\n" + this.$t('REMOVING_MODAL.REMOVING_DESCRIPTION') + i.information.title + "\n\n" + this.$t('REMOVING_MODAL.CONTINUE')
        this.showModal = true
      },
      closeModal(){
        this.showModal = false
      },
      removeProperty: async function(){
        await this.deleteProperty({id: this.property.id})
        this.closeModal()
        this.reloadDataTable++
      },
      archiveThisProperty: async function (id){
        await this.archiveProperty({id: id})
        this.$emit('update-view')
        this.reloadDataTable++
      },
      unArchiveThisProperty: async function(id){
        await this.unArchiveProperty({id: id})
        this.$emit('update-view')
        this.reloadDataTable++
      },  
    }

  }
</script>
