<template>
    <v-container>
      <v-row v-if="dataLoading">
            <v-col xl="10" lg="8" cols="8" >
              <p class="page-title text-capitalize">{{ $t('SERVICES.LISTINGS_TITLE') }}</p>
              <p class="page-subtitle"> {{ $t('SERVICES.LISTINGS_DESCRIPTION') }}</p>
            </v-col>
        </v-row>
      <v-row v-if="dataLoading">
            <v-card flat class="mt-3 w-full">
              <div class="py-3 px-4 w-full">
                <v-toolbar flat class="mode-header">
                  <v-toolbar-title>
                    {{$t('Listings.total_of')}} {{ items.length }} <span class="text-capitalize"> {{$t('SERVICES.SERVICES')}}</span>
                  </v-toolbar-title>

                  <v-spacer></v-spacer>

                  <v-btn depressed color="secondary" class="square" to="/services/new">
                      <i class="zwicon-plus"></i>
                  </v-btn>
                </v-toolbar>
                <div class="px-5 pb-4 listing-div w-full">

                <v-data-table
                        :key="reloadDataTable"
                        class="table__minimal recent-properties elevation-0 td-data-table"
                        :loading="dataTableLoading"
                        :items="items"
                        :options.sync="pagination"
                        :headers="headers"
                        :loading-text="$t('Developments.loading')"
                        :hide-default-footer="false"
                        :calculate-widths="true"
                        :items-per-page="5"
                >
                  <template v-slot:[`item.Title`]="{ item }">
                        <p class="mb-0 font-weight-bold black--text listing-sub-sub-title">{{ $processLanguage(item.title) }}</p>
                    </template>

                    <template v-slot:[`item.Description`]="{ item }" >
                            <p class="mb-0 font-weight-medium black--text listing-sub-sub-title" style="white-space: pre-line !important;">{{ removeTags($processLanguage(item.description)).slice(0, 350) }} (...)</p>
                  
                    </template>

                    <template v-slot:[`item.view`]="{ item }">
                      <v-btn :to="`/services/edit/${item.id}`" color="#D8D8D8" class="pa-0 ma-0" depressed small>
                            <i class="zwicon-pencil"></i>
                        </v-btn>
                        <v-btn @click="confirmModal(item)" color="#D8D8D8" label class="pa-0 ma-0 mt-2" depressed small>
                          <i class="zwicon-trash"></i>
                        </v-btn>
                    </template>

                </v-data-table>
                </div>
              </div>
            </v-card>
      </v-row>
      <ConfirmationModal v-show="showModal" 
          @yes="removeService"
          @no="closeModal"
          :title="confirmationModal.title"
          :message="confirmationModal.message"
        />
    </v-container>
</template>

<script>
  import mixin from '@/models/services/ServicesModel'
  import { ServiceTranslated } from '@/store/structs/service'
  import ConfirmationModal from '@/components/Modals/ConfirmationModal'

  export default {
    name: 'Listings',
    mixins: [mixin],
    components: { ConfirmationModal },
    data(){
        return{
            dataLoading: true,
            dataTableLoading: true,
            pagination: {},
            headers: [
              { value: 'Title', width: '25%', align: 'left' },
              { value: 'Description', width: '70%', align: 'left'  },
              { value: 'view', width: '5%', align: 'img' }
            ],
            showModal: false,
            confirmationModal:{
              title: '',
              message: ''
            },
            service: null,
            reloadDataTable: 0
        }
    },
    async created(){
        await this.getServices()
    },
    watch: {
      pagination: {
        async handler() {
          try {
            this.dataTableLoading = true
            await this.getServices()
            this.dataTableLoading = false
            // eslint-disable-next-line no-unused-vars
          } catch (error) {
            this.dataTableLoading = false
          }
          //console.log(this.dataTableLoading)
        },
        deep: true
      }
    },
    methods:{
      removeTags(str) {
          if ((str===null) || (str===''))
          return false;
          else
          str = str.toString();
          return str.replace( /(<([^>]+)>)/ig, '');
      },
      confirmModal(i){
        this.service = i
        this.confirmationModal.title = 'Removing service: ' + i.title
        this.confirmationModal.message = "You are about to delete the following service: \n\nName: " + i.title + "\n\nDescription: " + i.description + "\n\nShould I continue?"
        this.showModal = true
      },
      closeModal(){
        this.showModal = false
      },
      async removeService(){
        await this.deleteItem(this.service.id)
        this.$emit('update-view')
      }
    },
    computed:{
        items() {
            let retrievedData = this.$store.state.servicesModule.services
            
            if(retrievedData.length == 0)
              return []

            
            return Object.keys(retrievedData).map(k =>
              new ServiceTranslated(retrievedData[k])
            )
        },
        getTitle(){
            return this.$route.meta.title.toLowerCase()
        },
        getSubtitle(){
            return this.$route.meta.subtitle
        },
    }
  }
</script>
