<template>
    <v-container>
      <v-row v-if="dataLoading">
            <v-col xl="10" lg="10" cols="10" >
              <p class="page-title">{{$t('Developments.active_listings')}}</p>
              <p class="page-subtitle">{{$t('Developments.all_message')}}</p>
            </v-col>
        </v-row>
        <v-row v-if="dataLoading">
          <v-card flat class="mt-3 w-full">
            <div class="py-3 px-4 w-full">
              <v-toolbar flat class="mode-header">
                <v-toolbar-title>
                  {{$t('Developments.total_of')}} {{ items.length }} <span class="text-capitalize"> {{$t('Developments.developments')}}</span>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn depressed color="secondary" class="square" to="/condominiums/new">
                    <i class="zwicon-plus"></i>
                </v-btn>
              </v-toolbar>
              <div class="px-5 pb-4 listing-div w-full">
                <v-data-table
                        :key="reloadDataTable"
                        class="table__minimal recent-properties elevation-0 td-data-table"
                        :loading="dataTableLoading"
                        :items="items"
                        :options.sync="pagination"
                        :headers="headers"
                        :loading-text="$t('Developments.loading')"
                        :hide-default-footer="false"
                        :calculate-widths="true"
                        :items-per-page="5"
                >
                    <template v-slot:[`item.image`]="{ item }">
                        <v-img
                                :src="$mediaFile('condominiums', item.id, item.cover)"
                                :lazy-src="$mediaFile('condominiums', item.id, item.cover)"
                                height="125"
                                width="125"
                                class="grey lighten-2 d-block rounded"
                        ></v-img>
                    </template>
                    <template v-slot:[`item.information`]="{ item }" >
                        <div class="main__info">
                            <p class="mb-0">{{$processLanguage(item.information.title)}}</p>
                            <p class="mb-0 font-weight-medium black--text listing-sub-sub-title">{{ item.information.description.en.slice(0, 150) }} (...)</p>
                            <p class="listing-kpis mt-2">
                              <template v-if="item.from_bedrooms != '-' && item.to_bedrooms != '-'">
                                {{ item.from_bedrooms }} - {{ item.to_bedrooms }}
                                <v-icon class="mr-4" v-if="$screenWidth()=='xl'">mdi-bed-king-outline</v-icon>
                                <v-icon class="mr-4" small v-else>mdi-bed-king-outline</v-icon>
                              </template>
                              <template v-if="item.from_parking != '-' && item.to_parking != '-'">
                                {{ item.from_parking}} - {{ item.to_parking }}
                                <v-icon v-if="$screenWidth()=='xl'">mdi-car-outline</v-icon>
                                <v-icon v-else small>mdi-car-outline</v-icon>
                              </template>
                            </p>
                            <p class="ml-0 mb-0">
                            <v-row>
                                <!-- ERROR LOCATION -->
                                <VueCustomTooltip v-if="item.lat == null && item.len == null" :label="$t('TOOLTIPS.CONDO_LOCATION_ERROR')" position="is-top">
                                  <v-chip
                                    class="ml-2"
                                    fab color="danger"
                                    x-small
                                    text-color="white"
                                  >
                                    <v-avatar>
                                      <v-icon small>mdi-earth-remove</v-icon>
                                    </v-avatar>
                                  </v-chip>
                                </VueCustomTooltip>
                                <!-- END ERROR LOCATION -->

                                <!-- HIGHLIGHTED TAG -->
                                <VueCustomTooltip :label="$t('TOOLTIPS.HIGHLIGHT_DESCRIPTION')" position="is-top">
                                  <v-chip
                                    v-if="item.highlighted == 1"
                                    class="ml-2"
                                    fab
                                    x-small
                                    color="yellow darken-3"
                                    text-color="white"
                                  >
                                    <v-avatar>
                                      <v-icon small>mdi-star-plus</v-icon>
                                    </v-avatar>                                    
                                  </v-chip>
                                </VueCustomTooltip>
                                <!-- END HIGHLIGHTED TAG -->
                            </v-row>
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                        <div class="main__info" style="width: auto;">
                            <p class="mb-0 caption secondaryCard">
                                <span class="secondaryCard listing-secondary-objects">{{$t('Developments.where')}}</span>
                            </p>
                            <p v-if="item.condo_where == ''">-</p>
                            <p v-else class="mb-0 text-limit-liner secondaryCard listing-sub-sub-title" style="white-space: pre-line">{{ getAdress(item) }}</p>
                        </div>
                    </template>

                    <template v-slot:[`item.price`]="{ item }">
                          <div class="main__info" style="width: auto;">
                            <p class="mb-0 caption">
                                <span class="secondaryCard listing-secondary-objects">{{$t('Developments.price')}}</span>
                            </p>
                            <p class="mb-0 text-limit-liner secondaryCard listing-sub-sub-title">{{ $currency(item.starting_price)  }}</p>
                        </div>
                    </template>

                    <template v-slot:[`item.agent`]="{ item }" >
                        <p class="mb-0 caption">
                                <span class="secondaryCard listing-secondary-objects">{{$t('Developments.agent')}}</span>
                        </p>
                        <p class="mb-0 text-limit-liner secondaryCard listing-sub-sub-title">{{item.agent_name}}</p>
                    </template>

                    <template v-slot:[`item.view`]="{ item }">
                      <v-btn :to="`/condominiums/edit/${item.id}/general`" color="#D8D8D8" class="pa-0 ma-0" depressed small>
                            <i class="zwicon-pencil"></i>
                        </v-btn>
                        <v-btn @click="confirmArchiveOrUnarchive(item)" color="#D8D8D8" class="pa-0 ma-0 mt-2" depressed small>
                            <i class="zwicon-tray-delete"></i>
                        </v-btn>
                        <v-btn @click="confirmModal(item)" color="#D8D8D8" label class="pa-0 ma-0 mt-2" depressed small>
                          <i class="zwicon-trash"></i>
                        </v-btn>
                    </template>

                    <template v-slot:expanded-item="{ item }">
                      <td></td>
                      <td :colspan="headers.length">
                        <v-row>
                          <v-col cols="1" xl="1" md="2">
                            <label>{{$t('TOOLTIPS.OPTIONS')}}:</label>
                          </v-col>
                          <v-col cols="2"> 
                            <v-row>
                              <v-switch
                                v-if="(checkHighlightedCounter < 12 || item.highlighted == 1)"
                                v-model="item.highlighted"
                                inset
                                @change="quickAction(item.id, 'highlighted', item.highlighted)"
                                class="v-label"
                                :label="item.highlighted == 0 ? $t('TOOLTIPS.HIGHLIGHT_PUT') : $t('TOOLTIPS.HIGHLIGHT_DESCRIPTION')"
                              ></v-switch>
                              <template
                                v-else>
                                <v-switch
                                  disabled
                                  v-model="item.highlighted"
                                  inset
                                  @change="quickAction(item.id, 'highlighted', item.highlighted)"
                                  class="v-label"
                                  :label="item.highlighted == 0 ? $t('TOOLTIPS.HIGHLIGHT_PUT') : $t('TOOLTIPS.HIGHLIGHT_DESCRIPTION')"
                                ></v-switch>
                                <VueCustomTooltip :label="$t('HIGHLIGHT_EXCESS.ERROR')" position="is-right">
                                <v-icon color="error" class="ml-2 mt-4">
                                  mdi-alert-circle
                                </v-icon>
                                </VueCustomTooltip>
                              </template>
                            </v-row>
                          </v-col>
                          <v-col cols="1" md="2" xl="1">
                            <label>{{$t('TOOLTIPS.TAGS')}}:</label>
                          </v-col>
                          <v-col>
                            <group-condominiumtags
                            @update-tag-id="updateTagID"
                            :form="serializeFalseForm(item)"
                            >
                          </group-condominiumtags>
                          </v-col>
                        </v-row>
                      </td>
                     
                    </template>
                </v-data-table>
              </div>
            </div>
          </v-card>
        </v-row>
        <ConfirmationModal v-show="showModal" 
          @yes="removeCondominium"
          @no="closeModal"
          :title="confirmationModal.title"
          :message="confirmationModal.message"
        />
        
        <ConfirmationModal v-show="showModal_archive" 
          @yes="unArchiveThisCondominium"
          @no="closeModal"
          :title="confirmationModal.title"
          :message="confirmationModal.message"
        />
    </v-container>    
</template>

<script>
  import { Condominium, CondominiumForm } from '@/store/structs/condominium'
  import mixin from '@/models/condominium/CondominiumsModel'
  import ConfirmationModal from '@/components/Modals/ConfirmationModal'

  export default {
    name: 'Listings',
    components: { ConfirmationModal },
    mixins: [mixin],
    props: ["applyFilters"],
    data: () => ({
      dataLoading: true,
      refreshThisView: 0,
      expanded: [],
      singleExpand: true,
      dataTableLoading: true,
      pagination: {},
      highlightedCounter: -1,
      headers: [
        { value: 'image', width: '6%', align: 'img' },
        { value: 'information', width: '50%', align: 'left'  },
        { value: 'description', width: '20%', align: 'left' },
        { value: 'price', width: '10%', align: 'left' },
        { value: 'agent', width: '19%', align: 'left' },
        { value: 'view', width: '10%', align: 'img' }
      ],
      showModal: false,      
      showModal_archive: false,
      confirmationModal:{
        title: '',
        message: ''
      },
      condominium: null,
      reloadDataTable: 0
    }),


    watch: {
      pagination: {
        async handler() {
          try {
            this.dataTableLoading = true
            this.$cookies.set('CONDOMINIUMS_ARCHIVED_LISTINGS_PAGINATION', this.pagination, "1d");
            await this.getCondominiums()
            this.dataTableLoading = false
          } catch (error) {
            this.dataTableLoading = false
          }
        },
        deep: true
      }
    },
    async created(){
      this.dataLoading = false
      await this.getCondominiums()
      this.$emit('condominiums-count', this.$store.state.condominiumsModule.condominiums.length)

      // Colocar na página certa
      let p = this.$cookies.get('CONDOMINIUMS_ARCHIVED_LISTINGS_PAGINATION')
      if(p != null)
        this.pagination = p

      this.dataLoading = true

      this.dataLoading = true
      
      
      let counter = 0
      this.$store.state.condominiumsModule.condominiums.forEach(element => {
        if(element.highlighted == 1)
          counter++
      });
      this.highlightedCounter = counter
    },
    computed: {
      checkHighlightedCounter(){
        return this.highlightedCounter
      },
      items() {
        let retrievedData = this.$store.state.condominiumsModule.condominiums
        let finalArray = []



        retrievedData.forEach(element => {
          
            if(element.archived===1){
              console.log(element)
                finalArray.push(element)
            }
        });
        
        if(this.applyFilters == null || this.filtersEmpty()){
           return Object.keys(finalArray).map(k =>
            new Condominium(finalArray[k])
          )
        }

        let filtersToApply = this.applyFilters
        
        let list = [] 
        let parent = this
        finalArray.map(function(value, key) {
          let flag = 1
          
            if(parent.$processLanguage(value.title) != null){
              if(String(parent.$processLanguage(value.title)).toUpperCase().match(filtersToApply.name))
                flag = 0
              else flag = 1
            }else flag = 1

            if(flag == 0){
              if(filtersToApply.highlighted == 1){
                if(value.highlighted == 1)
                  flag = 0
                else flag = 1
              }
            }

            if(flag == 0){
              if(filtersToApply.bedrooms.from == 0 && filtersToApply.bedrooms.to == 10)
                flag = 0
              else if(value.from_bedrooms >= filtersToApply.bedrooms.from && value.to_bedrooms <= filtersToApply.bedrooms.to)
                flag = 0
              else flag = 1
            }

            if(flag == 0){
              if(filtersToApply.parking.from == 0 && filtersToApply.parking.to == 10)
                flag = 0
              else if(value.from_parking >= filtersToApply.parking.from && value.to_parking <= filtersToApply.parking.to)
                flag = 0
              else flag = 1
            }

            if(flag == 0 && filtersToApply.price != 0){
            if(value.starting_price >= filtersToApply.price )
                flag = 0
            else flag = 1
          }

            if(flag == 0 && filtersToApply.condominiumLocation != ''){
              if(value.condo_where != null){
                if(String(value.condo_where).toUpperCase().match(filtersToApply.condominiumLocation))
                  flag = 0
                else flag = 1
              }else flag = 1
            }

            // CHECK PROBLEMATIC
          if(flag == 0){
            if(filtersToApply.problems == 1){
              
              if((value.lat == null && value.len == null) || (value.lat == 0 && value.len == 0) || (value.lat == '' && value.len == ''))
                flag = 0
                else flag = 1
            }
          }

          // END PROBLEMATIC

          if(flag == 0)
              list.push(finalArray[key])
        })

        if(filtersToApply.locationUpdate != null){    
          
          let routeLevel = []  
          let areaLeve3Array = []  
          let areaLeve2Array = []  
          let areaDistrict = []


      list.forEach(value => {
        
        // LOCATION LEVELS        
          
            if(value.route != null){
              filtersToApply.locationUpdate.forEach(addr => {
                // GET SMALLEST LEVEL
                if(addr.types[0] == "route" || addr.types[0] == "neighborhood"){
                    if(value.route != null)
                        if(value.route == addr.long_name || value.route.indexOf(addr.long_name) !== -1)
                          routeLevel.push(value)
                }


                // GET SMALLEST LEVEL
                if(addr.types[0] == "administrative_area_level_3" || addr.types[0] == "locality"){
                    if(value.administrative_area_level_3 != null)
                        if(value.administrative_area_level_3 == addr.long_name || value.administrative_area_level_3.indexOf(addr.long_name) !== -1)
                          areaLeve3Array.push(value)
                }

                // GET SECOND SMALLEST LEVEL
                if(addr.types[0] == "administrative_area_level_2" || addr.types[0] == "locality"){
                    if(value.administrative_area_level_2 != null)
                        if(value.administrative_area_level_2 == addr.long_name || value.administrative_area_level_2.indexOf(addr.long_name) !== -1)
                          areaLeve2Array.push(value)
                }

                // GET DISTRICT LEVEL
                if(addr.types[0] == "administrative_area_level_1"){
                    if(value.gmap_district != null)
                        if(value.gmap_district == addr.long_name || value.gmap_district.indexOf(addr.long_name) !== -1)
                          areaDistrict.push(value)
                }
              })
            }
          
           });

           if(routeLevel.length != 0){
             return Object.keys(routeLevel).map(k =>
                new Condominium(routeLevel[k])
              )
           }
           if(areaLeve3Array.length != 0){
             return Object.keys(areaLeve3Array).map(k =>
                new Condominium(areaLeve3Array[k])
              )
           }
           if(areaLeve2Array.length != 0){
             return Object.keys(areaLeve2Array).map(k =>
                new Condominium(areaLeve2Array[k])
              )
           }
           if(areaDistrict.length != 0){
             return Object.keys(areaDistrict).map(k =>
                new Condominium(areaDistrict[k])
              )
           }
        }
          // END LOCATION LEVELS


        return Object.keys(list).map(k =>
            new Condominium(list[k])
          )
        
      }
    },
    methods: {        
      getAdress(i){
        if(i.administrative_area_level_2 == '' || i.administrative_area_level_2 == null)
            return ''
        
        if(i.political != null)
            return i.political + ', ' + i.administrative_area_level_3

        return i.administrative_area_level_2 + ', ' + i.administrative_area_level_3
      },
      filtersEmpty(){
        let emptyValue = true

        if(this.applyFilters.baths != 0) 
          emptyValue = false
        
        if(this.applyFilters.beds != 0) 
          emptyValue = false


        if(this.applyFilters.problems != 0) 
          emptyValue = false

        if(this.applyFilters.name != '') 
          emptyValue = false

        if(this.applyFilters.locationUpdate != '') 
          emptyValue = false

        
        if(this.applyFilters.bedrooms.from != 0) 
          emptyValue = false

        if(this.applyFilters.bedrooms.to != 10) 
          emptyValue = false

        if(this.applyFilters.parking.from != 0) 
          emptyValue = false

        if(this.applyFilters.parking.to != 10) 
          emptyValue = false

        if(this.applyFilters.condominiumLocation != '')

        return emptyValue
      },    
      updateTagID(tag, id){
        this.quickAction(id, 'tag', tag)
      },
      serializeFalseForm(item){
        let ob = new CondominiumForm({})
        
        ob.id = item.id
        ob.tag_id = item.tag_id
        if(item.tag_id == undefined)
          ob.tag_id = null
          
        return ob
      },
      async quickAction(id, block, value){
        await this.condominiumQuickChange({id: id, block: block, value: value})
        if(block == 'highlighted'){
          let auxiliar_items = this.items
          let counter = 0
          auxiliar_items.forEach(element => {
            if(element.highlighted == 1)
              counter++
          });

          this.highlightedCounter = counter
        }
      },
      composeWhere(s){
        if(s.includes(',')){
          let newString = s.split(',')[0] + '\n' + s.split(', ')[1]
          //console.log(newString)
          return newString
        }
        return s
      },
      async refreshView(){
        await this.getCondominiums()
        this.refreshThisView += 1
      },
      confirmModal(i){
        this.condominium = i
        this.confirmationModal.title = 'Removing new development: ' + i.reference
        this.confirmationModal.message = "You are about to delete the following new development: \n\nTitle: " + i.information.title.en + "\nDescription: " + i.information.description.en + "\n\nShould I continue?"
        this.showModal = true
      },
      confirmArchiveOrUnarchive(i){
        this.condominium = i
        this.confirmationModal.title = this.$t('Developments.unarchive_title')
        this.confirmationModal.message = this.$t('Developments.unarchive_description')
        this.showModal_archive = true        
      },
      closeModal(){
        this.showModal = false
      },
      removeCondominium: async function(){
        await this.deleteCondominium({id: this.condominium.id})
        await this.getCondominiums()
        this.closeModal()
        this.reloadDataTable++
      },
      archiveThisCondominium: async function (){
        await this.archiveCondominium({id: this.condominium.id})
        await this.getCondominiums()
        this.$emit('update-view')
        this.reloadDataTable++
      },
      unArchiveThisCondominium: async function(){
        await this.unArchiveCondominium({id: this.condominium.id})
        await this.getCondominiums()
        this.$emit('update-view')
        this.reloadDataTable++
      },   
    }
  }
</script>
