import { mapActions } from 'vuex'

const mixin = {

    created: function() {
      console.debug(`Services action called.`)
    },

    methods: {
        ...mapActions(['getWhoWeAre', 'saveWhoWeAre']),
        async save(form) {
          try {
              await this.saveWhoWeAre({ 'data': form })
          } catch (error) {
            this.dataTableLoading = false
          }
        },
    }

}

export default mixin