import { mapActions } from 'vuex'
//import { buildPayloadPagination } from '@/utils/utils'

const mixin = {

  created: function() {
    console.debug(`Agents action called.`)
  },

  methods: {
    ...mapActions(['getCSV']),

    
  }
}

export default mixin