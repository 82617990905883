<template>
    <div id="myModal" class="modal allow-string-break">
        
    <!-- Modal content -->
    <div class="modal-content">
        <div>
            <p class="font-weight-bold black--text title allow-string-break">{{ message }}</p>
        </div>
        <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
            v-if="progressLoader == 1"
        ></v-progress-circular>
        <v-btn 
            v-if="progressLoader == 2"
            @click="okResponse" small depressed color="secondary" form="type-form" type="submit" class="px-0">
            Ok
        </v-btn>
        <v-btn 
            v-if="progressLoader == 3"
            @click="validateResponse" small depressed color="secondary" form="type-form" type="submit" class="px-0">
            {{$t('Export.btnCheck')}}
        </v-btn>
    </div>

    </div>
</template>
<style>
/* The Modal (background) */
.modal {
 /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(255, 255, 255, 0.8); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: inherit;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 40%; /* Could be more or less, depending on screen size */
  height: auto;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
<script>
export default {
    name: 'ExportersModal',
    props: {
        message: String,
        // 1 - Loader
        // 2 - OK Button
        // 3 - OK Button
        progressLoader: Number,
    },
    methods:{
        okResponse(){
            this.$emit('ok')
        },
        validateResponse(){
            this.$emit('exporter-validate')
        }
    }
}
</script>
