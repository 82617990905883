<template>
    <v-container fluid class="pt-0 mr-10 ml-10">
        <v-layout row wrap class="v-manage-property">
            <v-col cols="9">
                <v-card flat :loading="loadingItem">
                    
                    <v-toolbar flat class="mode-header" style="background-color: #BE5E3B !important;">
                        <v-toolbar-title
                                v-for="(language, index) in $t('LANGUAGES')"
                                :key="index"
                                class="cursor-pointer mr-5"
                                :class="language.id === locale ? 'white--text font-weight-medium' : 'darkgray--text font-weight-light unselected-language-style'"
                                @click="locale = language.id"
                        >
                            {{ language.name }}
                        </v-toolbar-title>
                    </v-toolbar>
                    <div class="ma-8 mb-0 pb-0">
                        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
                            <form id="service-form" @submit.prevent="handleSubmit(submit)">
                                <v-row>
                                    <!-- Translatable content -->
                                    <v-col cols="12" class="pt-4 pb-0" v-if="loadingItem">
                                        <v-window
                                                v-model="locale"
                                                vertical
                                        >
                                            <v-window-item
                                                    v-for="language in $t('LANGUAGES')"
                                                    :key="language.slug"
                                                    :ref="`window_${language.id}`"
                                                    :class="`window_${language.id}`"
                                            >

                                              <div  v-bind:style="language.slug=='pt' ? 'border-color:#4CAF50;border-top-style:solid;border-radius: 5px;' : ''">


                                                  <!-- Service Title -->
                                                  <input-text
                                                          :name="`name[${language.slug}]`"
                                                          :label="$t('SERVICES.SERVICE_TITLE')"
                                                          id="title"
                                                          rules="required|min:5"
                                                          v-model="service.title[language.slug]"
                                                          :fill="service.title[language.slug]"
                                                          @blur="onBlurAction"
                                                  >
                                                  </input-text>
                                                  <!-- end -->
                                            </div>
                                            <div  v-bind:style="language.slug=='pt' ? 'border-color:#4CAF50;border-top-style:solid;border-radius: 5px;' : ''">

                                                  <!-- Service Title -->
                                                  <input-textarea
                                                          v-if="false"
                                                          :name="`name[${language.slug}]`"
                                                          label="Description"
                                                          :rows="6"
                                                          class="pb-1"
                                                          v-model="service.description[language.slug]"
                                                          :fill="service.description[language.slug]"
                                                          @blur="onBlurAction"
                                                  >
                                                  </input-textarea>
                                                  <!-- end -->

                                                  
                                                  <label style="text-align: left;" class="d-block v-label theme--light mb-4 black--text font-weight-light">{{$t('SERVICES.SERVICE_DESCRIPTION_LONG')}}</label>
                                                  <vue-editor 
                                                    v-model="service.description[language.slug]" 
                                                    @blur="onBlurAction"
                                                    :editor-toolbar="customToolbar"
                                                    />
                                            </div>  

                                            </v-window-item>
                                        </v-window>
                                    </v-col>
                                </v-row>
                            </form>
                        </ValidationObserver>
                    </div>
                    <v-card flat>
                    <!-- Saving Action -->
                    <v-card-actions class="light-bg py-4 px-4">
                       <v-spacer></v-spacer>
                        <v-btn depressed color="primary" form="service-form" type="submit" @click="supportValidation">
                            {{$t('SERVICES.SAVE')}}
                        </v-btn>
                    </v-card-actions>
                    <!-- end -->
                  
                  </v-card>
                </v-card>
            </v-col>
        </v-layout>
    </v-container>
</template>

<script>
  import mixin from '@/models/services/ServicesModel'
  import { ServiceForm } from '@/store/structs/service'
  import { VueEditor } from "vue2-editor";

  
  export default {
    name: 'SetService',
    mixins: [mixin],
    components: { VueEditor },
    data(){
        return{
            loadingItem: false,
            locale: 0,
            service: ServiceForm,
            customToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "bullet" }],
            ]
        }
    },
    async created(){
        this.loadingItem = false
        let itemID = this.$route.params.id
        if (typeof itemID !== 'undefined') {
            await this.getServices()
            let retrievedData = this.$store.state.servicesModule.services

            if(retrievedData.length == 0)
                return

            let findingID = this.$route.params.id;
            retrievedData.forEach(element => {
                if(element.id == findingID)
                    this.service = new ServiceForm(element)         
            });
        }else{
            this.service = new ServiceForm({})
        }
        
        this.loadingItem = true
    },
    methods:{
        supportValidation() {
        let f = this.service
        let l = null
        this.$t('LANGUAGES').forEach(function(entry) {
          if (f.title[entry.slug].length < 5) {
            l = entry.id
          }
        })
        this.locale = l
      },
      onBlurAction(){
          if(this.service.id != null)
            this.submit()
      },
      async submit() {
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          await this.save(this.service)
        }
      }
    }
  }
</script>
