<template>
    <div>
        <VueCustomTooltip :label="$t('TOOLTIPS.EXPORT_PROPERTY')" position="is-left">
            <v-btn depressed color="secondary" class="square" @click="$emit('click')" >
                <i class="zwicon-export"></i>
            </v-btn>
        </VueCustomTooltip>
    </div>
</template>
<script>



export default {
    name: 'ExporterMenu',
    
}

</script>
