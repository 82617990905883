<template>
    <div>
      <div style="right: -16px; z-index: 9999; top: -16px; position: absolute;">
          
          
          <SpeedDial block="properties" :lowCost="getLowCost" @low-cost="lowCostToggle" :highlighted="getHiglighted" @highlight="higlightForm"/>
        </div>
        <div class="ma-8 mb-4 pb-5">
            <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
                <form id="property-form" @submit.prevent="handleSubmit(submit)">

                  <v-alert
                    v-if="false"
                    icon="mdi-shield-lock-outline"
                    prominent
                    text
                    type="info"
                  >
                  <v-row>
                    <v-col class="grow">
                      <label style="float:left;">{{$t('Export.Description')}}</label>
                    </v-col>
                    <v-col class="shrink">
                      <v-btn @click="openExportApi">{{$t('Export.Open')}}</v-btn>
                    </v-col>
                  </v-row>
                  
                  </v-alert>
                  <v-divider
                    v-if="false"
                    class="my-4 info"
                    style="opacity: 0.42"
                  ></v-divider>
                    <v-row>
                        <v-col cols="8" class="text-left py-0">
                            <v-card-title class="subtitle-2 font-weight-bold pt-2 px-0">
                                  {{$t('PROPERTIES.GENERAL')}}:

                                  <!-- PRICE TAG -->
                                  <v-chip
                                    class="ma-2"
                                    label
                                    small
                                    :color="getHidePrice == 0 ? 'indigo' : 'red darken-2'"
                                    text-color="white"
                                  >
                                    <v-avatar left>
                                      <v-icon small>mdi-currency-eur</v-icon>
                                    </v-avatar>
                                    <div v-if="!getForm.hide_price">{{$t('TOOLTIPS.PRICE_VISIBILITY')}}</div>
                                    <div v-else>{{$t('TOOLTIPS.PRICE_INVISIBILITY')}}</div>
                                  </v-chip>
                                  <!-- END PRICE TAG -->

                                  
                                  <!-- LOW COST TAG -->
                                  <v-chip
                                    v-if="getLowCost"
                                    class="ma-2"
                                    small
                                    close
                                    @click:close="lowCostToggle(0)"
                                    label
                                    color="green darken-2"
                                    text-color="white"
                                  >
                                    <v-avatar left>
                                      <v-icon small>mdi-sale</v-icon>
                                    </v-avatar>
                                    {{$t('TOOLTIPS.LOW_COST_DESCRIPTION')}}
                                  </v-chip>
                                  <!-- END LOW COST TAG -->

                                  <!-- HIGHLIGHTED TAG -->
                                  <v-chip
                                    v-if="getHiglighted"
                                    class="ma-2"
                                    close
                                    label
                                    @click:close="higlightForm(0)"
                                    small
                                    color="yellow darken-3"
                                    text-color="white"
                                  >
                                    <v-avatar left>
                                      <v-icon small>mdi-star-plus</v-icon>
                                    </v-avatar>
                                    {{$t('TOOLTIPS.HIGHLIGHT_DESCRIPTION')}}
                                  </v-chip>
                                  <!-- END HIGHLIGHTED TAG -->

                                  


                            </v-card-title> 
                            <!-- For Sale or Rent -->
                              <v-row class="subtitle-2 font-weight-bold mt-4" no-gutters>
                                <v-col cols="auto">
                                  <p class="font-weight-bold black--text" style="width: fit-content;">{{$t('PROPERTIES.BUSINESS')}}
                                    <toggle-button
                                        v-if="getForm.for_sale != null && dataLoading==true"
                                        :value="getForSale"
                                        class="v-label theme--light"
                                        :width=70                                   
                                        :colors="{checked: '#C48F56', unchecked: '#C48F56', disabled: '#CCCCCC'}"
                                        name="hidden_price"
                                        @input="forSaleToggle"
                                        :key="toggleKey"
                                        :labels="{checked: $t('PROPERTIES.FOR_RENT'), unchecked: $t('PROPERTIES.FOR_SALE')}"/>
                                  </p>
                                </v-col>
                                <v-col cols="auto" v-if="getLowCost==0 ? true :  false">
                                  <!-- Collection Privee -->
                                  <p class="font-weight-bold black--text ml-4">{{$t('PROPERTIES.COLLECTION')}}
                                    <toggle-button 
                                      :value="getCollectionPrivee==1 ? true : false"
                                      class="v-label theme--dark"
                                      :width=70                                        
                                      :colors="{checked: '#00FF00', unchecked: '#FF0000', disabled: '#CCCCCC'}"
                                      name="collection"
                                      @input="collectionToggle"
                                      :key="toggleCollectionKey"
                                      :labels="{checked: $t('PROPERTIES.YES'), unchecked: $t('PROPERTIES.NO')}"/>
                                  </p>   
                                  <!-- end -->  
                                </v-col>
                                <v-col cols="auto" v-if="false">
                                  <!-- Low Cost -->
                                  <p class="font-weight-bold black--text ml-4">{{$t('PROPERTIES.LOW_COST')}}
                                    <toggle-button 
                                      :value="getLowCost==1 ? true :  false"
                                      class="v-label theme--dark"
                                      :width=70                                        
                                      :colors="{checked: '#00FF00', unchecked: '#FF0000', disabled: '#CCCCCC'}"
                                      name="low_cost"
                                      @input="lowCostToggle"
                                      :key="toogleLowCostKey"
                                      :labels="{checked: $t('PROPERTIES.YES'), unchecked: $t('PROPERTIES.NO')}"/>
                                  </p>   
                                  <!-- end -->  
                                </v-col>
                              </v-row>
                            
                            <!-- end -->     
                            <group-search-list  v-if="getLowCost==0 ? true :  false" :key="condominiumKey" :form="form" @condominium-input-changed="openModal"></group-search-list>                       
                            <v-card-title class="subtitle-2 font-weight-bold pt-2 px-0">{{$t('PROPERTIES.TAG')}}</v-card-title>    
                            <group-propertytags 
                              @update-tag-id="updateTagID"
                              :form="getForm"
                              >
                            </group-propertytags>
                            
            
                            <!-- Reference -->
                            <input-text
                                    v-if="form.reference"
                                    name="reference"
                                    :placeholder="$t('PROPERTIES.REFERENCE')"
                                    :label="$t('PROPERTIES.REFERENCE')"
                                    id="reference"
                                    v-model="form.reference" 
                                    :fill="form.reference"
                                    @blur="onBlurAction"
                            >
                            </input-text>
                            <!-- end -->
                            <!-- Translatable -->
                            <group-translatable 
                                  @collection-toggle="collectionToggle"  
                                  @form-translatable="processTranslatable" 
                                  :form="form.i18n"
                                  :formPrivee="form"
                                  :locale="window" 
                                  @blur="onBlurAction"
                            >
                            </group-translatable>
                            <!-- end -->

                            
                        <!-- end -->                            
                        </v-col>
                        <v-col cols="4" class="text-left py-0">
                            <v-card flat class="short-information">
                                <v-card-title class="subtitle-2 font-weight-bold pt-2 px-0">
                                </v-card-title>
                                <v-card-subtitle class="caption font-weight-bold text-left mb-2 pb-4 px-0">
                                </v-card-subtitle>

                                <v-card-text class="all-inputs pb-0 px-0">
                                  <!-- Hide Price -->
                                   
                                     <p  v-if="getLowCost==0 ? true :  false" class="font-weight-bold black--text">{{$t('PROPERTIES.PRIVE_VISIBILITY')}}
                                      <toggle-button 
                                          v-if="getHidePrice != null && dataLoading==true"
                                          :value="getHidePrice==1 ? true :  false"
                                          class="v-label theme--light mb-2"
                                          style="margin-left: 20px;"
                                          :width=60                                        
                                          :colors="{checked: '#00FF00', unchecked: '#FF0000', disabled: '#CCCCCC'}"
                                          name="hidden_price"
                                          @input="priceToggle"
                                          :key="toggleKey"
                                          :labels="{checked: 'Show', unchecked: 'Hide'}"
                                          />
                                      </p>

                                    <!-- end -->  
                                  <!-- Price -->   
                                  <div class="v-input v-input-text v-input--is-label-active v-input--is-dirty theme--dark v-text-field v-text-field--prefix v-text-field--single-line v-text-field--filled v-text-field--is-booted v-text-field--enclosed v-text-field--placeholder"><div class="v-input__control"><div class="v-input__slot"><div class="v-text-field__slot"><div class="v-text-field__prefix">€</div>
                                      <currency-input
                                          class="v-input-text"
                                          v-model="form.price" 
                                          name="price"
                                          :placeholder="$t('PROPERTIES.PRICE')"
                                          prefix="€"
                                          :fill="form.price"
                                          autocomplete="off"
                                          rules="numeric"
                                          @blur="onBlurAction"
                                        >
                                        </currency-input>
                                      </div></div>
                                    <div class="v-text-field__details">
                                      <div class="v-messages theme--dark">
                                        <div class="v-messages__wrapper">
                                        </div>
                                      </div>
                                    </div></div></div>
                                  
                                    <!-- end -->

                                    <!-- Bedrooms -->
                                    <special-input-number 
                                                  v-model="form.bedrooms" 
                                                  :fill="String(form.bedrooms)"
                                                  :label="$t('PROPERTIES.BEDROOMS')" 
                                                  @on-blur-event="onBlurAction"
                                    ></special-input-number>
                                    <!-- end -->

                                    <!-- Bathrooms -->
                                    <special-input-number 
                                                  v-model="form.bathrooms" 
                                                  :fill="String(form.bathrooms)"
                                                  :label="$t('PROPERTIES.BATHROOMS')" 
                                                  @on-blur-event="onBlurAction"></special-input-number>
                                    <!-- end -->

                                    <!-- Parking -->
                                    <special-input-number 
                                                  v-model="form.parking" 
                                                  :fill="String(form.parking)"
                                                  :label="$t('PROPERTIES.PARKING')" 
                                                  @on-blur-event="onBlurAction">
                                    </special-input-number>
                                    <!-- end -->

                                    <!-- Gross Area -->
                                    <special-input-number
                                            name="gross_area"
                                            :label="$t('PROPERTIES.GROSS')+' '+$t('PROPERTIES.UNIT')" 
                                            id="gross_area"
                                            :suffix="$t('PROPERTIES.UNIT')"
                                            v-model="form.gross_area" 
                                            :fill="String(form.gross_area)"
                                             @on-blur-event="onBlurAction"
                                    >
                                    </special-input-number>
                                    <!-- end -->

                                    <!-- Living Area -->
                                    <special-input-number
                                            name="living_area"
                                            :label="$t('PROPERTIES.LIVING')+' '+$t('PROPERTIES.UNIT')" 
                                            id="living_area"
                                            :suffix="$t('PROPERTIES.UNIT')"
                                            v-model="form.living_area" 
                                            :fill="String(form.living_area)"
                                             @on-blur-event="onBlurAction"
                                    >
                                    </special-input-number>
                                    <!-- end -->

                                     <!-- Exterior Area -->
                                    <special-input-number
                                            name="exterior_area"
                                            :label="$t('PROPERTIES.EXTERIOR')+' '+$t('PROPERTIES.UNIT')" 
                                            id="exterior_area"
                                            :suffix="$t('PROPERTIES.UNIT')"
                                            v-model="form.exterior_area" 
                                            :fill="String(form.exterior_area)"
                                             @on-blur-event="onBlurAction"
                                    >
                                    </special-input-number>
                                    <!-- end -->
                                </v-card-text>
                            </v-card>
                        </v-col>
                        
                    </v-row>

                    <!-- Groups -->
                    <v-alert
                          v-if="$route.params.slug == undefined"
                          border="top"
                          colored-border
                          type="info"
                          elevation="1"
                          class="mt-2 alert-message-box"
                        >
                          {{$t('PROPERTIES.WARNING')}}
                      </v-alert>
                    <group-keys :disabled="$route.params.slug == undefined ? true : false" @form-types="processTypes" :form="form.types" @blur="onBlurAction"></group-keys>
                    <group-agents :disabled="$route.params.slug == undefined ? true : false" @form-agents="processAgents" :form="form.agent_id"></group-agents>
                    <group-search 
                            :disabled="$route.params.slug == undefined ? true : false"
                            @form-search="processSearch"
                            @form-district="processDistrict"
                            :form="form.search"
                            :prop_district="form.district_id"
                            @blur="onBlurAction"
                    ></group-search>

                </form>
            </ValidationObserver>
        </div>
        <!-- end -->

        <v-card flat>
            <!-- Saving Action -->
            <v-card-actions class="light-bg">
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" form="property-form" type="submit" @click="supportValidation">{{$t('PROPERTIES.SAVE')}}
                </v-btn>
            </v-card-actions>
            <!-- end -->
        </v-card>
        <ConfirmationModal 
          v-show="showModal" 
          @yes="condominiumInputChanged"
          @no="closeModal"
          :title="confirmationModal.title"
          :message="confirmationModal.message"
        />
        <ExportersModal
          :message="exportersMessage"
          v-show="exportersModal"
          :progressLoader="exporterProgressBar"
          @exporter-validate="exporterValidate"
          @ok="exportersModal = false"
        />
        <APIsExportModal
          :imovirtual_uuid="getForm.imovirtual_uuid"
          :idealista_uuid="String(getForm.idealista_uuid)"
          v-show="exportApiModal"
          @export="exportTo"
          @delete="deleteFrom"
          @update="updateTo"
          @close-modal="exportApiModal = false"
          :media="hasMedias"
        />

        <InformationModal 
          :message="informationModal.message" 
          v-if="informationModal.show"
          @ok="informationModal.show = false"

        />
    </div>
</template>

<script>
  //import tags from '@/data/tags'
  import { PropertyForm } from '@/store/structs/properties'
  import mixin from '@/models/properties/PropertiesModel'
  import exporterMixin from '@/models/exporter/ExporterModel'
  import ConfirmationModal from '@/components/Modals/ConfirmationModal'
  import ExportersModal from '@/components/Modals/ExportersModal'
  import APIsExportModal from '@/components/Modals/APIsExportModal'
  import SpeedDial from '@/components/common/SpeedDial'
  import InformationModal from '@/components/Modals/InformationModal'
  
  export default {
    name: 'General',

    mixins: [mixin, exporterMixin],
    components:{ SpeedDial, ConfirmationModal, ExportersModal, APIsExportModal, InformationModal },

    props: {
      lang: {
        type: Number,
        default: 0
      }
    },

    data: () => ({
      dataLoading: true,
      block: 'general',
      //listTags: tags,
      window: 0,
      separator: 1,
      toggleCollectionKey: 0,
      toogleLowCostKey: 0,
      toggleKey: 0,
      condominiumKey: 100,
      showModal: false,
      informationModal:{
        show: false,
        message:''
      },
      condominiumChangedEvent: null,
      selectedPlace: '',
      confirmationModal:{
        title: '',
        message: ''
      },
      exportersModal: false,
      form: PropertyForm,
      exporterProgressBar: 3,
      exportersMessage: '',
      exportApiModal:false,
      media: [],
      showExportButton: false
    }),
    

    async created() {
      this.dataLoading = false
      let itemID = this.$route.params.slug

      // if this, we need to check if property inserted
      if(this.$route.name == 'property-general-insertion-checker'){
        this.checkIfInserted(this.$route.params.thePlace)
     
      }

      if (typeof itemID !== 'undefined') {
        await this.getPropertyBlock({ id: itemID, block: 'media' })
        this.media = this.$store.state.propertiesModule.block
        
        this.getPropertyBlock({ id: itemID, block: this.block})
        
        this.showExportButton = true


      }
      // Chamar sempre a lista de condominios
      this.form = new PropertyForm({})

      if(!this.$route.path.includes('edit')){
        this.dataLoading = true
        this.form.for_sale = 0
        this.form.hide_price = 0
      }
    },

    computed: {
      getCollectionPrivee(){
        return this.form.collection
      },
      getHiglighted(){
        return this.form.highlighted
      },  
      getLowCost(){
        return this.form.low_cost
      },
      hasMedias(){
        return this.media
      },
      chekSlug(){
        let itemID = this.$route.params.slug
        if (typeof itemID !== 'undefined') {
          return true
        }
        return false
      },
      items() {
        return this.$store.state.propertiesModule.block
      },
      getForm(){
        return this.form
      },
      getForSale(){
        let flag = false
        
        if(this.form.for_sale == 1)
          flag = true

        return flag
      },
      getHidePrice(){
        let flag = false
        if(this.form.hide_price == 1)
          flag = true

        return flag
        
      }
    },

    watch: {
      items(val) {        
        this.form = new PropertyForm(val)
        this.toggleCollectionKey++       
        this.toggleKey++ 
        this.toogleLowCostKey++
        this.dataLoading = true
      },
      lang: {
        handler: function(val) {
          this.window = val
          this.toggleCollectionKey++
          this.toggleKey++
          this.toogleLowCostKey++
        },
        deep: true,
        immediate: true
      }
    },

    methods: {
      async checkIfInserted(place){
        this.exportersModal = true
        this.exporterProgressBar = 1
        this.exportersMessage = this.$t('Export.checkin')

        let parent = this
        // since the imovirtual api delays 2 seconds, we use 3 to give time to our server to process the information
        if(place != parent.$t('Export.Idelista')){
          setTimeout(async function(){
              let itemID = parent.$route.params.slug

              // Check If insertion was OK
              await parent.checkInsertion({place: place, id: itemID})

              let result = parent.$store.state.exporterModule.insertion

              if(result == "success"){
                parent.exportersMessage = parent.$t('Export.success_imovirtual')
                parent.exporterProgressBar = 2
              }else{
                parent.exportersMessage = parent.$t('Export.error_imovirtual') + ":\n\n" + parent.$store.state.exporterModule.log
                parent.exporterProgressBar = 2
              }
              
              await parent.getPropertyBlock({ id: itemID, block: parent.block})
              parent.form = new PropertyForm(parent.$store.state.propertiesModule.block)
          }, 3000);
          
          //this.exportersModal = false
        }else{
          // if not imovirtual, no need to wait
          let itemID = parent.$route.params.slug
          await parent.getPropertyBlock({ id: itemID, block: parent.block})
          parent.form = new PropertyForm(parent.$store.state.propertiesModule.block)
        }
      },
      openExportApi(){
        this.exportApiModal=true
      },
      exporterValidate(){
        let parent = this
        setTimeout(async function(){
          
          parent.exportersModal = false
        }, 1000);
        
      },
      async deleteFrom(place){
        let parent = this        
        this.exporterProgressBar = 1
        this.exportApiModal = false
        this.exportersModal = true
        this.exportersMessage = this.$t('Export.ExportInit');


        let final_uuid = this.form.idealista_uuid
        if(place == this.$t('Export.Imovirtual')){
          final_uuid = this.form.imovirtual_uuid
        }

        setTimeout(async function(){ 
          await parent.deleteFromPlatform({place: place, id: parent.form.id, uuid: final_uuid, url: btoa(window.location.href)})
          parent.exportersModal = false

          if(place == parent.$t('Export.Idelista')){
            let itemID = parent.$route.params.slug
            await parent.getPropertyBlock({ id: itemID, block: parent.block})
            parent.form = new PropertyForm(parent.$store.state.propertiesModule.block)
          }

        }, 1000);

      },
      async exportTo(place){
        let parent = this        
        this.exporterProgressBar = 1
        this.exportApiModal = false
        this.exportersModal = true
        this.exportersMessage = this.$t('Export.ExportInit');
        this.selectedPlace = place
        let itemID = this.$route.params.slug  
   
        setTimeout(async function(){
          await parent.exportToPlatform({place: place, id: itemID, url: btoa(window.location.href + '/checkInsertion' + '/' + place) });
          parent.exportersModal = false
        }, 1000);

/*
        // since the imovirtual api delays 2 seconds, we use 3 to give time to our server to process the information
        if(place != parent.$t('Export.Idelista')){
          setTimeout(async function(){
              let itemID = parent.$route.params.slug

              // Check If insertion was OK
              await this.checkInsertion({place: place, id: itemID})
              
              await parent.getPropertyBlock({ id: itemID, block: parent.block})
              parent.form = new PropertyForm(parent.$store.state.propertiesModule.block)
          }, 3000);
        }else{
          // if not imovirtual, no need to wait
          let itemID = parent.$route.params.slug
          await parent.getPropertyBlock({ id: itemID, block: parent.block})
          parent.form = new PropertyForm(parent.$store.state.propertiesModule.block)
        }
        */
        
      },
      async updateTo(place){
        let parent = this        
        this.exporterProgressBar = 1
        this.exportApiModal = false
        this.exportersModal = true
        this.exportersMessage = this.$t('Export.ExportInit');
        this.selectedPlace = place

  

        let final_uuid = this.form.idealista_uuid
        if(place == this.$t('Export.Imovirtual')){
          final_uuid = this.form.imovirtual_uuid
        }
        setTimeout(async function(){ 
          await parent.updateToPlatform({place: place, id: parent.form.id, uuid: final_uuid, url: btoa(window.location.href)});
          parent.exportersModal = false
          if(place == parent.$t('Export.Idealista')){
            let itemID = parent.$route.params.slug
            await parent.getPropertyBlock({ id: itemID, block: parent.block})
            parent.form = new PropertyForm(parent.$store.state.propertiesModule.block)
          }
        }, 1000);

        
      },
      async exportersModalFunction(){
        let parent = this
        setTimeout(function(){ 
            parent.exporterProgressBar = 2
            location.reload(true);
          }, 3000);
      },
      openModal(e){
        this.condominiumChangedEvent = e
        this.confirmationModal.title = 'Condominium changes'
        this.confirmationModal.message = 'You are about to change the condominium. This could affect all the form data.\n\nShould I continue?'
        this.showModal = true
      },
      closeModal(){
        this.showModal = false
        this.condominiumKey++
      },
      keymonitor: function(e) {
        this.form.price = new Intl.NumberFormat('pt-PT').format(e)
       },
      updateTagID: function(id){
        this.form.tag_id = id
        this.onBlurAction()
      },
      async condominiumInputChanged(){
        var id = this.condominiumChangedEvent;

        
        // If null and if link is new, so don't do anything and put everything as the starting point
        // Quando faço o clean do condominio
        if(this.$route.fullPath.includes('new')){
          if(id == null){
            this.form.condominium_id = null            
            this.showModal = false
            this.form = new PropertyForm({})
            return
          }
        }

        const formData = new FormData() 
        formData.append('condominium_id', id)
        formData.append('other_id', id)
        
        

        if(id != null){
          await axios.get('/properties/fillWithCondominiumData/'+id, formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then((response => {
            var obj = response.data.properties[0];
            // Deixa de funcionar se tirar o comentário...
            //this.form.types.elevator = obj.elevator;
            
            this.form.condominium_id = id
            
              
            // I18N - Languages (Description, title and slogan)
            if(this.form.i18n.description.en == '' || this.form.i18n.description.en == null){
              this.form.i18n.description.en = obj.i18n.description.en
            }
            if(this.form.i18n.description.pt == '' || this.form.i18n.description.pt == null){
              this.form.i18n.description.pt = obj.i18n.description.pt
            }

            if(this.form.i18n.title.en == '' || this.form.i18n.title.en == null){
              this.form.i18n.title.en = obj.i18n.title.en
            }
            if(this.form.i18n.title.pt == '' || this.form.i18n.title.pt == null){
              this.form.i18n.title.pt = obj.i18n.title.pt
            }

            if(this.form.i18n.slogan.en == '' || this.form.i18n.slogan.en == null){
              this.form.i18n.slogan.en = obj.i18n.slogan.en
            }
            if(this.form.i18n.slogan.pt == '' || this.form.i18n.slogan.pt == null){
              this.form.i18n.slogan.pt = obj.i18n.slogan.pt
            }
            // END

            
            // MAIN VIEW UPDATING            
            if(this.form.price == '' || this.form.price == null)
              this.form.price = parseFloat(obj.price);
            
            // TYPES UPDATING VIEW
            if(this.form.types.floor == '' || this.form.types.floor == null)
              this.form.types.floor = parseInt(obj.floor);

            if(this.form.types.lote_size == '' || this.form.types.lote_size == null){
              this.form.types.lote_size = obj.lote_Size;
              this.form.types.lote_size_type = obj.lote_size_type;
            }

            if(this.form.types.construction == '' || this.form.types.construction == null){
              this.form.types.construction = obj.construction;
              this.form.types.construction_area_type = obj.construction_area_type
            }

            if(this.form.types.land_size == '' || this.form.types.land_size == null){
              this.form.types.land_size = obj.land_size;
              this.form.types.land_size_type = obj.land_size_type
            }
            // END

            // AGENT UPDATE
            this.form.gent_id = obj.market_agent_id
            // END

            // SEARCH UPDATE
            this.form.search = new PropertyForm({}).search
            this.form.search.youtube_id = obj.search_youtube_id
            this.form.search.website = obj.search_website
            this.form.search.listing_id = obj.search_listing_id
            this.form.search.lifestyles_id = obj.search_lifestyles_id_toArray.split(',')
            this.form.district_id = obj.district_id
          
            // END

            if(this.form.types.year == '' || this.form.types.year == null)  
              this.form.types.year = obj.year;
            
            if(this.form.types.energy == '' || this.form.types.energy == null)
              this.form.types.energy = obj.energy;

            // MAIN UPDATING

            if(this.form.living_area == '' || this.form.living_area == null)
              this.form.living_area = obj.living_area;
            
            if(this.form.gross_area == '' || this.form.gross_area == null)
              this.form.gross_area = obj.gross_area;
            
            if(this.form.exterior_area == '' || this.form.exterior_area == null)
              this.form.exterior_area = obj.exterior_area;
            
            if(this.form.parking == '' || this.form.parking == null)
              this.form.parking = obj.parking;
            
            if(this.form.bathrooms == '' || this.form.bathrooms == null)
              this.form.bathrooms = obj.bathrooms;
            
            if(this.form.bedrooms == '' || this.form.bedrooms == null)
              this.form.bedrooms = obj.bedrooms;
            
            this.form.types.features_id = obj.features_id;
            this.form.types.type_id = obj.type_id


          })).catch((thrown) => {
            if (axios.isCancel(thrown)) {
                console.log('Request canceled', thrown.message)
            } else {
                // handle error
            }
          })
        }else{
          // null id
          this.form.condominium_id = null
        }

        this.showModal = false
        this.onBlurAction()
      },
      processTranslatable(e) {
        this.form.i18n = e
      },
      processAgents(e) {
        if(this.form.agent_id == e)
          this.form.agent_id = null
        else
          this.form.agent_id = e

        this.onBlurAction()
      },
      processTypes(e) {
        this.form.types = e
      },
      processSearch(e) {
        this.form.search = e
      },
      processDistrict(e) {
        this.form.district_id = e
      },
      priceToggle(e){
        this.form.hide_price = e
        this.onBlurAction()
      },
      forSaleToggle(e){
        this.form.for_sale = e
        this.onBlurAction()
      },
      collectionToggle(e){
        this.form.collection = e
        this.onBlurAction()
      },
      lowCostToggle(e){
        this.form.low_cost = e
        this.onBlurAction()
      },
      async higlightForm(h){
        await this.getProperties()
        let counter = 0
        this.$store.state.propertiesModule.properties.forEach(element => {
          if(element.highlighted == 1)
            counter++
        });
        if(counter >= 18){
          this.informationModal.show = true
          this.informationModal.message = this.$t('HIGHLIGHT_EXCESS.ERROR')
          return
        }

        this.form.highlighted = h
        this.onBlurAction()
      },
      supportValidation() {
        let f = this.form
        let l = null
        this.$t('LANGUAGES').forEach(function(entry) {
          if (f.i18n.title[entry.slug].length < 5) {
            l = entry.id
          }
        })
        this.window = l
      },
      onBlurAction(){
        let toReload = this.form
        if(toReload.id != null)
          this.submit()
      },

      async submit() {
        // Prefix
        this.form.types['loaded'] = 1
        this.form.search['loaded'] = 1
        

        const isValid = await this.$refs.observer.validate()
        let toReload = this.form

        if (isValid) {
          await this.save(this.form)
        }

        if(toReload.id == null){
          let itemID = this.$route.params.slug
          if (typeof itemID !== 'undefined') {
            this.getPropertyBlock({ id: itemID, block: this.block})
          }
        }
        this.condominiumKey++
      }
    }

  }
</script>
