<template>
    <div>profile</div>
</template>

<script>
  export default {
    name: 'ProfileAgent'
  }
</script>

<style scoped>

</style>
