<template>
    <v-container fluid class="pt-0 mr-10 ml-10">
        <v-row v-if="dataLoaded">
            <v-col cols="7">
                <!-- Properties Analytics -->
                <v-card flat>
                    <div class="py-3 px-4">
                        <v-card-title class="principalCardTitle font-weight-bold ">{{$t('DASHBOARD.PROPERTIES_ANA')}}</v-card-title>
                        <v-card-subtitle class="caption text-left font-weight-bold">
                            
                        </v-card-subtitle>
                    </div>
                    <chart-properties v-if="computeLastProperties != null" :lastProperties="computeLastProperties"/>
                    
                </v-card>
                <!-- end -->

                
               
            </v-col>
            <v-col cols="4">
                <!-- User Info -->
                <user-welcome />
                <!-- end -->
            </v-col>

            
            <v-col cols="11">
                 <!-- Widgets -->
                <widgets-badges :properties_sold_archived="computePropertiesSoldArchived" :condominiums_sold_archived="computeCondominiumsSoldArchived"/>
                <!-- end -->
            </v-col>


            <v-row>
                <v-col cols="11">
                    <v-row>
                        <v-col cols="6">
                            <MostViewedProperties :mostViewed="computeMostViewed"/>
                        </v-col>
                        <v-col cols="6">                    
                            <MostViewedCondominiums :mostViewed="computeMostViewedCondominiums"/>
                        </v-col>
                    </v-row>
                </v-col>
                
            </v-row>
            
            <v-col cols="11">
                <v-row>
                        <v-col cols="6">
                            <last-properties/>
                        </v-col>
                        <v-col cols="6">                    
                            <LastCondominiums/>
                        </v-col>
                    </v-row>
                <!-- Last Properties -->
                
                <!-- end -->
            </v-col>


            
            
            
            
            <v-col cols="4">

                <!-- Districts Analytics -->
                <v-card flat v-if="false">
                    <div class="py-3 px-4">
                        <v-card-title class="subtitle-2 font-weight-medium">Districts</v-card-title>
                        <v-card-subtitle class="v-card__subtitle caption text-left font-weight-medium secondaryCard">
                            Most visited locations
                        </v-card-subtitle>
                    </div>
                    <div>
                        <chart-districts class="pa-10 pt-5"/>
                    </div>
                </v-card>
                <!-- end -->
            </v-col>
        </v-row>        
    </v-container>
</template>

<script>
  import mixin from '@/models/dashboard/DashboardModel'
  import mixin2 from '@/models/properties/PropertiesModel'
  import mixinCondo from '@/models/condominium/CondominiumsModel'
  import MostViewedProperties from '@/components/dashboard/MostViewedProperties'
  import MostViewedCondominiums from '@/components/dashboard/MostViewedCondominiums'
  import LastCondominiums from '@/components/dashboard/LastCondominiums'
  export default {
    name: 'Dashboard',
    mixins: [mixin, mixin2, mixinCondo],
    components: {
        MostViewedProperties,
        MostViewedCondominiums,
        LastCondominiums
    },
    data(){
        return{
            dataLoaded: false,
            lastProperties: null,
            mostViewedProperties: null,
            mostViewedCondominiums: null,
            properties_sold_archived:{
                properties_amount: 0,
                totalSoldArchived: 0,
                sold: 0
            },
            condominiums_sold_archived: {
                condominiums_amount: 0,
                archived: 0
            }

        }
    },
    async created(){
        await this.getStatistics()
        await this.getProperties()
        await this.getCondominiums()



        if(this.$store.state.dashboardModule.statistics != null){
            this.lastProperties = this.$store.state.dashboardModule.statistics.count_properties_by_month
            this.mostViewedProperties = this.$store.state.dashboardModule.statistics.properties_data
            
            this.mostViewedCondominiums = this.$store.state.dashboardModule.statistics.condominiums_data
        }

        
        this.properties_sold_archived.properties_amount = this.$store.state.propertiesModule.properties.length
        let propertiesResult = this.calculateSoldArchivedProperties()
        this.properties_sold_archived.totalSoldArchived = propertiesResult[0] + propertiesResult[1]
        this.properties_sold_archived.sold = propertiesResult[0]


        this.condominiums_sold_archived.condominiums_amount = this.$store.state.condominiumsModule.condominiums.length
        this.condominiums_sold_archived.archived = this.calculateSoldArchivedCondomoniums()

        this.dataLoaded = true
    },
    methods:{
        calculateSoldArchivedCondomoniums(){
            let condominiums = this.$store.state.condominiumsModule.condominiums

            let archived = 0;
            condominiums.forEach(element => {
                if(element.archived == 1)
                    archived++
            });
            return archived
        },
        calculateSoldArchivedProperties(){
            let properties = this.$store.state.propertiesModule.properties
            let sold = 0;
            let archived = 0;
            properties.forEach(element => {
                if(element.archived == 1)
                    archived++

                if(element.tag_id == 3)
                    sold++
            });

            return [sold, archived]
        }
    },
    computed:{
        computePropertiesSoldArchived(){
            return this.properties_sold_archived            
        },
        computeCondominiumsSoldArchived(){
            return this.condominiums_sold_archived            
        },
        computeMostViewedCondominiums(){
            return this.mostViewedCondominiums
        },
        computeMostViewed(){
            return this.mostViewedProperties
        },
        computeLastProperties(){
            return this.lastProperties
        }
    }
  }
</script>
