import { mapActions } from 'vuex'

const mixin = {

  created: function() {
    console.debug(`Dashboard action called.`)
  },

  methods: {
    ...mapActions(['getStatistics']),
    
  
  }
  
}

export default mixin