<template>
    <div id="myModal" class="confirmationModal">

    <!-- Modal content -->
    <div class="modal-content">
        <div>
            <p class="title">{{title}}</p>
            <v-divider class="my-3"></v-divider>
            <p class="subtitle">{{ message }}</p>
        </div>
        <v-divider class="my-3"></v-divider>
        <div class="buttonsDiv">
            <v-btn @click="yesResponse" small color="success" depressed form="type-form" type="submit" class="px-6 py-6 successBTN">
                <i class="zwicon-task"></i>{{$t('REMOVING_MODAL.CONFIRM')}}
            </v-btn>
            <v-btn @click="noResponse" small depressed form="type-form" type="submit" class="px-6 py-6 backBTN">
                <i class="zwicon-sign-out"></i>{{$t('REMOVING_MODAL.BACK')}}
            </v-btn>
        </div>
    </div>

    </div>
</template>

<script>
export default {
    name: 'ConfirmationModal',
    props: {
        title: String,
        message: String,
    },
    methods:{
        yesResponse(){
            this.$emit('yes')
        },
        noResponse(){
            this.$emit('no')
        }
    }
}
</script>
