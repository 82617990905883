<template>
    <div class="ma-8 mb-4 pb-8">
        <!-- Upload & Browse -->
        <v-card
                flat
                color="black"
                class="custom-color v-seo-preview text-left"
        >
            <browse-files :counterItems="items.length" :key="reloadBrowsing" @reload-me="reloadFiles" @process-server="saveMediaFiles"></browse-files>
        </v-card>
        <!-- end -->
        
        <!-- List files -->
        <v-card
                flat
                class="mt-5"
        >
            <v-card-title class="pl-0 text-left subtitle-1 font-weight-bold">{{ $t('PROPERTIES.GENERAL_MEDIA_TITLE') }}</v-card-title>
            <v-card-subtitle class="pl-0 text-left caption mb-2 black--text">{{ $t('PROPERTIES.GENERAL_MEDIA_SUBTITLE') }}
            </v-card-subtitle>

            <!--<v-btn class="secondary pa-0 v-btn" depressed @click="reorderFunction">
                <i class="zwicon-arrow-right" v-if="!toEdit">Sort</i>
                <i class="zwicon-arrow-right" v-if="toEdit">See Gallery</i>
            </v-btn>
            
            <list-files 
            v-if="false"
                @remove-image="removeImage" 
                @generated-file="saveFileGenerated" 
                @change-cover="changeCover"
                :files="items" 
                :ID="ID"
                :location="location"
                :key="renderComponent"
              >
            </list-files> -->
            <drag-grid 
              @reordered="reorderMedia"
              @remove-image="removeImage" 
              @generated-file="saveFileGenerated" 
              @change-cover="changeCover"
              @change-plant="changePlant"
              @remove-all-media="removeAllImages"
            
            :items="items" :ID="parseInt(ID)" location="properties"></drag-grid>
         
        </v-card>
        <!-- end -->
        <LoadingModal v-if="loadingModal" :message="processModalMessage"/>
    </div>
</template>
<script>

  import mixin from '@/models/properties/PropertiesModel'
  import LoadingModal from '@/components/Modals/LoadingModal'
  //http://sagalbot.github.io/vue-sortable/

  
 
  export default {
    name: 'Media',

    mixins: [mixin],
    components: {LoadingModal},
    data: () => ({
      block: 'media',
      files: [],
      ID: Number,
      toEdit: false,
      location: String,
      renderComponent: 0,
      loadingModal: false,
      uploadQuantity: 0,
      reloadBrowsing: 0 + 'A',
      processMessage: '',
      filesForUpload: []
    }),

    async created() {
      this.files = [];
      if (typeof this.$route.params.slug !== 'undefined') {
        await this.getPropertyBlock({ id: this.$route.params.slug, block: this.block })
      }
      this.processMessage = `${this.$t('loadingModal.WAIT_MESSAGE')}`
    },

    computed: {
      items() { 
        let myItems = this.$store.state.propertiesModule.block

        console.log("ITEMS")
        console.log(myItems)
        
        if(!Array.isArray(myItems))
          return []

        // ORDENAR POR ORDERING E POR ID...
        myItems = myItems.sort((a, b) => a.ordering - b.ordering );
        return myItems
      },
      processModalMessage(){
        return this.processMessage
      }
    },
    watch: {
      items(val) {
        this.files = val
        this.ID = this.$route.params.slug
        this.location = "properties"
      },
    },
    methods:{
      reorderMedia: async function (e){
        //console.log(e)
        await this.reorderImage(e)
        //this.renderComponent = this.renderComponent + 1;
      },
      reorderFunction: function (){
        if(!this.toEdit)
          this.getPropertyBlock({ id: this.$route.params.slug, block: this.block });
          
        this.toEdit = !this.toEdit
      },
      reloadFiles: function(){
        this.loadingModal = false
        this.reloadBrowsing += 1
        this.uploadQuantity = 0
      },
      sleep: function(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
      
      saveMediaFiles: async function (file, loadingBar, filePos){
        this.loadingModal = !loadingBar

        var id = this.$route.params.slug;
        const formData = new FormData()
        formData.append('image', file)         
        formData.append('filePos', filePos)    
        
        this.filesForUpload.push({filesPos: filePos, formData: formData});
        //await this.saveMediaFilesFromProperty({ id: id, data: formData});
        
        //await this.getPropertyBlock({ id: this.$route.params.slug, block: this.block });
        
        this.renderComponent = this.renderComponent + 1;

        if(this.loadingModal == false){
          this.processMessage = `${this.$t('loadingModal.FINISHING_PROCESS')}`
          this.loadingModal = true




          this.filesForUpload.sort((a, b) => a.filesPos - b.filesPos );

          let parent = this
          

          let flag = true
          let i = 0
          
          while(flag){
            await parent.saveMediaFilesFromProperty({ id: id, data: parent.filesForUpload[i].formData});
            if(i == parent.filesForUpload.length-1)
              flag = false

            i++
          }
          
          await this.sleep(2500)

          //this.filesForUpload.forEach(element => {
          //  await this.saveMediaFilesFromProperty(element);
          //});

          

          //await this.saveMediaFilesFromProperty({ id: id, data: this.filesForUpload});

          await this.getPropertyBlock({ id: this.$route.params.slug, block: this.block });
          
          this.filesForUpload = []
          
          this.loadingModal = false
          this.reloadBrowsing += 1
          this.uploadQuantity = 0
        }else
          this.uploadQuantity++

      },saveFileGenerated: async function(file, id, media_id, image_name){
        // to replace: '/{id}/{media_id}/media/replace'
        var id = this.$route.params.slug;
        const formData = new FormData()
        formData.append('image', file)
        formData.append('name', image_name)        

        await this.saveFileGeneratedFromProperty({ id: id, media_id: media_id, data: formData});

        this.getPropertyBlock({ id: this.$route.params.slug, block: this.block });
        this.renderComponent = this.renderComponent + 1;
        
      },
      changeCover: async function(file, id, media_id, image_name){
        // {id}/{media_id}/media/cover
        
        const formData = new FormData()
        formData.append('image', file)
        formData.append('name', image_name) 

        // Change Cover
        await this.changeCoverImage({ id: id, media_id: media_id, data: formData});

        this.getPropertyBlock({ id: this.$route.params.slug, block: this.block });
        this.renderComponent = this.renderComponent + 1;
      },
      changePlant: async function(file, id, media_id, image_name){
        const formData = new FormData()
        formData.append('file', file)
        formData.append('id', id) 
        formData.append('media_id', media_id) 
        formData.append('image_name', image_name) 

        // Change Cover
        await this.attributePlant({ id: id, media_id: media_id, data: formData});

        this.getPropertyBlock({ id: this.$route.params.slug, block: this.block });
        this.renderComponent = this.renderComponent + 1;
      },
      removeImage: async function(location, fileName, media_id){
        // /{id}/media/remove

        var id = this.$route.params.slug;
        const formData = new FormData()
        formData.append('fileName', fileName)
        formData.append('mediaID', media_id)

        await this.removeImageFromProperty({ id: id, data: formData});
        this.getPropertyBlock({ id: this.$route.params.slug, block: this.block });
        this.renderComponent = this.renderComponent + 1;
      },
      async removeAllImages(){
        var id = this.$route.params.slug;
        await this.removeAllImagesFromProperty(id)
        this.getPropertyBlock({ id: this.$route.params.slug, block: this.block });
        this.renderComponent = this.renderComponent + 1;
      }
    }

  }
</script>