<template>
    <v-container>
      <v-row v-if="dataLoading">
            <v-col xl="10" lg="8" cols="8" >
              <p class="page-title text-capitalize">{{ $t('DISTRICT_LISTINGS.TITLE') }}</p>
              <p class="page-subtitle"> {{ $t('DISTRICT_LISTINGS.DESCRIPTION') }}</p>
            </v-col>
        </v-row>
        <v-row v-if="dataLoading">
          <v-card flat class="mt-3 w-full">
            <div class="py-3 px-4 w-full">
              <v-toolbar flat class="mode-header">
                <v-toolbar-title>
                  {{ $t('Listings.total_of') }} {{ items.length }} <span class="text-capitalize"> {{ $t('DISTRICT_LISTINGS.DISTRICTS') }}</span>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn depressed color="secondary" class="square" to="/districts/add">
                    <i class="zwicon-plus"></i>
                </v-btn>
              </v-toolbar>
              <div class="px-5 pb-4 listing-div w-full">

                <v-data-table
                        :key="reloadDataTable"
                        class="table__minimal recent-properties elevation-0 td-data-table"
                        :loading="dataTableLoading"
                        :items="items"
                        :options.sync="pagination"
                        :headers="headers"
                        :loading-text="$t('Developments.loading')"
                        :hide-default-footer="false"
                        :calculate-widths="true"
                        :items-per-page="5"
                >
                    <template v-slot:[`item.image`]="{ item }">
                        <v-img
                                :src="$mediaFile('districts', item.id, item.cover)"
                                :lazy-src="$mediaFile('districts', item.id, item.cover)"
                                height="150"
                                width="250"
                                class="grey lighten-2 d-block rounded"
                        ></v-img>
                    </template>

                    <template v-slot:[`item.information`]="{ item }" >
                        <div class="main__info">
                            <p class="mb-0">{{item.name}}</p>
                            <p class="mb-0 font-weight-medium black--text listing-sub-sub-title">{{ $processLanguage(item.description).slice(0, 150) }} (...)</p>
                            <p class="listing-kpis"><DistrictKPI :multiLanguage="true" :kpis="item.kpis"/></p>
                        </div>
                    </template>

                    <template v-slot:[`item.view`]="{ item }">
                      <v-btn :to="`/districts/edit/${item.id}`" color="#D8D8D8" class="pa-0 ma-0" depressed small>
                            <i class="zwicon-pencil"></i>
                        </v-btn>
                        <v-btn @click="confirmModal(item)" color="#D8D8D8" label class="pa-0 ma-0 mt-2" depressed small>
                          <i class="zwicon-trash"></i>
                        </v-btn>
                    </template>
                    
                </v-data-table>
              </div>
            </div>
          </v-card>
        </v-row>
        <ConfirmationModal v-show="showModal" 
          @yes="removeDistrict"
          @no="closeModal"
          :title="confirmationModal.title"
          :message="confirmationModal.message"
        />
    </v-container>   
</template>

<script>
  import { mapActions } from 'vuex'
  import { DistrictTranslated } from '@/store/structs/district'
  import ConfirmationModal from '@/components/Modals/ConfirmationModal'
  import mixin from '@/models/districts/DistrictsModel'

  export default {
    name: 'Listings',
    components: { ConfirmationModal },
    mixins: [mixin],
    data: () => ({
      dataLoading: true,
      dataTableLoading: true,
      pagination: {},
      headers: [
        { value: 'image', width: '20%', align: 'img' },
        { value: 'information', width: '75%', align: 'left'  },
        { value: 'view', width: '5%', align: 'img' }
      ],
      showModal: false,
      confirmationModal:{
        title: '',
        message: ''
      },
      district: null,
      reloadDataTable: 0
    }),

    async created(){
      this.dataLoading = false
      await this.getDistricts()
      this.$emit('districts-count', this.$store.state.districtsModule.districts.length)
      this.dataLoading = true
    },

    methods: {
      ...mapActions(['getDistricts']),
      confirmModal(i){
        this.district = i
        this.confirmationModal.title = 'Removing district: ' + i.name
        this.confirmationModal.message = "You are about to delete the following district: \n\nName: " + i.name + "\n\nShould I continue?"
        this.showModal = true
      },
      closeModal(){
        this.showModal = false
      },
      async removeDistrict(){
          await this.deleteItem(this.district.id)
          this.$emit('update-view')
      },
    },

    watch: {
      pagination: {
        async handler() {
          try {
            this.dataTableLoading = true
            await this.getDistricts()
            this.dataTableLoading = false
            // eslint-disable-next-line no-unused-vars
          } catch (error) {
            this.dataTableLoading = false
          }
          //console.log(this.dataTableLoading)
        },
        deep: true
      }
    },

    computed: {
      items() {
        let retrievedData = this.$store.state.districtsModule.districts
        
        if(retrievedData.length == 0)
          return []

        //console.log(new District(retrievedData[0]))
        
        return Object.keys(retrievedData).map(k =>
          new DistrictTranslated(retrievedData[k])
        )
      }
    }
  }
</script>
