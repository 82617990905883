<template>
    <v-container>
      <v-row v-if="dataLoading">
            <v-col xl="10" lg="8" cols="8" >
              <p class="page-title text-capitalize">{{ $t('CSV_CONTACTS.LISTING') }}</p>
              <p class="page-subtitle"> {{ $t('CSV_CONTACTS.DESCRIPTION') }}</p>
            </v-col>
        </v-row>
      <v-row v-if="dataLoading">
            <v-card flat class="mt-3 w-full">
              <div class="py-3 px-4 w-full">
                <v-toolbar flat class="mode-header">
                  <v-toolbar-title>
                    {{$t('CSV_CONTACTS.TOTAL_OF')}} {{getCSVLength}} <span class="text-capitalize"> {{$t('CSV_CONTACTS.CONTACTS')}}</span>&nbsp;{{$t('CSV_CONTACTS.FOLLOWING')}}
                  </v-toolbar-title>
                  
                  

                <v-spacer></v-spacer>

                <v-btn depressed color="secondary" class="square" @click="generateCSV()">
                    <i class="zwicon-floppy"></i>
                </v-btn>
                </v-toolbar>
                <div class="px-5 pb-4 listing-div w-full">
                  <v-data-table
                        class="table__minimal recent-properties elevation-0 td-data-table"
                        :loading="dataTableLoading"
                        :items="items"
                        :options.sync="pagination"
                        :headers="headers"
                        :loading-text="$t('Developments.loading')"
                        :hide-default-footer="false"
                        :calculate-widths="true"
                        :items-per-page="getCSVLength"
                >
                    <template v-slot:[`item.Title`]="{ item }">
                        <p class="mb-0 font-weight-bold black--text listing-sub-sub-title">{{ item[0] }}</p>
                    </template>
                    <template v-slot:[`item.Description`]="{ item }">
                        <p class="mb-0 font-weight-bold black--text listing-sub-sub-title">{{ item[1] }}</p>
                    </template>
                  </v-data-table>
               
                </div>
              </div>
            </v-card>
      </v-row>
    </v-container>
</template>

<script>

  import mixin from '@/models/administration/AdministrationModel'

  export default {
    name: 'DownloadContacts',
    mixins:[mixin],
    data(){
        return{
            dataLoading: false,
            dataTableLoading: true,
            pagination: {},
            headers: [
              { value: 'Title', width: '50%', align: 'left' },
              { value: 'Description', width: '50%', align: 'left'  }
            ],
        }
    },
    async created(){
        await this.getCSV()
        this.dataLoading = true
    },
    watch: {
      pagination: {
        async handler() {
          try {
            this.dataTableLoading = true
            await this.getCSV()
            this.dataTableLoading = false
            // eslint-disable-next-line no-unused-vars
          } catch (error) {
            this.dataTableLoading = false
          }
          //console.log(this.dataTableLoading)
        },
        deep: true
      }
    },
    methods:{
      generateCSV(){
        let data = this.$store.state.administrationModule.csv_contacts

        let csvContent = ''

        data.forEach(function(rowArray) {
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var encodedUri = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
        
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "CSV_Contacts.csv");
        document.body.appendChild(link); 
        link.click();
      }
    },
    computed:{
      getCSVLength(){
        return this.$store.state.administrationModule.csv_contacts.length
      },
      items(){
        console.log(this.$store.state.administrationModule.csv_contacts)
        return this.$store.state.administrationModule.csv_contacts
      }
    }
  }
</script>
