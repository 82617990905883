<template>
  <div :key="reloadDiv" v-if="center!=null">
      <v-card
                flat
                class="mt-5"
      >
            <v-card-title class="text-left subtitle-1 font-weight-bold">{{$t('SEARCH.LOCATION')}}</v-card-title>
            <v-card-subtitle class="text-left caption mb-2 black--text">{{$t('SEARCH.INSERT_DRAW')}}
            </v-card-subtitle>
            
            
            <gmap-autocomplete style="border: 1px solid; width: 60%; font-size: 16px; font-family: roboto;"
              @place_changed="setPlace">
            </gmap-autocomplete>
         
      </v-card>
      
    
      <div class="form-group" v-show="!showRemover">
        <v-btn v-show="!showSaver" @click="drawCircle" style="margin-top: 20px; margin-bottom: 20px;" depressed color="primary">
          <span class="white--text"><i class="zwicon-earth-alt"></i> {{$t('SEARCH.DRAW')}}</span>
        </v-btn>
        <v-btn v-show="showSaver" style="margin-top: 20px; margin-bottom: 20px;" depressed color="primary"
          form="condominium-form" type="submit" @click="confirmPositionModal"><span class="white--text"><i class="zwicon-floppy"></i> {{$t('SEARCH.SAVE')}}</span>
        </v-btn>
        <v-btn v-show="showSaver" style="margin-top: 20px; margin-bottom: 20px;" depressed color="secondary"
          form="condominium-form" type="submit" @click="cancelAddMarker"><span class="white--text"><i class="zwicon-close-circle"></i> {{$t('SEARCH.CANCEL')}}</span>
      </v-btn>
      </div>
      <v-btn v-show="showRemover" style="margin-top: 20px; margin-bottom: 20px;" depressed color="danger"
          form="condominium-form" type="submit" @click="removeMarkOnMap"><span class="white--text"><i class="zwicon-trash"></i> {{$t('SEARCH.REMOVE')}}</span>
      </v-btn>
      <v-btn v-show="showRemover" style="margin-top: 20px; margin-bottom: 20px;" depressed color="secondary"
          form="condominium-form" type="submit" @click="cancelRemoveMarkOnMap"><span class="white--text"><i class="zwicon-close-circle"></i> {{$t('SEARCH.CANCEL')}}</span>
      </v-btn> 

      

    <v-expansion-panels v-if="this.$store.state.propertiesModule.block[0] != undefined && this.center != null">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <template>
            <v-row no-gutters>
              <v-col cols="12">
                <v-banner two-line style="white-space: pre-line; text-align: left;">
                  <v-icon
                    slot="icon"
                    color="warning"
                    size="36"
                  >
                    mdi-map-marker-radius-outline
                  </v-icon>
                  <v-row>
                    <v-col cols="5"><p>{{$t('SEARCH.LOCATION_ADDRESS')}}:</p> {{getRouteOnly}}</v-col>
                    <v-col><p>{{$t('SEARCH.ZONE')}}:</p>{{this.$store.state.propertiesModule.block[0].political}}</v-col>
                    <v-col><p>{{$t('SEARCH.CONCELHO')}}/{{$t('SEARCH.DISTRICT')}}:</p>{{this.$store.state.propertiesModule.block[0].gmap_county}} / {{this.$store.state.propertiesModule.block[0].gmap_district}}</v-col>
                  </v-row>
                    
                </v-banner>
              </v-col>              
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <p>{{$t('SEARCH.EDIT_DATA_LOCATION')}}</p>
          <input-text
            v-model="gmapRoutes.routeLocation"
            :placeholder="this.$store.state.propertiesModule.block[0].route"
            :fill="this.$store.state.propertiesModule.block[0].route"
            class="v-text-field__details"
            :label="$t('SEARCH.LOCATION_ADDRESS')"
            @blur="editManualAddress"
          ></input-text>
          <input-text
            v-model="gmapRoutes.political"
            :placeholder="this.$store.state.propertiesModule.block[0].political"
            :fill="this.$store.state.propertiesModule.block[0].political"
            class="v-text-field__details"
            :label="$t('SEARCH.ZONE')"
            @blur="editManualAddress"
          ></input-text>
      </v-expansion-panel-content>
    </v-expansion-panel>
    </v-expansion-panels>
      
      <v-alert
            v-if="showAlert"
            text
            outlined
            color="deep-orange"
            icon="mdi-fire"
            >
            {{alertMessage}}
        </v-alert>

    <div
          v-if="center!=null">
      <v-card :elevation="24" >
      <GmapMap id="gMap"
          :center="getCenterPosition"
          :zoom="15"
          :options="getOptions"
          ref="gmapref"
          map-type-id="roadmap"
          style="width: 100%; height: 700px; margin-left: auto; margin-right: auto;">


          <GmapCircle
            v-for="(m, index) in newMarkers"
            :key="index"
            :draggable="true"
            :center="m.position"
            :position="m.position"
            :radius="55"
            :visible="true"
            @center_changed="updateCoordinates"
            @radius_changed="updateRadius"
            :options="{
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity:0.35
                      }"
            
          />
          <GmapCircle
            v-for="(m, i) in items"
            :key="'Pos: ' + i"
            :center="m.position"
            :radius="55"
            :visible="true"
            :options="selectedMark==m.id ? 
                      {
                        strokeColor: '#000000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#000000',
                        fillOpacity:0.35
                      } : 
                      { 
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity:0.35
                      }"
            @click="showRemoverBtn(m)"
          />
          
      </GmapMap>
      </v-card>
    </div>
    <v-dialog
          width="500"
          v-model="informationModal.show">
          <DialogModalConfirmation 
            @yes="saveMapChanges"
            @no="informationModal.show = false"
            :title="$t('SEARCH.SURE')"
            :message="informationModal.message" 

          />
        </v-dialog>
  </div>
</template>

<script>
import mixin from '@/models/properties/PropertiesModel'
import DialogModalConfirmation from '@/components/Modals/DialogModalConfirmation'

export default {
  name: "GoogleMap",
  components: { DialogModalConfirmation },
  mixins: [mixin],
  data() {
    return {
      confirmationModal:{
        title: '',
        message: ''
      },
      informationModal:{
        show: false,
        message:''
      },
      dataLoading: true,
      center: null,
      markers: [],
      selectedMark: -1,
      markerChild: {
        lat: 0,
        lng: 0,
        radius: 55
      },
      newMarkers: [],
      showSaver: false,
      showRemover: false,
      toDeleteMarkers: [],
      places: [],
      reloadDiv: 0,
      currentPlace: null,
      block: 'location',
      gmapRoutes:{
        concelho: '',
        distrito: '',
        administrative_level_2: '',
        administrative_level_3: '',
        routeLocation: '',
        political: '',
        zone: ''
      },
      
      showAlert: false,
      alertMessage: ''
    };
  },
  mounted() {
    //this.geolocate();
  },
  async created(){
    this.dataLoading = false
    let itemID = this.$route.params.slug
    if (typeof itemID !== 'undefined') {
      await this.getPropertyBlock({ id: itemID, block: this.block})
    }
    if(this.$store.state.propertiesModule.block[0] == undefined){
      this.geolocate()
    }else{
      this.center = {
        lat: Number(this.$store.state.propertiesModule.block[0].lat),
        lng: Number(this.$store.state.propertiesModule.block[0].len),
      };
    }
    this.dataLoading = true
  },
  computed: {
    getRouteOnly(){
      
      let add = this.$store.state.propertiesModule.block[0].route

      if(this.$store.state.propertiesModule.block[0].route != '')
        add = add + ', ' + this.$store.state.propertiesModule.block[0].administrative_area_level_3
      else
        add = add + this.$store.state.propertiesModule.block[0].administrative_area_level_3

      

      return add
    },
    getAddress(){
      let add = this.$store.state.propertiesModule.block[0].route

      if(this.$store.state.propertiesModule.block[0].route != '')
        add = add + ', ' + this.$store.state.propertiesModule.block[0].administrative_area_level_3
      else
        add = add + this.$store.state.propertiesModule.block[0].administrative_area_level_3

      if(this.$store.state.propertiesModule.block[0].administrative_level_3 != '')
        add = add + ', ' + this.$store.state.propertiesModule.block[0].administrative_area_level_2
      else
        add = add  + this.$store.state.propertiesModule.block[0].administrative_area_level_2


      add = add  + this.$store.state.propertiesModule.block[0].political

      add += "\n\n"

      
      add += this.$store.state.propertiesModule.block[0].gmap_county + ', ' + this.$store.state.propertiesModule.block[0].gmap_district

      return add
    },
    getCenterPosition(){
      return this.center
    },
    items() {
      let retrievedData = this.$store.state.propertiesModule.block
      let returningArray = []
      if(retrievedData == null)
        return retrievedArray;
        
      retrievedData.forEach(element => {
          let marker = {
            lat: parseFloat(element.lat),
            lng: parseFloat(element.len),
            radius: 55
          };
          returningArray.push({id: element.id, position: marker, radius: 55})
      });
      
      return returningArray
    },
    getOptions(){
      let options = {
            clickableIcons: false,
            mapTypeControl: false, 
            fullscreenControl: false,
            styles: [
              {
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#f5f5f5"
                  }
                ]
              },
              {
                "elementType": "labels.icon",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#616161"
                  }
                ]
              },
              {
                "elementType": "labels.text.stroke",
                "stylers": [
                  {
                    "color": "#f5f5f5"
                  }
                ]
              },
              {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#bdbdbd"
                  }
                ]
              },
              {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#eeeeee"
                  }
                ]
              },
              {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#757575"
                  }
                ]
              },
              {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#e5e5e5"
                  }
                ]
              },
              {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#9e9e9e"
                  }
                ]
              },
              {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#ffffff"
                  }
                ]
              },
              {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#757575"
                  }
                ]
              },
              {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#dadada"
                  }
                ]
              },
              {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#616161"
                  }
                ]
              },
              {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#9e9e9e"
                  }
                ]
              },
              {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#e5e5e5"
                  }
                ]
              },
              {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#eeeeee"
                  }
                ]
              },
              {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#c9c9c9"
                  }
                ]
              },
              {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#9e9e9e"
                  }
                ]
              }
            ]
          }


      return options
    }
  },
  methods: {    
    async editManualAddress(){
      let sMark = this.items[0]
      

      this.gmapRoutes.zone = this.$store.state.propertiesModule.block[0].political
      if(this.gmapRoutes.political == '')
        this.gmapRoutes.political = this.$store.state.propertiesModule.block[0].political

      this.gmapRoutes.distrito = this.$store.state.propertiesModule.block[0].gmap_district
      this.gmapRoutes.concelho = this.$store.state.propertiesModule.block[0].gmap_county
      this.gmapRoutes.administrative_level_2 = this.$store.state.propertiesModule.block[0].administrative_area_level_2
      this.gmapRoutes.administrative_level_3 = this.$store.state.propertiesModule.block[0].administrative_area_level_3
      
      if(this.gmapRoutes.routeLocation == '')
        this.gmapRoutes.routeLocation = this.$store.state.propertiesModule.block[0].route
      
      this.cleanMarkers()
      this.markerChild.lat = parseFloat(sMark.position.lat)
      this.markerChild.lng = parseFloat(sMark.position.lng)

      this.newMarkers.push({position: this.markerChild, radius: sMark.position.radius})
      
      await this.removeMapMarker({id: sMark.id})
      
      await this.saveMapMarkers(this.$route.params.slug, this.newMarkers, this.gmapRoutes)

      let itemID = this.$route.params.slug
      if (typeof itemID !== 'undefined') {
        this.getPropertyBlock({ id: itemID, block: this.block})
      }

    },
    cancelAddMarker(){
      this.showSaver = false
      this.cleanMarkers()
    },
    cancelRemoveMarkOnMap(){
      this.selectedMark =  -1
      this.showRemover = false
    },
    showRemoverBtn(ob){
      this.selectedMark = ob.id
      this.showRemover = true
    },
    cleanMarkers(){
      this.newMarkers = this.newMarkers.splice()
    },
    updateRadius(r){
      this.markerChild.radius = 55
    },
    drawCircle(e){
      let retrievedData = this.$store.state.propertiesModule.block
      this.showAlert = false
      this.alertMessage = this.$t('LOCATION.ERROR_LOCATION')
      if(retrievedData.length >= 1){
        this.showAlert = true
        return
      }
      this.showSaver = true


      this.center = {
        lat: this.$refs.gmapref.$mapObject.getCenter().lat(),
        lng: this.$refs.gmapref.$mapObject.getCenter().lng(),
      };
     
      this.drawable(this.center.lat, this.center.lng)
    },
    drawable(lat, lng){
      this.cleanMarkers()
      const marker = {
        lat: lat,
        lng: lng
      };
      this.newMarkers.push({ position: marker, radius: 55 });
    },
    updateCoordinates(location){
      this.markerChild.lat = location.lat()
      this.markerChild.lng = location.lng()
    },
    // GET Distrito and Concelho
    async codeLatLng(lat, lng) {
      var latlng = new google.maps.LatLng(lat, lng);
      let geocoder = new google.maps.Geocoder();
      let parent = this
      let zone_discovered = false
      await geocoder.geocode({'latLng': latlng}, function(results, status) {
        
        console.log(results)
        if(status == google.maps.GeocoderStatus.OK) {
            for(const component of results[0].address_components){
              const componentType = component.types[0];
                switch (componentType) {
                  case "locality":
                    parent.gmapRoutes.concelho = component.long_name;
                    break;
                  case "administrative_area_level_1":
                    parent.gmapRoutes.distrito = component.long_name
                    break;
                  case "route":
                    parent.gmapRoutes.routeLocation = component.long_name
                    break;
                    
                  default:
                }
                // Find zone
                for(const zone of results){
                  if(!zone_discovered)
                    if(zone.types.includes("neighborhood") || zone.types.includes("political")){
                      parent.gmapRoutes.political = zone.address_components[0].long_name
                      zone_discovered = true
                    }
                }
            }
                console.log(parent.gmapRoutes)
          // Get Administrative area level 2 and 3
          let retrievedData = results
          retrievedData.forEach(element => {            
            if(element.types[0] == "administrative_area_level_3")
              parent.gmapRoutes.administrative_level_3 = element.formatted_address.split(',')[0]
            if(element.types[0] == "administrative_area_level_2")
              parent.gmapRoutes.administrative_level_2 = element.formatted_address.split(',')[0]
            if(element.types[0] == "political")
              parent.gmapRoutes.political = element.formatted_address.split(',')[0]
          });
        }

      });
    },
    async confirmPositionModal(){
      this.cleanMarkers()
      this.newMarkers.push({position: this.markerChild, radius: 55})

      
      this.informationModal.show = false


      this.showAlert = false
      if(this.newMarkers[0].position.lat == 0 && this.newMarkers[0].position.lng == 0){
        this.alertMessage = this.$t('LOCATION.DRAG_BALL')
        this.informationModal.show = false
        this.showAlert = true
        this.showSaver = false
        return
      }

      // GET Distrito and Concelho
      await this.codeLatLng(this.markerChild.lat, this.markerChild.lng)
      console.log("ROTAS")
      console.log(this.markerChild)

      this.informationModal.message = this.$t('SEARCH.LOCATION_ADDRESS') + ': ' + this.gmapRoutes.routeLocation

      if(this.gmapRoutes.routeLocation != '')
        this.informationModal.message = this.informationModal.message + ', ' + this.gmapRoutes.administrative_level_3
      else
        this.informationModal.message = this.informationModal.message  + this.gmapRoutes.administrative_level_3

      if(this.gmapRoutes.administrative_level_3 != '')
        this.informationModal.message = this.informationModal.message + ', ' + this.gmapRoutes.administrative_level_2
      else
        this.informationModal.message = this.informationModal.message  + this.gmapRoutes.administrative_level_2


        this.informationModal.message = this.informationModal.message  + '\n\n' + this.$t('SEARCH.ZONE') + ': ' + this.gmapRoutes.political


      this.informationModal.message += "\n\n"

      
      this.informationModal.message += this.$t('SEARCH.CONCELHO') + ": " + this.gmapRoutes.concelho

      this.informationModal.message += "\n\n"

      this.informationModal.message += this.$t('SEARCH.DISTRICT') + ": " + this.gmapRoutes.distrito


      this.informationModal.show = true
      
    },
    saveMapChanges: async function(){
      //this.markerChild.lat = this.newMarkers[0].lat
      //this.markerChild.lng = this.newMarkers[0].lng
      //this.markerChild.radius = this.newMarkers[0].radius
      this.cleanMarkers()
      this.newMarkers.push({position: this.markerChild, radius: 55})

      
      


      this.showAlert = false
      if(this.newMarkers[0].position.lat == 0 && this.newMarkers[0].position.lng == 0){
        this.alertMessage = this.$t('LOCATION.DRAG_BALL')
        this.showAlert = true
        this.showSaver = false
        return
      }
      // GET Distrito and Concelho
      //await this.codeLatLng(this.center.lat, this.center.lng)
      await this.codeLatLng(this.markerChild.lat, this.markerChild.lng)

      

      await this.saveMapMarkers(this.$route.params.slug, this.newMarkers, this.gmapRoutes)
      this.showSaver = false
      this.cleanMarkers()

      let itemID = this.$route.params.slug
      if (typeof itemID !== 'undefined') {
        this.getPropertyBlock({ id: itemID, block: this.block})
      }

      this.informationModal.show = false
      this.reloadDiv +=1
    },
    setPlace(place) {
      this.currentPlace = place;      
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      }
    },
    removeMarkOnMap: async function(){
      await this.removeMapMarker({id: this.selectedMark})
      this.cleanMarkers()
      let itemID = this.$route.params.slug
      if (typeof itemID !== 'undefined') {
        this.getPropertyBlock({ id: itemID, block: this.block})
      }
      this.cancelRemoveMarkOnMap()
      this.markerChild.lat = 0
      this.markerChild.lng = 0
      this.reloadDiv +=1
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
  }
};
</script>

 
