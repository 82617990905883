<template>
    <v-container fluid class="pt-0 mr-10 ml-10">
        <v-layout row wrap class="v-manage-property">
            <v-col cols="9">
                <v-card flat :loading="loadingItem">
                    
                    <v-toolbar flat class="mode-header" style="background-color: #BE5E3B !important;">
                        <v-toolbar-title
                                v-for="(language, index) in $t('LANGUAGES')"
                                :key="index"
                                class="cursor-pointer mr-5"
                                :class="language.id === locale ? 'white--text font-weight-medium' : 'darkgray--text font-weight-light unselected-language-style'"
                                @click="locale = language.id"
                        >
                            {{ language.name }}
                        </v-toolbar-title>
                    </v-toolbar>

                    <div class="ma-8 mb-0 pb-0">
                        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
                            <form id="agent-form" @submit.prevent="handleSubmit(submit)" :key="reloadForm">
                                <v-row>
                                    <v-col cols="3" class="pt-0">
                                        <!-- Cover Photo -->
                                        <div class="v-files-list-info position-relative" v-if="form.cover && typeof form.cover !== 'object' && form.id">
                                            <v-img
                                                    height="250"
                                                    :src="$mediaFile('districts', form.id, form.cover)"
                                                    :lazy-src="$mediaFile('districts', form.id, form.cover)"
                                                    class="align-end text-left pl-3 pb-3 rounded"
                                            >
                                                <template v-slot:default class="h-full">
                                                    <div class="footer pa-5 d-flex justify-center">
                                                        <!-- Actions -->
                                                        <v-btn class="remove pa-0" @click="openModal('cover1')">
                                                            <i class="zwicon-trash white--text"></i>
                                                        </v-btn>
                                                        <!-- end -->
                                                    </div>
                                                </template>
                                                <template v-slot:placeholder>
                                                    <v-row
                                                            class="fill-height ma-0"
                                                            align="center"
                                                            justify="center"
                                                    >
                                                        <v-progress-circular
                                                                indeterminate
                                                                color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>
                                        </div>
                                        <cover-file @input="onBlurActionCover" :instantUploadOption="true" :fill="form.cover" v-model="form.cover" v-if="typeof form.cover === 'object' || !form.cover "/>
                                        
                                        <!-- end -->
                                    </v-col>
                                    <v-col cols="3" class="pt-0">
                                        <!-- Cover1 Photo -->
                                        <div class="v-files-list-info position-relative" v-if="form.cover2 && typeof form.cover2 !== 'object' && form.id">
                                            <v-img
                                                    height="250"
                                                    :src="$mediaFile('districts', form.id, form.cover2)"
                                                    :lazy-src="$mediaFile('districts', form.id, form.cover2)"
                                                    class="align-end text-left pl-3 pb-3 rounded"
                                            >
                                                <template v-slot:default class="h-full">
                                                    <div class="footer pa-5 d-flex justify-center">
                                                        <!-- Actions -->
                                                        <v-btn class="remove pa-0" @click="openModal('cover2')">
                                                            <i class="zwicon-trash white--text"></i>
                                                        </v-btn>
                                                        <!-- end -->
                                                    </div>
                                                </template>
                                                <template v-slot:placeholder>
                                                    <v-row
                                                            class="fill-height ma-0"
                                                            align="center"
                                                            justify="center"
                                                    >
                                                        <v-progress-circular
                                                                indeterminate
                                                                color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>
                                        </div>
                                        <v-card
                                          flat
                                          color="#f7f7f7"
                                          class="custom-color v-seo-preview text-left"
                                        >
                                          <cover-file @input="onBlurActionCover" :instantUploadOption="true" :fill="form.cover2" v-model="form.cover2" v-if="typeof form.cover2 === 'object' || !form.cover2 "/>
                                        </v-card>
                                        <!-- end -->
                                    </v-col>
                                     <v-col cols="3" class="pt-0">
                                        <!-- Cover2 Photo -->
                                        <div class="v-files-list-info position-relative" v-if="form.cover3 && typeof form.cover3 !== 'object' && form.id">
                                            <v-img
                                                    height="250"
                                                    :src="$mediaFile('districts', form.id, form.cover3)"
                                                    :lazy-src="$mediaFile('districts', form.id, form.cover3)"
                                                    class="align-end text-left pl-3 pb-3 rounded"
                                            >
                                                <template v-slot:default class="h-full">
                                                    <div class="footer pa-5 d-flex justify-center">
                                                        <!-- Actions -->
                                                        <v-btn class="remove pa-0" @click="openModal('cover3')">
                                                            <i class="zwicon-trash white--text"></i>
                                                        </v-btn>
                                                        <!-- end -->
                                                    </div>
                                                </template>
                                                <template v-slot:placeholder>
                                                    <v-row
                                                            class="fill-height ma-0"
                                                            align="center"
                                                            justify="center"
                                                    >
                                                        <v-progress-circular
                                                                indeterminate
                                                                color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>
                                        </div>
                                        <cover-file @input="onBlurActionCover" :instantUploadOption="true" :fill="form.cover3" v-model="form.cover3" v-if="typeof form.cover3 === 'object' || !form.cover3 "/>
                                        <!-- end -->
                                    </v-col>
                                    <v-col cols="3" class="pt-0">
                                        <!-- Cover2 Photo -->
                                        <div class="v-files-list-info position-relative" v-if="form.cover4 && typeof form.cover4 !== 'object' && form.id">
                                            <v-img
                                                    height="250"
                                                    :src="$mediaFile('districts', form.id, form.cover4)"
                                                    :lazy-src="$mediaFile('districts', form.id, form.cover4)"
                                                    class="align-end text-left pl-3 pb-3 rounded"
                                            >
                                                <template v-slot:default class="h-full">
                                                    <div class="footer pa-5 d-flex justify-center">
                                                        <!-- Actions -->
                                                        <v-btn class="remove pa-0" @click="openModal('cover4')">
                                                            <i class="zwicon-trash white--text"></i>
                                                        </v-btn>
                                                        <!-- end -->
                                                    </div>
                                                </template>
                                                <template v-slot:placeholder>
                                                    <v-row
                                                            class="fill-height ma-0"
                                                            align="center"
                                                            justify="center"
                                                    >
                                                        <v-progress-circular
                                                                indeterminate
                                                                color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                </template>
                                            </v-img>
                                        </div>
                                        <cover-file @input="onBlurActionCover" :instantUploadOption="true" :fill="form.cover4" v-model="form.cover4" v-if="typeof form.cover4 === 'object' || !form.cover4 "/>
                                        <!-- end -->
                                    </v-col>

                                    
                                  


                                    <!-- Translatable content -->
                                    <v-col cols="12" class="pt-4 pb-0">
                                        <v-window
                                                v-model="locale"
                                                vertical
                                        >
                                            <v-window-item
                                                    v-for="language in $t('LANGUAGES')"
                                                    :key="language.slug"
                                                    :ref="`window_${language.id}`"
                                                    :class="`window_${language.id}`"
                                            >

                                              <div  v-bind:style="language.slug=='pt' ? 'border-color:#4CAF50;border-top-style:solid;border-radius: 5px;' : ''">


                                                  <!-- Districts Title 1 -->
                                                  <input-text
                                                          :name="`name[${language.slug}]`"
                                                          :label="$t('SET_DISTRICT.DISTRICT_TITLE')"
                                                          id="title 3"
                                                          rules="required|min:5"
                                                          v-model="form.name[language.slug]"
                                                          :fill="form.name[language.slug]"
                                                          @blur="onBlurAction"
                                                  >
                                                  </input-text>
                                                  <!-- end -->
                                              </div>
                                              <div  v-bind:style="language.slug=='pt' ? 'border-color:#4CAF50;border-top-style:solid;border-radius: 5px;' : ''">
                                                  
                                                  
                                                  <!-- Districts 1 to 5-->
                                                  <div class="form-group row" >
                                                    <v-col cols="2">
                                                      <input-text
                                                              :name="`districts1[${language.slug}]`"
                                                              :label="$t('SET_DISTRICT.KEYWORD1')"
                                                              id="districts1"
                                                              v-model="form.districts1[language.slug]"
                                                              :fill="form.districts1[language.slug]"
                                                              @blur="onBlurAction"
                                                      >
                                                      </input-text>
                                                    </v-col>
                                                    <v-col cols="2">
                                                      <input-text
                                                              :name="`districts2[${language.slug}]`"
                                                              :label="$t('SET_DISTRICT.KEYWORD2')"
                                                              id="districts2"
                                                              v-model="form.districts2[language.slug]"
                                                              :fill="form.districts2[language.slug]"
                                                              @blur="onBlurAction"
                                                      >
                                                      </input-text>
                                                    </v-col>
                                                    <v-col cols="2">
                                                      <input-text
                                                              :name="`districts3[${language.slug}]`"
                                                              :label="$t('SET_DISTRICT.KEYWORD3')"
                                                              id="districts3"
                                                              v-model="form.districts3[language.slug]"
                                                              :fill="form.districts3[language.slug]"
                                                              @blur="onBlurAction"
                                                      >
                                                      </input-text>
                                                    </v-col>
                                                    <v-col cols="2">
                                                      <input-text
                                                              :name="`districts4[${language.slug}]`"
                                                              :label="$t('SET_DISTRICT.KEYWORD4')"
                                                              id="districts4"
                                                              v-model="form.districts4[language.slug]"
                                                              :fill="form.districts4[language.slug]"
                                                              @blur="onBlurAction"
                                                      >
                                                      </input-text>
                                                    </v-col>
                                                    <v-col cols="2">
                                                      <input-text
                                                              :name="`districts5[${language.slug}]`"
                                                              :label="$t('SET_DISTRICT.KEYWORD5')"
                                                              id="districts5"
                                                              v-model="form.districts5[language.slug]"
                                                              :fill="form.districts5[language.slug]"
                                                              @blur="onBlurAction"
                                                      >
                                                      </input-text>
                                                    </v-col> 
                                                  </div>
                                                  <!-- end -->
                                                  </div>
                                                <div  v-bind:style="language.slug=='pt' ? 'border-color:#4CAF50;border-top-style:solid;border-radius: 5px;' : ''">
                                                  <!-- Districts Title 2 -->
                                                    <input-text
                                                            :name="`districts_title2[${language.slug}]`"
                                                            :label="$t('SET_DISTRICT.TITLE2')"
                                                            id="districts_title2"
                                                            v-model="form.districts_title2[language.slug]"
                                                            :fill="form.districts_title2[language.slug]"
                                                            @blur="onBlurAction"
                                                    >
                                                    </input-text>
                                                  <!-- end -->
                                                  </div>
                                                <div  v-bind:style="language.slug=='pt' ? 'border-color:#4CAF50;border-top-style:solid;border-radius: 5px;' : ''">
                                                  <!-- Key Point 1 -->
                                                    <input-text
                                                            :name="`key_point1[${language.slug}]`"
                                                            :label="$t('SET_DISTRICT.PRESENTS1')"
                                                            id="key_point1"
                                                            v-model="form.key_point1[language.slug]"
                                                            :fill="form.key_point1[language.slug]"
                                                            @blur="onBlurAction"
                                                    >
                                                    </input-text>
                                                  <!-- end -->
                                                </div>
                                                <div  v-bind:style="language.slug=='pt' ? 'border-color:#4CAF50;border-top-style:solid;border-radius: 5px;' : ''">
                                                  <!-- Key Point 2 -->
                                                    <input-text
                                                            :name="`key_point2[${language.slug}]`"
                                                            :label="$t('SET_DISTRICT.PRESENTS2')"
                                                            id="key_point2"
                                                            v-model="form.key_point2[language.slug]"
                                                            :fill="form.key_point2[language.slug]"
                                                            @blur="onBlurAction"
                                                    >
                                                    </input-text>
                                                  <!-- end -->
                                                </div>
                                                <div  v-bind:style="language.slug=='pt' ? 'border-color:#4CAF50;border-top-style:solid;border-radius: 5px;' : ''">
                                                  <!-- Key Point 3 -->
                                                    <input-text
                                                            :name="`key_point3[${language.slug}]`"
                                                            :label="$t('SET_DISTRICT.PRESENTS3')"
                                                            id="key_point3"
                                                            v-model="form.key_point3[language.slug]"
                                                            :fill="form.key_point3[language.slug]"
                                                            @blur="onBlurAction"
                                                    >
                                                    </input-text>
                                                  <!-- end -->
                                                </div>
                                                <div  v-bind:style="language.slug=='pt' ? 'border-color:#4CAF50;border-top-style:solid;border-radius: 5px;' : ''">
                                                  <!-- Key Point 4 -->
                                                    <input-text
                                                            :name="`key_point4[${language.slug}]`"
                                                            :label="$t('SET_DISTRICT.PRESENTS4')"
                                                            id="key_point4"
                                                            v-model="form.key_point4[language.slug]"
                                                            :fill="form.key_point4[language.slug]"
                                                            @blur="onBlurAction"
                                                    >
                                                    </input-text>
                                                  <!-- end -->
                                                  </div>
                                                  <div  v-bind:style="language.slug=='pt' ? 'border-color:#4CAF50;border-top-style:solid;border-radius: 5px;' : ''">

                                                  <!-- Title -->                                                
                                                  <input-text
                                                          :name="`districts_title1[${language.slug}]`"
                                                          :label="$t('SET_DISTRICT.TITLE2')"
                                                          id="districts_title1"
                                                          v-model="form.districts_title1[language.slug]"
                                                          :fill="form.districts_title1[language.slug]"
                                                          @blur="onBlurAction"
                                                  >
                                                  </input-text>
                                                  <!-- end -->
                                                  </div>
                                                  <div  v-bind:style="language.slug=='pt' ? 'border-color:#4CAF50;border-top-style:solid;border-radius: 5px;' : ''">
                                                  <!-- Description -->
                                                  <input-textarea
                                                          :name="`description[${language.slug}]`"
                                                          :label="$t('SET_DISTRICT.DESCRIPTION')"
                                                          :rows="6"
                                                          class="pb-1"
                                                          v-model="form.description[language.slug]"
                                                          :fill="form.description[language.slug]"
                                                          @blur="onBlurAction"
                                                  >
                                                  </input-textarea>
                                                  <!-- end -->
                                                  </div>
                                                  <div  v-bind:style="language.slug=='pt' ? 'border-color:#4CAF50;border-top-style:solid;border-radius: 5px;' : ''">
                                                  <!-- Description -->
                                                  <input-textarea
                                                          :name="`presents[${language.slug}]`"
                                                          :label="$t('SET_DISTRICT.DEMO_PRESENTS')"
                                                          :rows="6"
                                                          class="pb-1"
                                                          v-model="form.presents[language.slug]"
                                                          :fill="form.presents[language.slug]"
                                                          @blur="onBlurAction"
                                                  >
                                                  </input-textarea>
                                                  <!-- end -->
                                                  </div>
                                                  <div  v-bind:style="language.slug=='pt' ? 'border-color:#4CAF50;border-top-style:solid;border-radius: 5px;' : ''">
                                                  <!-- Did you know -->
                                                  <input-textarea
                                                          :name="`did_you_know[${language.slug}]`"
                                                          :label="$t('SET_DISTRICT.DID_YOU_KNOW')"
                                                          :rows="6"
                                                          class="pb-1"
                                                          v-model="form.did_you_know[language.slug]"
                                                          :fill="form.did_you_know[language.slug]"
                                                          @blur="onBlurAction"
                                                  >
                                                  </input-textarea>
                                                  <!-- end -->
                                                  </div>
                                                  <v-row>
                                                    <v-col cols="8" class="pt-0">
                                                      
                                                  <div  v-bind:style="language.slug=='pt' ? 'border-color:#4CAF50;border-top-style:solid;border-radius: 5px;' : ''">
                                                      <!-- Visit -->
                                                      <input-textarea
                                                              :name="`to_visit[${language.slug}]`"
                                                          :label="$t('SET_DISTRICT.PLACES')"
                                                              :rows="6"
                                                              class="pb-1"
                                                              v-model="form.to_visit[language.slug]"
                                                              :fill="form.to_visit[language.slug]"
                                                              @blur="onBlurAction"
                                                      >
                                                      </input-textarea>
                                                      <!-- end -->
                                                      
                                              </div>
                                                    </v-col>
                                                    <v-col cols="4" class="pt-0">
                                                      <!-- Map Photo -->
                                                      <div class="v-files-list-info position-relative" v-if="form.map && typeof form.map !== 'object' && form.id">
                                                          <v-img
                                                                  height="250"
                                                                  :src="$mediaFile('districts', form.id, form.map)"
                                                                  :lazy-src="$mediaFile('districts', form.id, form.map)"
                                                                  class="align-end text-left pl-3 pb-3 rounded"
                                                          >
                                                              <template v-slot:default class="h-full">
                                                                  <div class="footer pa-5 d-flex justify-center">
                                                                      <!-- Actions -->
                                                                      <v-btn class="remove pa-0" @click="removeMedia('map')">
                                                                          <i class="zwicon-trash white--text"></i>
                                                                      </v-btn>
                                                                      <!-- end -->
                                                                  </div>
                                                              </template>
                                                              <template v-slot:placeholder>
                                                                  <v-row
                                                                          class="fill-height ma-0"
                                                                          align="center"
                                                                          justify="center"
                                                                  >
                                                                      <v-progress-circular
                                                                              indeterminate
                                                                              color="grey lighten-5"
                                                                      ></v-progress-circular>
                                                                  </v-row>
                                                              </template>
                                                          </v-img>
                                                      </div>
                                                      <cover-file :placesToVisit="true" @input="onBlurActionCover" :instantUploadOption="true" :fill="form.map" v-model="form.map" v-if="typeof form.map === 'object' || !form.map "/>
                                                      <!-- end -->
                                                    </v-col>  
                                                  </v-row>
                                            </v-window-item>
                                        </v-window>
                                    </v-col>
                                    <!-- end -->

                                </v-row>
                            </form>
                        </ValidationObserver>
                    </div>

                  <v-card flat>
                    <!-- Saving Action -->
                    <v-card-actions class="light-bg py-4 px-0">
                       <v-spacer></v-spacer>
                        <v-btn depressed color="primary" form="agent-form" type="submit" @click="supportValidation">
                            {{$t('SET_AGENT.SAVE')}}
                        </v-btn>
                    </v-card-actions>
                    <!-- end -->
                  
                  </v-card>
                </v-card>
            </v-col>
        </v-layout>
        <ConfirmationModal 
          v-show="showModal" 
          @yes="removeMedia"
          @no="closeModal"
          :title="confirmationModal.title"
          :message="confirmationModal.message"
        />
    </v-container>
</template>

<script>
  import mixin from '@/models/districts/DistrictsModel'
  import { DistrictForm } from '@/store/structs/district'
  import ConfirmationModal from '@/components/Modals/ConfirmationModal'

  export default {
    name: 'SetDistrict',

    mixins: [mixin],
    components:{ ConfirmationModal },

    data: () => ({
      files: [],
      showModal: false,
      confirmationModal:{
        title: '',
        message: '',
        objCover: null
      },
      form: DistrictForm,
      loadingItem: false,
      locale: 0,
      reloadForm: 0
    }),

    created() {
      let itemID = this.$route.params.id
      if (typeof itemID !== 'undefined') {
        this.loadingItem = true
        this.districtItem(itemID)
      }
      this.form = new DistrictForm({})
    },

    computed: {
      items() {
        return this.$store.state.districtsModule.district
      }
    },

    watch: {
      items(val) {
        this.form = new DistrictForm(val)
        console.log("O FORM")
        console.log(this.form)
      }
    },

    methods: {
      openModal(e){
        this.confirmationModal.objCover = e
        this.confirmationModal.title = "Removing image"
        this.confirmationModal.message = "You are about to remove an image.\n\n Are you sure you want to continue?"
        this.showModal = true
      },
      closeModal(){
        this.showModal = false
      },
      supportValidation() {
        let f = this.form
        let l = null
        this.$t('LANGUAGES').forEach(function(entry) {
          if (f.name[entry.slug].length < 5) {
            l = entry.id
          }
        })
        this.locale = l
      },

      removeMedia(){        
        let objCover = this.confirmationModal.objCover
        // Falta colocar aqui para tirar da BD
        if(objCover == 'cover1')
          this.form.cover = ''
        else if(objCover == 'cover2')
          this.form.cover2 = ''
        else if(objCover == 'cover3')
          this.form.cover3 = ''
        else if(objCover == 'cover4')
          this.form.cover4 = ''
        else 
          this.form.map = ''

          this.showModal = false
          this.resetConfirmationModal()
          this.onBlurActionCover()
      },
      resetConfirmationModal(){
        this.confirmationModal.objCover = null
        this.confirmationModal.title = ""
        this.confirmationModal.message = ""
      },

      processDelete() {
        this.deleteItem(this.form.id)
      },

      async districtItem(id) {
        try {
          this.loadingItem = true
          await this.getDistrict(id)
          this.loadingItem = false
        } catch (error) {
          this.loadingItem = false
        }
      },
      onBlurAction(){
        if (this.form.id != null) 
          this.submit()
      },
      async onBlurActionCover(){
        if (this.form.id != null){
          await this.submit()
          let itemID = this.$route.params.id
          if (typeof itemID !== 'undefined') {
            await this.districtItem(itemID)
            this.form = new DistrictForm(this.$store.state.districtsModule.district)
          }
        }

        this.reloadForm++
      },

      async submit() {
        const isValid = await this.$refs.observer.validate()
        
        if (isValid) {
          await this.save(this.form)
        }
      }
    }
  }
</script>