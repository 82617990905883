<template>
    <v-container>
        <v-row>
            <v-col xl="10" lg="8" cols="8" >
              <p class="page-title text-capitalize">{{ $t('AGENTS_LISTINGS.AGENTS_LISTINGS_TITLE') }}</p>
              <p class="page-subtitle"> {{ $t('AGENTS_LISTINGS.AGENTS_LISTINGS_DESCRIPTION') }}</p>
            </v-col>
        </v-row>
        <v-card flat class="mt-3 w-full">
        <div class="py-3 px-4 w-full">
        <v-toolbar flat class="mode-header">
            <v-toolbar-title>
                {{ total }} <span class="text-capitalize">{{ $t('AGENTS_LISTINGS.AGENTS_TITLE') }}</span>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn depressed color="secondary" class="square" to="/agents/add">
                <i class="zwicon-plus"></i>
            </v-btn>
        </v-toolbar>
        </div>
        </v-card>
        <v-row v-if="!dataLoading">
            <v-col cols="4" v-for="(agent, index) in items" :key="index">
                <CardAgent @delete-action="processDelete" :agent-data="agent"/>
            </v-col>
        </v-row>
        <v-row v-if="dataLoading">
            <v-col cols="4" v-for="index in 3" :key="index">
                <v-skeleton-loader
                        class="skeleton-agent"
                        type="table-heading, list-item-two-line, image, table-tfoot"
                ></v-skeleton-loader>
            </v-col>
        </v-row>

        <!-- Success -->
        <SuccessMessage />
        <!-- end -->
        <InformationModal v-show="showModal" 
          @ok="okModal"
          :message="$t('AGENT_DELETE.CANT')"/>

    </v-container>
</template>

<script>
  import InformationModal from '@/components/Modals/InformationModal'
  import { Agent } from '@/store/structs/agent'
  import mixin from '@/models/agents/AgentsModel'
  import propertiesMixin from '@/models/properties/PropertiesModel'
  import condominiumsMixin from '@/models/condominium/CondominiumsModel'

export default {
    name: 'Listings',
    mixins: [mixin, condominiumsMixin, propertiesMixin],
    components: { InformationModal },
    data() {
      return {
        dataLoading: true,
        target: 'agents',
        condominiumsList: null,
        propertiesList: null,
        showModal: false
      }
    },
    async created(){
      await this.getCondominiums()
      await this.getProperties()

      this.condominiumsList = this.$store.state.condominiumsModule.condominiums
      this.propertiesList = this.$store.state.propertiesModule.properties
    },

    methods: {
      okModal(){
        this.showModal = false
      },
      processDelete(e) {
        console.log(this.condominiumsList)
        console.log(this.propertiesList)

        let parent = this

        // Check if agent is in condo
        let condominiumAssociated = []
        this.condominiumsList.forEach(element => {
          if(element.agent_id == e)
            condominiumAssociated.push(parent.$processLanguage(element.title))
        });

        // Check if agent is in condo
        let propertiesAssociated = []
        this.propertiesList.forEach(element => {
          console.log(element.agent_id)
          if(element.agent_id == e)
            propertiesAssociated.push(element.reference)
        });

        if(propertiesAssociated.length > 0 || condominiumAssociated.length > 0)
          this.showModal = true
        else
          this.deleteItem(e)
      }
    },

    watch: {
      pagination: {
        async handler() {
          try {
            this.dataLoading = true
            await this.getAgents()
            this.dataLoading = false
          } catch (error) {
            this.dataLoading = false
          }
        },
        immediate: true,
        deep: true
      }
    },

    computed: {
      items() {
        let retrievedData = this.$store.state.agentsModule.list
        return Object.keys(retrievedData).map(k =>
          new Agent(retrievedData[k])
        )
      },
      getTitle(){
        return this.$route.meta.title.toLowerCase()
      },
      getSubtitle(){
        return this.$route.meta.subtitle
      },
      total(){
        return this.$store.state.agentsModule.list.length
      }
    }

}
</script>
