<template>
    <div class="ma-8 mb-4 pb-8" v-if="dataLoaded">
        <!-- Upload & Browse -->
        <v-card
                flat
                color="#f7f7f7"
                class="custom-color v-seo-preview text-left"
        >
            <browse-files-all @process-server="saveMediaFiles" :key="reloadBrowsing"></browse-files-all>
        </v-card>
        <!-- end -->

        <!-- List files -->
        <v-card
                flat
                class="mt-5"
        >
            <v-card-title class="pl-0 text-left subtitle-1 font-weight-bold">{{$t('DEVELOPMENT_ATTACHMENTS.ADDED')}}</v-card-title>
            <v-card-subtitle class="pl-0 text-left caption mb-2 black--text">{{$t('DEVELOPMENT_ATTACHMENTS.LIST')}}
            </v-card-subtitle>

            <attached-list-files :key="renderComponent"
              :files="items" 
              :ID="ID"
              :location="location"
              @remove-attachment="removeAttachment"></attached-list-files>
        </v-card>
        <!-- end -->
        <LoadingModal v-if="loadingModal" :message="processModalMessage"/>
    </div>
</template>

<script>
  import mixin from '@/models/condominium/CondominiumsModel'
  import LoadingModal from '@/components/Modals/LoadingModal'

  export default {
    name: 'Attachments',

    mixins: [mixin],
    components: {LoadingModal},

    data: () => ({
      block: 'attachments',
      files: [],
      ID: Number,
      loadingModal: false,
      location: String,
      renderComponent: 0,
      processMessage: '',
      reloadBrowsing: 0 + 'A',
      uploadQuantity: 0,
      dataLoaded: false,
      cleanQueue: 0
    }),

    async created() {
      if (typeof this.$route.params.slug !== 'undefined') {
        await this.getCondominiumBlock({ id: this.$route.params.slug, block: this.block })
      }
      this.processMessage = `${this.$t('loadingModal.WAIT_MESSAGE_ATTACHMENTS')}`
      this.dataLoaded = true
    },

    computed: {
      items() {
        return this.$store.state.condominiumsModule.block
      },
      processModalMessage(){
        return this.processMessage
      }
    },
    watch: {
      items(val) {        
        this.files = val
        this.ID = this.$route.params.slug
        this.location = "attachments"
      },
    },
    methods: {
      saveMediaFiles: async function (file, loadingBar){
        this.loadingModal = !loadingBar

        var id = this.$route.params.slug;
        const formData = new FormData()
        formData.append('attachment', file)         
        
        await this.saveAttachmentFromCondominium({ id: id, data: formData});
        
        await this.getCondominiumBlock({ id: this.$route.params.slug, block: this.block });
        
        this.renderComponent = this.renderComponent + 1;
        
        if(this.loadingModal == false){
          this.processMessage = `${this.$t('loadingModal.FINISHING_PROCESS')}`
          this.loadingModal = true
          
          await this.sleep(2500)
          
          this.loadingModal = false
          this.reloadBrowsing += 1
          this.uploadQuantity = 0
        }else
          this.uploadQuantity++

      },
      sleep: function(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
      removeAttachment: async function(location, fileName, media_id){
        var id = this.$route.params.slug
        const formData = new FormData()
        formData.append('mediaID', media_id)
        formData.append('fileName', fileName)

        // Save Attachment
        await this.removeAttachmentFromCondominium({ id: id, data: formData});
        
        // Update View
        await this.getCondominiumBlock({ id: this.$route.params.slug, block: this.block })
        this.renderComponent = this.renderComponent + 1;
      }
    }

  }
</script>