var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-3",attrs:{"flat":""}},[_c('div',{staticClass:"py-3 px-4"},[_c('v-card-title',{staticClass:"subtitle-2 font-weight-medium"},[_vm._v(_vm._s(_vm.$t('DASHBOARD.MOST_VIEWED_PROPERTIES')))]),_c('v-card-subtitle',{staticClass:"v-card__subtitle caption text-left font-weight-medium secondaryCard"}),_c('div',{staticClass:"px-5 pb-4"},[_c('v-data-table',{staticClass:"table__minimal recent-properties elevation-0",attrs:{"loading":_vm.dataTableLoading,"items":_vm.items,"options":_vm.pagination,"headers":_vm.headers,"loading-text":"Loading... Please wait","hide-default-footer":true,"calculate-widths":true,"items-per-page":5},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"grey lighten-2 d-block rounded",attrs:{"src":_vm.$mediaFile('properties', item.id, item.media.cover),"lazy-src":_vm.$mediaFile('properties', item.id, item.media.cover),"height":"125","width":"125"}})]}},{key:"item.information",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"main__info"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.reference))]),_c('p',{staticClass:"mb-0 font-weight-medium black--text listing-sub-sub-title"},[_vm._v(_vm._s(item.information.title))]),_c('p',{staticClass:"listing-kpis"}),_c('p',{staticClass:"listing-kpis"},[_vm._v(_vm._s(item.kpis.beds)+" "+_vm._s(_vm.$t('DASHBOARD.BEDS'))+" "),_c('span',{staticClass:"span-bullet"},[_vm._v("⬤")]),_vm._v(" "+_vm._s(item.kpis.baths)+" "+_vm._s(_vm.$t('DASHBOARD.BATHS'))+" "),_c('span',{staticClass:"span-bullet"},[_vm._v("⬤")]),_vm._v(" "+_vm._s(item.kpis.parking)+" "+_vm._s(_vm.$t('DASHBOARD.PARKING')))])])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"main__info",staticStyle:{"width":"auto"}},[_c('p',{staticClass:"mb-0 caption"},[_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.$t('DASHBOARD.PRICE')))])]),_c('p',{staticClass:"mb-0 text-limit-liner black--text"},[_vm._v(_vm._s(_vm.$currency(item.price)))])])]}},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"main__info"},[_c('p',{staticClass:"mb-0 caption"},[_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.$t('DASHBOARD.VIEWS')))])]),(item.total_views != null)?_c('p',{staticClass:"mb-0 font-weight-medium black--text"},[_vm._v(_vm._s(item.total_views)+" "),_c('i',{staticClass:"zwicon-eye"})]):_c('p',{staticClass:"mb-0 font-weight-medium black--text"},[_vm._v("0 "),_c('i',{staticClass:"zwicon-eye"})])])]}}],null,true)})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }