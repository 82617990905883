export class Service {
    constructor(object) {
        this.id = object.id
        this.title = object.title.en ?? object.title.pt
        this.description = object.description.en ?? object.description.pt
        this.service_id = object.service_id
  
    }
}

export class ServiceTranslated {
  constructor(object) {
      this.id = object.id
      this.title = {
        en: object.title.en,
        pt:object.title.pt
      }
      
      this.description = {
        en: object.description.en,
        pt:object.description.pt
      }
      
      this.service_id = object.service_id

  }
}

export class ServiceForm {
    constructor({
                  id = null,
                  title = '',
                  description = '',
                  service_id = '',
                }) {
      this.id = id || null
      this.title = {
        en: title.en || '',
        pt: title.pt || ''
      }
      this.description = {
        en: description.en || '',
        pt: description.pt || ''
      } 
      this.service_id = service_id || null
    }
}

export class ServiceDescriptionForm{
  constructor({
    id = null,
    description = '',
  }) {
  this.id = id || null
  this.description = {
    en: description.en || '',
    pt: description.pt || ''
  } 
  }
}

  export default { Service, ServiceForm, ServiceDescriptionForm}