<template>
    <div>
        <div class="ma-8 mb-4">
            <!-- Preview Google SEO -->
            <v-card
                    flat
                    color="rgb(247, 247, 247)"
                    class="pa-8 custom-color v-seo-preview text-left"
            >
                <div class="link caption secondary--text">
                    https://la.agency
                    <span class="d-inline-block slug special-blue"> ›
                    <template v-if="form.slug">
                        {{ form.slug }}
                    </template>
                    <template v-else>
                        {{$t('DEVELOPMENT_SEO.YOUR_PROPERTY_SLUG')}}
                    </template>
                </span>
                </div>
                <div class="title primary--text">
                    <template v-if="form.title">
                        {{ form.title }}
                    </template>
                    <template v-else>
                        {{$t('DEVELOPMENT_SEO.NAME')}}
                    </template>
                </div>
                <div class="description subtitle-2 font-weight-medium light-grey--text mt-2">
                    <template v-if="form.description">
                        {{ form.description }}
                    </template>
                    <template v-else>
                        {{$t('DEVELOPMENT_SEO.DESCRIPTION')}}
                    </template>
                </div>
            </v-card>
            <!-- end -->

            <!-- Form -->
            <v-card flat class="mt-5">

                <v-card-title class="pl-0 text-left subtitle-1 font-weight-bold">{{$t('DEVELOPMENT_SEO.SEO')}}</v-card-title>
                <v-card-subtitle class="pl-0 text-left caption mb-2 black--text">{{$t('DEVELOPMENT_SEO.IMPORTANCE')}}
                </v-card-subtitle>

                <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
                    <form id="type-form" @submit.prevent="handleSubmit(submit)">
                        <!-- Name -->
                        <input-text
                                name="name"
                                :label="$t('DEVELOPMENT_SEO.SEO_NAME')"
                                id="name"
                                rules="required|min:5"
                                v-model="form.title"
                                :fill="form.title"
                                :counter="50"
                                hint="Google recommend max length 50 characters"
                                @blur="onBlurAction"
                        >
                        </input-text>
                        <!-- end -->
                        <!-- Slug -->
                        <input-text
                                name="slug"
                                :label="$t('DEVELOPMENT_SEO.SLUG')"
                                id="slug"
                                rules="min:5"
                                v-model="form.slug"
                                :fill="form.slug"
                                @blur="onBlurAction"
                        >
                        </input-text>
                        <!-- end -->
                        <!-- Description -->
                        <input-textarea
                                name="seo-description"
                                :label="$t('DEVELOPMENT_SEO.SEO_DESCRIPTION')"
                                :rows="4"
                                :persistent-hint="false"
                                hint="Google recommend max length 100 characters"
                                :counter="240"
                                rules="required"
                                v-model="form.description"
                                :fill="form.description"
                                @blur="onBlurAction"
                        >
                        </input-textarea>
                        <!-- end -->
                    </form>
                    <!-- end -->
                </ValidationObserver>
            </v-card>
        </div>

        <!-- Saving Action -->
        <v-card-actions class="light-bg py-4 px-0">
            <v-spacer></v-spacer>
            <v-btn depressed color="primary" form="type-form" type="submit">{{$t('DEVELOPMENT_SEO.SAVE')}}</v-btn>
        </v-card-actions>
        <!-- end -->

        <SuccessMessage />
    </div>
</template>

<script>
  import mixin from '@/models/condominium/CondominiumsModel'
  import {  CondominiumSEOForm } from '@/store/structs/condominium'

  export default {
    name: 'SEO',

    mixins: [mixin],

    data: () => ({
      block: 'seo',
      form: CondominiumSEOForm
    }),

    created() {

      this.form = new CondominiumSEOForm({})
      this.form._id = this.$route.params.slug

      if (typeof this.form._id !== 'undefined') {
        this.getCondominiumBlock({ id: this.form._id, block: this.block })
      }
    },

    computed: {
      items() {
        return this.$store.state.condominiumsModule.block[0]
      }
    },

    watch: {
      items(val) {
        this.form = new CondominiumSEOForm(val)
        this.form._id = this.$route.params.slug 
      }
    },

    methods: {
      async onBlurAction(){
        const isValid = await this.$refs.observer.validate()
        if(isValid)
          this.submit()
      },
      async submit() {
        const isValid = await this.$refs.observer.validate()
        if(isValid)
          await this.saveBlock(this.form, this.block)
      }
    }

  }
</script>
