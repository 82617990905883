<template>
    <v-container fluid class="pt-0 mr-10 ml-10">
        <v-layout row wrap class="v-manage-property">
            <v-col cols="9">
                <v-card flat :loading="loadingItem">

                   <v-toolbar flat class="mode-header" style="background-color: #BE5E3B !important;">
                        <v-toolbar-title
                                v-for="(language, index) in $t('LANGUAGES')"
                                :key="index"
                                class="cursor-pointer mr-5"
                                :class="language.id === locale ? 'white--text font-weight-medium' : 'darkgray--text font-weight-light unselected-language-style'"
                                @click="locale = language.id"
                        >
                            {{ language.name }}
                        </v-toolbar-title>
                    </v-toolbar>
          
                      <div class="ma-8 mb-4 pb-8">
                          <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
                              <form id="agent-form" @submit.prevent="handleSubmit(submit)">
                                  <v-row>
                                      <v-col cols="3" class="pt-0 pb-0">
                                        
                                          <!-- Avatar File -->
                                          <div class="v-files-list-info position-relative" v-if="form.avatar && typeof form.avatar !== 'object' && form.id">
                                              <v-img
                                                      height="200"
                                                      max-height="200"
                                                      :src="$mediaFile('agents', form.id, form.avatar)"
                                                      :lazy-src="$mediaFile('agents', form.id, form.avatar)"
                                                      class="align-end text-left pl-3 pb-3 rounded"
                                              >
                                                  <template v-slot:default class="h-full">
                                                      <div class="footer pa-5 d-flex justify-center">
                                                          <!-- Actions -->
                                                          <v-btn class="remove pa-0" @click="removeMedia">
                                                              <i class="zwicon-trash white--text"></i>
                                                          </v-btn>
                                                          <!-- end -->
                                                      </div>
                                                  </template>
                                                  <template v-slot:placeholder>
                                                      <v-row
                                                              class="fill-height ma-0"
                                                              align="center"
                                                              justify="center"
                                                      >
                                                          <v-progress-circular
                                                                  indeterminate
                                                                  color="grey lighten-5"
                                                          ></v-progress-circular>
                                                      </v-row>
                                                  </template>
                                              </v-img>
                                          </div>
                                          <avatar-file :fill="form.avatar" v-model="form.avatar" v-if="typeof form.avatar === 'object' || !form.avatar "/>
                                          <!-- end -->
                                      </v-col>
                                      <v-col cols="9" class="pl-10 pt-0">
                                        <v-window
                                                v-model="locale"
                                                vertical
                                        >
                                            <v-window-item
                                                    v-for="language in $t('LANGUAGES')"
                                                    :key="language.slug"
                                                    :ref="`window_${language.id}`"
                                                    :class="`window_${language.id}`"
                                            >
                                          <div class="d-flex align-center">
                                              <!-- First Name -->
                                              <i18n-input-text class="pr-3 w-full" :label="$t('SET_AGENT.FIRST_NAME')" type="text"
                                                              id="first_name"
                                                              name="first_name" rules="required|min:2"
                                                              v-model="form.first_name"
                                                              :fill="form.first_name"
                                                              @blur="onBlurAction"></i18n-input-text>
                                              <!-- end -->
                                              <!-- Last Name -->
                                              <i18n-input-text class="pl-3 w-full" :label="$t('SET_AGENT.LAST_NAME')" type="text"
                                                              id="last_name"
                                                              name="last_name" rules="required|min:3"
                                                              v-model="form.last_name"
                                                              :fill="form.last_name"
                                                              @blur="onBlurAction"></i18n-input-text>
                                              <!-- end -->
                                          </div>

                                          <div class="d-flex align-center">
                                              <!-- Phone -->
                                              <input-text class="pr-3 w-full" :label="$t('SET_AGENT.PHONE')" type="text" id="phone"
                                                          name="phone"
                                                          v-model="form.phone" :fill="parseInt(form.phone)"
                                                          @blur="onBlurAction"
                                              ></input-text>
                                              <!-- end -->
                                              <!-- Role -->
                                              <input-text class="pl-3 w-full" :label="$t('SET_AGENT.ROLE')" type="text" id="role"
                                                          :name="`role[${language.slug}]`"
                                                          v-model="form.role[language.slug]" :fill="form.role[language.slug]"
                                                          @blur="onBlurAction"
                                              ></input-text>
                                              <!-- end -->
                                          </div>

                                          <!-- Email -->
                                          <i18n-input-text class="pl-0" :label="$t('SET_AGENT.EMAIL')" type="text" id="email"
                                                          name="email" rules="required|email|min:10" v-model="form.email"
                                                          :fill="form.email"
                                                          @blur="onBlurAction"></i18n-input-text>
                                          <!-- end -->
                                            </v-window-item>
                                        </v-window>
                                      </v-col>

                                      <v-col cols="12" class="pt-0 pb-0">
                                        <v-window
                                                v-model="locale"
                                                vertical
                                        >
                                            <v-window-item
                                                    v-for="language in $t('LANGUAGES')"
                                                    :key="language.slug"
                                                    :ref="`window_${language.id}`"
                                                    :class="`window_${language.id}`"
                                            >
                                          <!-- Notes / Description -->
                                          <v-card
                                                  flat
                                          >
                                              <v-card-title class="pl-0 pt-0 text-left subtitle-1 font-weight-light">
                                                  {{ $t('SET_AGENT.DESCRIPTION_NOTES') }}
                                              </v-card-title>
                                              <v-card-subtitle class="pl-0 text-left caption mb-2 black--text">{{ $t('SET_AGENT.SHORT_TITLE') }}
                                              </v-card-subtitle>

                                              <input-textarea
                                                      :name="`description[${language.slug}]`"
                                                      :label="$t('SET_AGENT.AGENT_DESCRIPTION')"
                                                      :rows="6"
                                                      :hint="$t('SET_AGENT.SHORT')"
                                                      :persistent-hint="true"
                                                      v-model="form.description[language.slug]" :fill="form.description[language.slug]"
                                                      @blur="onBlurAction"
                                              >
                                              </input-textarea>
                                          </v-card>
                                          <!-- end -->
                                            </v-window-item>
                                        </v-window>
                                      </v-col>
                                  </v-row>
                              </form>
                          </ValidationObserver>
                      </div>

                      <!-- Saving Action -->
                      <v-card-actions class="light-bg">
                          <v-spacer></v-spacer>
                          <v-btn depressed color="primary" form="agent-form" type="submit">{{$t('SET_AGENT.SAVE')}}</v-btn>
                      </v-card-actions>
                      <!-- end -->

                  </v-card>
              </v-col>
          </v-layout>
      </v-container>
</template>

<script>
  import { Agent, AgentForm } from '@/store/structs/agent'
  import mixin from '@/models/agents/AgentsModel'

  export default {
    name: 'SetAgent',

    mixins: [mixin],

    data: () => ({
      files: [],
      form: AgentForm,
      loadingItem: false,
      locale: 0
    }),

    created() {
      let itemID = this.$route.params.id;
      if (typeof itemID !== 'undefined'){
        this.loadingItem = true;
        this.agentItem(itemID)
      }
      this.form = new AgentForm({})
    },

    computed: {
      items() {
        return this.$store.state.agentsModule.agent;
      },
      getTitle(){
        return this.$route.meta.title.toLowerCase()
      },
      getSubtitle(){
        return this.$route.meta.subtitle
      }
    },

    watch: {
      items(val) {
        this.form = new AgentForm(val)
      }
    },

    methods: {
      supportValidation() {
        let f = this.form
        let l = null
        this.$t('LANGUAGES').forEach(function(entry) {
          if (f.name[entry.slug].length < 5) {
            l = entry.id
          }
        })
        this.locale = l
      },
      onBlurAction(){
        if(this.form.id !== null)
          this.submit()
      },
      async agentItem(id) {
        try {
          this.loadingItem = true
          await this.getAgent(id)
          this.loadingItem = false
        } catch (error) {
          this.loadingItem = false
        }
      },

      removeMedia(){
        this.form.avatar = ''
      },

      async submit() {
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          await this.save(this.form)
        }
      }
    }
  }
</script>