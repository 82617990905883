<template>
    <div id="myModal" class="modal">

    <!-- Modal content -->
    <div class="modal-content">
        <div>
            <p class="myPrincipalCaption font-weight-bold">{{ message }}</p>
        </div>
        <v-progress-circular
            indeterminate
            color="black lighten-5"
        ></v-progress-circular>
    </div>

    </div>
</template>
<style>
/* The Modal (background) */
.modal {
 /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(255, 255, 255, 0.8); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: inherit;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 20%; /* Could be more or less, depending on screen size */
  height: 20%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
<script>
export default {
    name: 'LoadingModal',
    props: {
        message: String,
    },
    methods:{
    }
}
</script>
