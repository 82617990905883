<template>
    <div id="myModal" class="modal allow-string-break" v-if="!loadingData">
        
    <!-- Modal content -->
    <div class="modal-content">
        <div>
            <v-toolbar flat class="mode-header" style="background-color: #BE5E3B !important;">
                <v-toolbar-title
                        @click="changeTab($t('Export.Imovirtual'))"
                        class="cursor-pointer mr-5"
                        :class="$t('Export.Imovirtual') === getPlaceSelected ? 'white--text font-weight-medium' : 'darkgray--text font-weight-light unselected-language-style'"
                >
                    {{ $t('Export.Imovirtual') }}
                </v-toolbar-title>
                <v-toolbar-title
                        @click="changeTab($t('Export.Idelista'))"
                        class="cursor-pointer mr-5"
                        :class="$t('Export.Idelista') === getPlaceSelected ? 'white--text font-weight-medium' : 'darkgray--text font-weight-light unselected-language-style'"
                >
                    {{ $t('Export.Idelista') }}
                </v-toolbar-title>
                <v-toolbar-title
                        @click="changeTab($t('Export.James'))"
                        class="cursor-pointer mr-5"
                        :class="$t('Export.James') === getPlaceSelected ? 'white--text font-weight-medium' : 'darkgray--text font-weight-light unselected-language-style'"
                >
                    {{ $t('Export.James') }}
                </v-toolbar-title>
            </v-toolbar>
            <div>
                <!-- IMOVIRTUAL TAB -->
                <div class="ma-8 mb-4 pb-5" v-if="getPlaceSelected == $t('Export.Imovirtual')">
                    <v-alert
                        v-if="imovirtual_uuid.length > 0 && getPlaceSelected == $t('Export.Imovirtual')"
                        text
                        outlined
                        color="deep-orange"
                        icon="mdi-fire"
                        >
                        {{ $t('Export.Imovirtual_INSERTED')}}
                    </v-alert>
                    

                    <v-alert
                        v-if="hasLocation"
                        dense
                        text
                        type="success"
                    >
                        {{$t('Export.successLocation')}}
                    </v-alert>
                    <v-alert
                        v-else
                        dense
                        outlined
                        type="error"
                    >
                        {{$t('Export.errorNoLocation')}}
                    </v-alert>


                    <v-alert
                        v-if="hasMedia"
                        dense
                        text
                        type="success"
                    >
                        {{$t('Export.successImage')}}
                    </v-alert>
                    <v-alert
                        v-else
                        dense
                        outlined
                        type="error"
                    >
                        {{$t('Export.errorNoImage')}}
                    </v-alert>
                    <v-row style="width:100%;">
                        <v-col cols="12" class="text-left py-0">
                            <v-card-title class="subtitle-2 font-weight-bold pt-2 px-0">{{$t('Export.ImovirtualInfo')}}
                            </v-card-title> 
                            <v-row>
                                <v-col>
                                    <strong>ID:</strong> 
                                </v-col>
                                <v-col align="start">{{$route.params.slug}}</v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <strong>Uuid:</strong> 
                                </v-col>
                                <v-col align="start" v-if="imovirtual_uuid.length > 0">{{imovirtual_uuid}}</v-col>
                                <v-col align="start" v-else>{{$t('Export.Non')}}</v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    
                    <v-btn
                        class="ma-2"
                        :disabled="imovirtual_uuid.length > 0 ? true : false"
                        color="secondary"
                        @click="exportProperty"
                        >
                        {{ $t('Export.ExportLabel')}}
                    </v-btn>

                    <v-btn
                        class="ma-2 white--text"
                        color="#7289da"
                        :disabled="imovirtual_uuid.length == 0 ? true : false"
                        @click="updateProperty"
                        >
                        {{ $t('Export.UpdateLabel')}}
                    </v-btn>

                    <v-btn
                        class="ma-2"
                        color="error"
                        :disabled="imovirtual_uuid.length == 0 ? true : false"
                        @click="deleteProperty"
                        >
                        {{ $t('Export.DeleteLabel')}}
                        <v-icon
                            right
                            light
                        >
                            mdi-delete
                        </v-icon>
                    </v-btn>

                    <v-btn
                        class="ma-2"
                        color="warning"
                        @click="$emit('close-modal')"
                        >
                        {{ $t('common.CLOSE')}}
                        <v-icon
                            right
                            light
                        >
                            mdi-close
                        </v-icon>
                    </v-btn>
                </div>
                <!-- END IMOVIRTUAL TAB -->

                <!-- IDEALISTA TAB -->
                <div class="ma-8 mb-4 pb-5" v-if="getPlaceSelected == $t('Export.Idelista')">
                    

                    <v-alert
                        v-if="hasLocation"
                        dense
                        text
                        type="success"
                    >
                        {{$t('Export.successLocation')}}
                    </v-alert>
                    <v-alert
                        v-else
                        dense
                        outlined
                        type="error"
                    >
                        {{$t('Export.errorNoLocation')}}
                    </v-alert>


                    <v-alert
                        v-if="hasMedia"
                        dense
                        text
                        type="success"
                    >
                        {{$t('Export.successImage')}}
                    </v-alert>
                    <v-alert
                        v-else
                        dense
                        outlined
                        type="error"
                    >
                        {{$t('Export.errorNoImage')}}
                    </v-alert>
                    
                    <v-btn
                        class="ma-2"
                        :disabled="idealista_uuid.length > 0 ? true : false"
                        color="secondary"
                        @click="exportProperty"
                        >
                        {{ $t('Export.ExportLabel')}}
                    </v-btn>

                    <v-btn
                        class="ma-2 white--text"
                        color="#7289da"
                        :disabled="idealista_uuid.length == 0 ? true : false"
                        
                        >
                        {{ $t('Export.UpdateLabel')}}
                    </v-btn>

                    <v-btn
                        class="ma-2"
                        color="error"
                        :disabled="idealista_uuid.length == 0 ? true : false"
                        @click="deleteProperty"
                        >
                        {{ $t('Export.DeleteLabel')}}
                        <v-icon
                            right
                            light
                        >
                            mdi-delete
                        </v-icon>
                    </v-btn>

                   
                    <v-btn
                        class="ma-2"
                        color="warning"
                        @click="$emit('close-modal')"
                        >
                        {{ $t('common.CLOSE')}}
                        <v-icon
                            right
                            light
                        >
                            mdi-close
                        </v-icon>
                    </v-btn>
                </div>
                <!-- END IDEALISTA TAB -->
                
                <!-- JAMES TAB -->
                <div class="ma-8 mb-4 pb-5" v-if="getPlaceSelected == $t('Export.James')">
                    
                    
                    <v-btn
                        class="ma-2"
                        color="secondary"
                        @click="exportProperty"
                        >
                        {{ $t('Export.ExportLabel')}}
                    </v-btn>
                </div>
                 <!-- END JAMES TAB -->
            </div>
        </div>
    </div>

    </div>
</template>
<style>
/* The Modal (background) */
.modal {
 /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(255, 255, 255, 0.8); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: inherit;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 40%; /* Could be more or less, depending on screen size */
  height: auto;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
<script>
import mixin from '@/models/properties/PropertiesModel'
export default {
    name: 'APIsExportModal',
    mixins: [mixin],
    props:{
        imovirtual_uuid: String,
        media: Array,
        idealista_uuid: String
    },
    data(){
        return{
            placeSelected: '',
            loadingData: true,
            hasLocation: false,
            hasMedia: false
        }
    },
    async created(){
        this.placeSelected = this.$t('Export.Imovirtual')
        if(!this.$route.fullPath.includes('new')){
            await this.getPropertyBlock({ id: this.$route.params.slug, block: 'location'})
            let locale = this.$store.state.propertiesModule.block
            
            
            if(locale != null){
                if(locale.length > 0)
                    this.hasLocation = true
            }

            if(this.media != null){
                if(this.media.length > 0)
                    this.hasMedia = true
            }
        }
        this.loadingData = false
    },
    methods:{
        changeTab(t){
            this.placeSelected = t
        },
        exportProperty(){
            this.$emit('export', this.placeSelected)
        },
        deleteProperty(){
            this.$emit('delete', this.placeSelected)
        },
        updateProperty(){
            this.$emit('update', this.placeSelected)
        }
    },
    computed:{
        getPlaceSelected(){
            return this.placeSelected
        }
    }
}
</script>
