<template>


     <v-card style="white-space: pre-line;">
        <v-card-title class="text-h5 grey lighten-2">
          {{title}}
        </v-card-title>

        <v-card-text>
            {{message}}
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
            <v-btn @click="yesResponse" small color="success" depressed form="type-form" type="submit" class="px-6 py-6 successBTN">
                <i class="zwicon-task"></i>{{$t('REMOVING_MODAL.CONFIRM')}}
            </v-btn>
            <v-btn @click="noResponse" small depressed form="type-form" type="submit" class="px-6 py-6 backBTN">
                <i class="zwicon-sign-out"></i>{{$t('REMOVING_MODAL.BACK')}}
            </v-btn>
        </v-card-actions>

     </v-card>

</template>

<script>
export default {
    name: 'DialogModalConfirmation',
    props: {
        title: String,
        message: String,
    },
    methods:{
        yesResponse(){
            this.$emit('yes')
        },
        noResponse(){
            this.$emit('no')
        }
    }
}
</script>
