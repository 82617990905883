<template>
    <v-container fluid class="pt-0 mr-10 ml-10">
        <v-layout row wrap class="v-manage-property">
            <v-col cols="9">
                <v-card flat>
                    <v-toolbar flat class="mode-header" style="background-color: #BE5E3B !important;">
                        <v-toolbar-title
                                v-for="(language, index) in $t('LANGUAGES')"
                                :key="index"
                                class="cursor-pointer mr-5"
                                :class="language.id === locale ? 'white--text font-weight-medium' : 'darkgray--text font-weight-light unselected-language-style'"
                                @click="locale = language.id"
                        >
                            {{ language.name }}
                        </v-toolbar-title>
                    </v-toolbar>

                    <router-view :key="viewKey" :lang="locale" @update-view="refreshView"></router-view>
                </v-card>
            </v-col>
            <v-col cols="3">
                <!-- COMPOR ESTE MENU -->
                <!-- Navigation -->
                <RightMenu :menus="this.menus"/>
                <!-- end -->
            </v-col>
        </v-layout>

        <SuccessMessage />

    </v-container>
</template>

<script>
  import Menu from '@/data/properties-menu'

  export default {
    name: 'AddCondominium',
    data(){
      return{
        locale: 0,
        menus: Menu.getRightMenu(this),
        viewKey: 0
      }
    },
    created(){
      // Watch if variable changes
      this.$store.watch(
        (state)=>{
          return this.$store.state.locale.locale
        },
        (newValue, oldValue)=>{
          this.menus = Menu.getRightMenu(this)
        }
      )
    },

    methods: {
      onClickChild(value) {
        this.$store.commit('setIsCheck', value.replace('#', ''))
      },
      refreshView(){
        this.viewKey+=1
      }
    },

    computed: {
      tabActive: {
        // getter
        get: function() {
          return this.$store.state.condominiums.options.tab
        },
        // setter
        set: function(newValue) {
          this.$store.commit('setIsCheck', window.location.hash.replace('#', ''))
        }

      }

    }
  }
</script>