<template>
    <v-container>
        <v-row>
          <v-card flat class="mt-3 w-full">
            <div class="py-3 px-4 w-full">
              <v-toolbar flat class="mode-header">
                <v-toolbar-title>
                    {{guidance}} - {{$t('errors.UNDER_CONSTRUCTION')}}
                </v-toolbar-title>
              </v-toolbar>
              <div class="px-5 pb-4 listing-div w-full" v-if="guidance == ''">
                {{$t('INSTRUCTIONS.WELCOME')}}
                <br/>
                <br/>
                {{$t('INSTRUCTIONS.SIZE_IMAGE')}}
                
                <div class="d-flex flex-column justify-space-between align-center">
                  <v-slider
                    v-model="widths.imageSize"
                    class="align-self-stretch"
                    :min="min"
                      :max="max"
                    step="1"
                  ></v-slider>
                    <v-img
                        :src="this.$mediaFile('guide', 'ice', 'ice')"
                        :lazy-src="this.$mediaFile('guide', 'ice', 'ice')"
                        :aspect-ratio="16/9"
                        :width="widths.imageSize"
                    />
                </div>
              </div>
              <div class="px-5 pb-4 listing-div w-full" v-if="guidance == $t('INSTRUCTIONS.Introduction')">
                {{$t('INSTRUCTIONS.INTRO_MESSAGE')}}
                <div class="d-flex flex-column justify-space-between align-center">
                  <v-slider
                      v-model="widths.imageSize"
                      class="align-self-stretch"
                     :min="min"
                      :max="max"
                      step="1"
                    ></v-slider>
                      <v-img
                          :src="this.$mediaFile('guide', 'ice', 'ice')"
                          :lazy-src="this.$mediaFile('guide', 'ice', 'ice')"
                          :aspect-ratio="16/9"
                          :width="widths.imageSize"
                          
                      />
                  </div>
              </div>
              <div class="px-5 pb-4 listing-div w-full" v-if="guidance == 'Windows'">
                {{$t('INSTRUCTIONS.WINDOWS')}}
                <div class="d-flex flex-column justify-space-between align-center">
                  <v-slider
                      v-model="widths.imageSize"
                      class="align-self-stretch"
                      :min="min"
                      :max="max"
                      step="1"
                    ></v-slider>
                      <v-img
                          contain
                          :src="this.$mediaFile('guide', 'windows', 'ctrlf5')"
                          :lazy-src="this.$mediaFile('guide', 'windows', 'ctrlf5')"
                          :aspect-ratio="16/9"
                          :width="widths.imageSize"
                      />
                </div>
              </div>
              <div class="px-5 pb-4 listing-div w-full" v-if="guidance == 'Mac'">
                {{$t('INSTRUCTIONS.MAC')}}
                <div class="d-flex flex-column justify-space-between align-center">
                  <v-slider
                      v-model="widths.imageSize"
                      class="align-self-stretch"
                      :min="min"
                      :max="max"
                      step="1"
                    ></v-slider>
                <v-img
                    contain
                    :src="this.$mediaFile('guide', 'mac', 'maccmdf5')"
                    :lazy-src="this.$mediaFile('guide', 'mac', 'maccmdf5')"
                    :aspect-ratio="16/9"
                    :width="widths.imageSize"
                />
                </div>
              </div>

              <!-- NEW PROPERTY -->
              <div class="px-5 pb-4 listing-div w-full" v-if="guidance == 'New Property'">
                {{$t('INSTRUCTIONS.INSERT_PROPERTIES_INSTRUCTIONS_I')}}
                <div class="d-flex flex-column justify-space-between align-center">
                  <v-slider
                      v-model="widths.insertPropWay1"
                      class="align-self-stretch"
                      :min="min"
                      :max="max"
                      step="1"
                    ></v-slider>
                <v-img
                    contain
                    :src="this.$mediaFile('guide', 'insert_property', 'insert_prop_way_1')"
                    :lazy-src="this.$mediaFile('guide', 'insert_property', 'insert_prop_way_1')"
                    :aspect-ratio="16/9"
                    :width="widths.insertPropWay1"
                />
                </div>
                {{$t('INSTRUCTIONS.INSERT_PROPERTIES_INSTRUCTIONS_II')}}
              <div class="d-flex flex-column justify-space-between align-center">
                  <v-slider
                      v-model="widths.insertPropWay2"
                      class="align-self-stretch"
                      :min="min"
                      :max="max"
                      step="1"
                    ></v-slider>
                <v-img
                    contain
                    :src="this.$mediaFile('guide', 'insert_property', 'insert_prop_way_2')"
                    :lazy-src="this.$mediaFile('guide', 'insert_property', 'insert_prop_way_2')"
                    :aspect-ratio="16/9"
                    :width="widths.insertPropWay2"
                />
                </div>
              </div>
              
              <!-- END NEW PROPERTY -->

            </div>
          </v-card>
        </v-row>
        
        
    </v-container>
</template>

<script>
  export default {
    name: 'Instructions',
    props:['guidePage'],
    data(){
        return{
            guidance: '',
            max: 1000,
            min: 400,
            widths:{
              imageSize: 0,
              insertPropWay1: 0,
              insertPropWay2: 0,
            }
        }
    },
    watch:{
        guidePage(g){
            console.log(g)
            this.guidance = g
        }
    }
  }
</script>
