export class WhoWeAreForm {
    constructor({
        description = '',
      }) { {
        this.description = {
            en: description.en || '',
            pt: description.pt || ''
          } 
  
    }
}
}

export default { WhoWeAreForm }