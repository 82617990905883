import { mapActions } from 'vuex'

const mixin = {

    created: function() {
      console.debug(`Services action called.`)
    },

    methods: {
        ...mapActions(['getServices', 'editService', 'saveService', 'deleteService', 'getServiceDescription', 'saveServiceDescriptionForm']),
        async deleteItem(item) {
          try {
              this.dataLoading = true
    
              await this.deleteService(item)
              await this.getServices()
    
              this.dataLoading = false
        
    
          } catch (error) {
            this.dataLoading = false
          }
    
          return true
        },
        async save(form) {
          try {
            // Updating item
            if (form.id !== null) {
              await this.editService({ 'data': form })
              await this.getServices()
            } else {
              // Creating new item
              await this.saveService({ 'data': form })
              await this.getServices()
            }
          } catch (error) {
            this.dataTableLoading = false
          }
        },
        async saveServiceDescription(form){
          try{
            await this.saveServiceDescriptionForm({'data':form})
          } catch (error) {
            this.dataTableLoading = false
          }
        }
    }

}

export default mixin