<template>
    <div>
        <div class="ma-8 mb-4 pb-5">
          <v-main>
          <v-data-table
              class="table__minimal inbox-email elevation-0"
              :loading="dataTableLoading"
              :items="logFiles"
              :headers="headers"
              loading-text="Loading... Please wait"
              :hide-default-footer="false"
              :calculate-widths="true"
              :items-per-page="10"
              :item-class="itemRowBackground"
                            >
          </v-data-table>
          <template v-slot:[`item.transaction_id`]="{ item }">
                <i aria-hidden="true"
                    class="v-icon notranslate title v-icon--left mdi mdi-label theme--light ma-0"
                    :class="item.transaction_id"></i>
            </template>
            
            <template v-slot:[`item.message`]="{ item }">
                <div class="main__info">
                    <p class="mb-0 black--text font-weight-bold">{{ item.message }}</p>
                </div>
            </template>
          </v-main>
        </div>
    
        <ExportersModal
          :message="exportersMessage"
          v-show="exportersModal"
          :progressLoader="exporterProgressBar"
        />
    </div>
</template>

<script>
  //import tags from '@/data/tags'
  import { PropertyForm } from '@/store/structs/properties'
  import mixin from '@/models/properties/PropertiesModel'
  import exporterMixin from '@/models/exporter/ExporterModel'
  import ExportersModal from '@/components/Modals/ExportersModal'
  
  export default {
    name: 'General',

    mixins: [mixin, exporterMixin],
    components:{ ExportersModal },

    props: {
      lang: {
        type: Number,
        default: 0
      }
    },

    data: () => ({
      dataTableLoading: false,
      logFiles: [],
      block: 'general',
      headers: [
        { text: 'Icon', value: 'stared', width: '5%', align: 'left' },
        { value: 'label', width: '100%', align: 'left' },
      ],
    }),
    

    async created() {
        
        let itemID = this.$route.params.slug

        this.exporterProgressBar = 1
        this.exportersModal = true
        this.exportersMessage = this.$t('Export.validateExport');

        if (typeof itemID !== 'undefined') {
        
          await this.getPropertyBlock({ id: itemID, block: this.block})
          this.form = new PropertyForm(this.$store.state.propertiesModule.block)

      
          
          // if form or uuid empty, get back to general
          if(this.form.imovirtual_uuid == '' || this.form == null || this.form == undefined){
            this.$router.push({ name: 'property-general-edit' })
          }


          this.exportersModal = false

          this.dataTableLoading = true


      }
      
    },
    methods:{
      itemRowBackground: function(item) {
        return item.read ? 'light-bg-light' : ''
      },
    }
  }
</script>
