<template>
    <v-container>
      <v-row v-if="dataLoading">
            <v-col xl="10" lg="10" cols="10" >
              <p class="page-title">{{$t('Listings.active_listings')}}</p>
              <p class="page-subtitle">{{$t('Listings.all_message')}}</p>
            </v-col>
        </v-row>
        <v-row v-if="dataLoading">
          <v-card flat class="mt-3 w-full">
            <div class="py-3 px-4 w-full">
              <v-toolbar flat class="mode-header">
                <v-toolbar-title>
                  {{$t('Listings.total_of')}} {{ items.length }} <span class="text-capitalize"> {{$t('Listings.properties')}}</span> - 
                  <a @click="selectAll()" class="cursor-pointer caption font-weight-light">({{$t('Export.SelectAll')}})</a>
                </v-toolbar-title>
                

                <v-spacer></v-spacer>

                <VueCustomTooltip class="ml-4" :label="$t('TOOLTIPS.UPDATE_TO_PLATFORM')" position="is-left">
                  <v-btn depressed color="secondary" class="square" @click="showUpdateExporter = !showUpdateExporter">
                      <i class="zwicon-refresh-double"></i>
                  </v-btn>
                </VueCustomTooltip>
                
                
                <ExporterMenu @click="openModalExporter" class="ml-4"/>
                

                <VueCustomTooltip class="ml-4" :label="$t('TOOLTIPS.ADD_PROPERTY')" position="is-left">
                  <v-btn depressed color="secondary" class="square" to="/properties/new">
                      <i class="zwicon-plus"></i>
                  </v-btn>
                </VueCustomTooltip>
                
                
              </v-toolbar>
              <div class="px-5 pb-4 listing-div w-full">
                <v-data-table
                        ref="listingDataTable"
                        :key="reloadDataTable"
                        class="table__minimal recent-properties elevation-0 td-data-table"
                        :loading="dataTableLoading"
                        :items="items"
                        :options.sync="pagination"
                        :headers="headers"
                        :loading-text="$t('Listings.loading')"
                        :hide-default-footer="false"
                        :calculate-widths="true"
                        :items-per-page="5"
                        v-model="selectedRows"
                        show-expand
                        :single-expand="singleExpand"
                        :expanded.sync="expanded"
                        show-select
                >
                    <template v-slot:[`item.image`]="{ item }">
                        <v-img v-if="true"
                          :src="$mediaFile('properties', item.id, item.media.cover)"
                          :lazy-src="$mediaFile('properties', item.id, item.media.cover)"
                          height="150"
                          width="150"
                          class="grey lighten-2 d-block rounded"
                        ></v-img>
                    </template>
                    <template v-slot:[`item.information`]="{ item }" >
                        <div class="main__info">
                          <VueCustomTooltip :label="$t('TOOLTIPS.NAVIGATION_TO_WEBSITE')" position="is-right">
                          <p class="mb-0">
                            <a target="_blank" :href="$frontConnection('listing') + item.id ">{{item.reference}}</a>
                          </p>
                          </VueCustomTooltip>
                          <p class="mb-0 font-weight-medium black--text listing-sub-sub-title">{{ item.information.title }}</p>
                          <p class="mb-0 mt-0 font-weight-medium black--text listing-sub-sub-title-address" v-if="getAdress(item) != ''">({{getAdress(item)}})</p>

                          <div 
                            v-if="
                            item.type_id == 7 || 
                            item.type_id == 8 || 
                            item.type_id == 9 || 
                            item.type_id == 10 || 
                            item.type_id == 11 || 
                            item.type_id == 12 ||
                            item.type_id == 13 ||
                            item.type_id == 14"
                          class="mt-2">
                            <div
                              v-if="
                            item.type_id == 8 || 
                            item.type_id == 9 || 
                            item.type_id == 10">
                            <p class="mb-0 mt-0 font-weight-medium black--text listing-sub-sub-title" >                            
                              <span class="span-bullet">&#11044;</span> {{$t('DEVELOPMENTS.LOTE_SIZE')}}:
                              {{ item.lote.area }} {{ item.lote.unit == 'm2'  ? $i18n.locale == 'pt' ? 'm2' : 'sq.ft' : item.lote.unit }} 
                              
                            </p>
                            <p class="mb-0 mt-0 font-weight-medium black--text listing-sub-sub-title" >                            
                              <span class="span-bullet font-weight-medium">&#11044;</span> {{$t('DEVELOPMENTS.LAND_SIZE')}}:
                              {{ item.land.area }} {{ item.land.unit == 'm2'  ? $i18n.locale == 'pt' ? 'm2' : 'sq.ft' : item.land.unit }} 
                              
                            </p>
                            </div>
                            <div
                              v-if="item.type_id == 7">
                              <p class="mb-0 mt-0 font-weight-medium black--text listing-sub-sub-title" >                            
                              <span class="span-bullet">&#11044;</span> {{$t('DEVELOPMENTS.FLOOR')}}:
                              {{ item.floor }}
                              
                            </p>
                            <p class="mb-0 mt-0 font-weight-medium black--text listing-sub-sub-title" >                            
                              <span class="span-bullet font-weight-medium">&#11044;</span> {{$t('DEVELOPMENTS.GROSS_AREA')}}:
                              {{ item.kpis.m2 }} {{ $i18n.locale == 'pt' ? 'm2' : 'sq.ft' }} 
                              
                            </p>
                            </div>
                            <div
                              v-if="item.type_id == 14">
                              <p class="mb-0 mt-0 font-weight-medium black--text listing-sub-sub-title" >                            
                              <span class="span-bullet">&#11044;</span> {{$t('DEVELOPMENTS.FLOOR')}}:
                              {{ item.floor }}
                              
                            </p>
                            <p class="mb-0 mt-0 font-weight-medium black--text listing-sub-sub-title" >                            
                              <span class="span-bullet font-weight-medium">&#11044;</span> {{$t('DEVELOPMENTS.LAND_SIZE')}}:
                              {{ item.kpis.m2 }} {{ $i18n.locale == 'pt' ? 'm2' : 'sq.ft' }} 
                              
                            </p>
                            </div>
                            <div v-if="item.type_id == 11 || item.type_id == 12 || item.type_id == 13" class="mt-2">
                            <p class="mb-0 mt-0 font-weight-medium black--text listing-sub-sub-title" >                            
                              <span class="span-bullet">&#11044;</span> {{$t('DEVELOPMENTS.GROSS')}}:
                              {{ item.kpis.m2 }}
                              
                            </p>
                            <p class="mb-0 mt-0 font-weight-medium black--text listing-sub-sub-title" >                            
                              <span class="span-bullet font-weight-medium">&#11044;</span> {{$t('DEVELOPMENTS.EXTERIOR')}}:
                              {{ item.kpis.exterior }}
                              
                            </p>
                          </div>
                          </div>
                          
                          <p class="listing-kpis mt-2" v-else>
                            {{ item.kpis.beds }} 
                            <v-icon class="mr-4" v-if="$screenWidth()=='xl'">mdi-bed-king-outline</v-icon>
                            <v-icon class="mr-4" small v-else>mdi-bed-king-outline</v-icon>  
                            {{ item.kpis.baths }}
                            <v-icon class="mr-4" v-if="$screenWidth()=='xl'">mdi-shower</v-icon> 
                            <v-icon class="mr-4" v-else small>mdi-shower</v-icon> 
                            {{ item.kpis.parking }} 
                            <v-icon v-if="$screenWidth()=='xl'">mdi-car-outline</v-icon>
                            <v-icon v-else small>mdi-car-outline</v-icon>
                          </p>
                          
                          <p class="ml-0 mb-0">
                            <v-row>
                                <!-- ERROR LOCATION -->
                                <VueCustomTooltip v-if="item.lat == null && item.len == null" :label="$t('TOOLTIPS.LOCATION_ERROR')" position="is-top">
                                  <v-chip
                                    class="ml-2"
                                    fab color="danger"
                                    x-small
                                    text-color="white"
                                  >
                                    <v-avatar>
                                      <v-icon small>mdi-earth-remove</v-icon>
                                    </v-avatar>
                                  </v-chip>
                                </VueCustomTooltip>
                                <!-- END ERROR LOCATION -->

                                <!-- PRICE TAG -->
                                <VueCustomTooltip v-if="item.collection == 1" :label="$t('TOOLTIPS.COLLECTION')" position="is-top">
                                  <v-chip
                                    class="ml-2"
                                    fab
                                    x-small
                                    color="#b8860b"
                                    text-color="white"
                                  >
                                    <v-avatar>
                                      <v-icon small>mdi-key-star</v-icon>
                                    </v-avatar>
                                  </v-chip>
                                </VueCustomTooltip>
                                  <!-- END PRICE TAG -->


                                  <!-- PRICE TAG -->
                                <VueCustomTooltip :label="item.hide_price == 1 ? $t('TOOLTIPS.PRICE_INVISIBILITY') : $t('TOOLTIPS.PRICE_VISIBILITY')" position="is-top">
                                  <v-chip
                                    class="ml-2"
                                    fab
                                    x-small
                                    :color="item.hide_price == 0 ? 'success darken-2' : 'orange darken-2'"
                                    text-color="white"
                                  >
                                    <v-avatar>
                                      <v-icon small v-if="item.hide_price==0">mdi-currency-eur</v-icon>
                                      <v-icon small v-else>mdi-currency-eur-off</v-icon>
                                    </v-avatar>
                                  </v-chip>
                                </VueCustomTooltip>
                                  <!-- END PRICE TAG -->

                                  <!-- ERROR CONDOMINIUM -->
                                <VueCustomTooltip v-if="item.condominium_id != null" :label="$t('TOOLTIPS.BELONGS_CONDO')" position="is-top">
                                  <v-chip
                                    class="ml-2"
                                    fab color="blue darken-2"
                                    x-small
                                    text-color="white"
                                  >
                                    <v-avatar>
                                      <v-icon small>mdi-home-city-outline</v-icon>
                                    </v-avatar>
                                  </v-chip>
                                </VueCustomTooltip>
                                <!-- END ERROR CONDOMINIUM -->
                            
                                <!-- HIGHLIGHTED TAG -->
                                <VueCustomTooltip :label="$t('TOOLTIPS.HIGHLIGHT_DESCRIPTION')" position="is-top">
                                  <v-chip
                                    v-if="item.highlighted == 1"
                                    class="ml-2"
                                    fab
                                    x-small
                                    color="yellow darken-3"
                                    text-color="white"
                                  >
                                    <v-avatar>
                                      <v-icon small>mdi-star-plus</v-icon>
                                    </v-avatar>                                    
                                  </v-chip>
                                </VueCustomTooltip>
                                <!-- END HIGHLIGHTED TAG -->
                            </v-row>
                            <v-row class="mt-4">
                                <!-- IDEALISTA TAG -->
                                <VueCustomTooltip :label="$t('Export.ExportedTo')" position="is-top" v-if="item.idealista_uuid != ''">
                                  <v-chip
                                    v-if="item.idealista_uuid != ''"
                                    class="ml-2"
                                    fab
                                    x-small
                                    color="primary"
                                    text-color="white"
                                  >
                                   {{$t('Export.Idelista')}}                                 
                                  </v-chip>
                                </VueCustomTooltip>
                                <!-- END IDEALISTA TAG -->

                                <!-- IMOVIRTUAL TAG -->
                                <VueCustomTooltip :label="$t('Export.ExportedTo')" position="is-top" v-if="item.imovirtual_uuid != '' && item.imovirtual_state == ''">
                                  <v-chip
                                    v-if="item.imovirtual_uuid != ''"
                                    class="ml-2"
                                    fab
                                    close
                                    @click:close="removeExportedProperty($t('Export.Imovirtual'), item.id, item.imovirtual_uuid)"
                                    x-small
                                    color="primary"
                                    text-color="white"
                                  >
                                    {{$t('Export.Imovirtual')}}                                     
                                  </v-chip>
                                </VueCustomTooltip>
                                <!-- END IMOVIRTUAL TAG -->
                                <!-- IMOVIRTUAL ERROR OR WAITING TAG -->
                                <VueCustomTooltip :label="item.imovirtual_state == 'error_notification' ? $t('Export.error_notification') : $t('Export.waiting_notification')" position="is-top" v-if="item.imovirtual_state == 'error_notification' || item.imovirtual_state == 'waiting_notification'">
                                  <v-chip
                                    v-if="item.imovirtual_state == 'error_notification' || item.imovirtual_state == 'waiting_notification'"
                                    class="ml-2"
                                    fab
                                    @click="getSingleErrorFile(item)"
                                    x-small
                                    :color="item.imovirtual_state == 'error_notification' ? 'danger' : 'blue darken-2'"
                                    text-color="white"
                                  >
                                    {{$t('Export.Imovirtual')}}    
                                    <v-icon right small v-if="item.imovirtual_state == 'error_notification'">
                                      mdi-alert
                                    </v-icon>      
                                    <v-icon right small v-else>
                                      mdi-sync-alert
                                    </v-icon>                            
                                  </v-chip>
                                </VueCustomTooltip>
                                <!-- END IMOVIRTUAL TAG -->

                                <!-- JAMES EDITION TAG -->
                                <VueCustomTooltip :label="$t('Export.ExportedTo')" position="is-top" v-if="item.jamesEdition_uuid != ''">
                                  <v-chip
                                    v-if="item.jamesEdition_uuid != ''"
                                    class="ml-2"
                                    fab
                                    close
                                    @click:close="removeExportedProperty($t('Export.James'), item.id, item.jamesEdition_uuid)"
                                    x-small
                                    color="primary"
                                    text-color="white"
                                  >
                                    {{$t('Export.James')}}                                       
                                  </v-chip>
                                </VueCustomTooltip>
                                <!-- END JAMES EDITION TAG -->
                                </v-row>
                            </p>
                        </div>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                        <div class="main__info" style="width: auto;">
                            <p class="mb-0 caption secondaryCard">
                                <span class="secondaryCard listing-secondary-objects">{{$t('Listings.type')}}</span>
                            </p>
                            <p v-if="item.information.location.type == ''">-</p>
                            <p v-else class="mb-0 text-limit-liner secondaryCard listing-sub-sub-title">{{ item.information.location.type }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                          <div class="main__info" style="width: auto;">
                            <p class="mb-0 caption">
                                <span class="secondaryCard listing-secondary-objects">{{$t('Listings.price')}}</span>
                            </p>
                            <p class="mb-0 text-limit-liner secondaryCard listing-sub-sub-title">{{ $currency(item.price)  }}</p>
                        </div>
                    </template>
                    <template v-slot:[`item.agent`]="{ item }" >
                        <p class="mb-0 caption">
                            <span class="secondaryCard listing-secondary-objects">{{$t('Listings.agent')}}</span>
                        </p>
                        <p v-if="item.agent == undefined || item.agent.name.first == ''">-</p>
                        <p v-else class="mb-0 text-limit-liner secondaryCard listing-sub-sub-title">{{ item.agent.name.first }} {{ item.agent.name.last }}</p>
                    </template>
                    <template v-slot:[`item.view`]="{ item }">
                        <VueCustomTooltip :label="$t('TOOLTIPS.EDIT_PROP')" position="is-left">
                        <v-btn :to="`/properties/edit/${item.id}/general`" color="#D8D8D8" class="pa-0 ma-0" depressed small>
                            <i class="zwicon-pencil"></i>
                        </v-btn>
                        </VueCustomTooltip>
                        <VueCustomTooltip :label="$t('TOOLTIPS.ARCHIVE_PROP')" position="is-left">
                        <v-btn @click="archiveThisProperty(item.id)" color="#D8D8D8" class="pa-0 ma-0 mt-2" depressed small>
                            <i class="zwicon-archive"></i>
                        </v-btn>
                        </VueCustomTooltip>
                        <VueCustomTooltip :label="$t('TOOLTIPS.REMOVE_PROP')" position="is-left">
                        <v-btn @click="confirmModal(item)" color="#D8D8D8" label class="pa-0 ma-0 mt-2" depressed small>
                          <i class="zwicon-trash"></i>
                        </v-btn>
                        </VueCustomTooltip>
                    </template>
                    <template v-slot:expanded-item="{ item }">
                      <td></td><td></td>
                      <td :colspan="headers.length">
                        <v-row>
                          <v-col cols="1" xl="1" md="2">
                            <label>{{$t('TOOLTIPS.OPTIONS')}}:</label>
                          </v-col>
                          <v-col cols="4" md="4" lg="4" xl="2"> 
                            <v-row>
                              <v-switch
                                v-if="(checkHighlightedCounter < 18 || item.highlighted == 1)"
                                v-model="item.highlighted"
                                inset
                                @change="quickAction(item.id, 'highlighted', item.highlighted)"
                                class="v-label"
                                :label="item.highlighted == 0 ? $t('TOOLTIPS.HIGHLIGHT_PUT') : $t('TOOLTIPS.HIGHLIGHT_DESCRIPTION')"
                              ></v-switch>
                              <template
                                v-else>
                                <v-switch
                                  disabled
                                  v-model="item.highlighted"
                                  inset
                                  @change="quickAction(item.id, 'highlighted', item.highlighted)"
                                  class="v-label"
                                  :label="item.highlighted == 0 ? $t('TOOLTIPS.HIGHLIGHT_PUT') : $t('TOOLTIPS.HIGHLIGHT_DESCRIPTION')"
                                ></v-switch>
                                <VueCustomTooltip :label="$t('HIGHLIGHT_EXCESS.ERROR')" position="is-right">
                                <v-icon color="error" class="ml-2 mt-4">
                                  mdi-alert-circle
                                </v-icon>
                                </VueCustomTooltip>
                              </template>
                              <v-switch
                                v-model="item.hide_price"
                                inset
                                @change="quickAction(item.id, 'price', item.hide_price)"
                                class="v-label"
                                :label="item.hide_price == 0 ? $t('TOOLTIPS.HIDE_PRICE') : $t('TOOLTIPS.PRICE_INVISIBILITY')"
                              ></v-switch>
                            </v-row>
                          </v-col>
                          <v-col cols="1" xl="1" md="2">
                            <label>{{$t('TOOLTIPS.BUSINESS')}}:</label>
                          </v-col>
                          <v-col cols="4" md="4" lg="4" xl="2">
                            <v-switch
                              v-model="item.for_sale"
                              inset
                              @change="quickAction(item.id, 'sale', item.for_sale)"
                              class="v-label"
                              :label="item.for_sale == 0 ? $t('PROPERTIES.FOR_SALE') : $t('PROPERTIES.FOR_RENT')"
                            ></v-switch>
                            <v-switch
                                v-model="item.low_cost"
                                inset
                                @change="quickAction(item.id, 'low_cost', item.low_cost)"
                                class="v-label"
                                :label="$t('TOOLTIPS.LOW_COST_DESCRIPTION')"
                              ></v-switch>
                          </v-col>
                          <v-col cols="1" md="2" xl="1">
                            <label>{{$t('TOOLTIPS.TAGS')}}:</label>
                          </v-col>
                          <v-col>
                            <group-propertytags 
                            @update-tag-id="updateTagID"
                            :form="serializeFalseForm(item)"
                            >
                          </group-propertytags>
                          </v-col>
                        </v-row>


                        <!-- BREVEMENTE NOVAS PLATAFORMAS DISPONIVEIS PARA ATUALIZAR INDIVIDUALMENTE -->


                        <v-row v-if="item.imovirtual_uuid != ''">
                          <label>{{$t('Export.Update')}}</label>
                          <v-chip
                            v-if="item.imovirtual_uuid != ''"
                            class="ml-2 mt-6"
                            fab
                            @click="updatePropertyAt($t('Export.Imovirtual'), item)"
                            small
                            color="success"
                            text-color="white"
                          >
                            {{$t('Export.Imovirtual')}}
                            
                            <v-icon right small>mdi-sync-alert</v-icon>                               
                          </v-chip>
                        </v-row>
                      </td>
                     
                    </template>
                </v-data-table>
              </div>
            </div>
          </v-card>
        </v-row>

        <v-dialog
          width="500"
          v-model="showModal">
            <DialogModalConfirmation
              @yes="removeProperty"
              @no="closeModal"
              :title="confirmationModal.title"
              :message="confirmationModal.message"
            />
        </v-dialog>

        <v-dialog
          width="500"
          v-model="informationModal.show">
          <DialogModalInformation 
            :message="informationModal.message" 
            @ok="informationModal.show = false"

          />
        </v-dialog>
        <v-dialog
          width="500"
          v-model="showModalExporter">
          <PortalExporterModal
            action="export"
            @close-me="closeExporterModal"
            @export="exportProperties"
          />
        </v-dialog>

        <v-dialog
          width="500"
          v-model="showUpdateExporter">
          <PortalExporterModal 
            action="update"
            @close-me="closeUpdateExporter"
            @export="updateExporter"
          />
        </v-dialog>


    </v-container>    
</template>

<script>
  import mixin from '@/models/properties/PropertiesModel'
  import exporter_model from '@/models/exporter/ExporterModel'
  import { Property, PropertyForm } from '@/store/structs/properties'
  import DialogModalConfirmation from '@/components/Modals/DialogModalConfirmation'
  import ExporterMenu from '@/components/listings/exporter/ExporterMenu'
  import PortalExporterModal from '@/components/listings/exporter/PortalExporterModal'
  import DialogModalInformation from '@/components/Modals/DialogModalInformation'

  

  export default {
    name: 'Listings',
    components: { DialogModalInformation, DialogModalConfirmation, ExporterMenu, PortalExporterModal },

    mixins: [mixin, exporter_model],
    props: ["applyFilters"],
    data: () => ({
      dataLoading: true,
      dataTableLoading: true,
      expanded: [],
      singleExpand: true,
      selectedRows:[],
      highlightedCounter: -1,
      pagination: {},
      headers: [
        { value: 'image', width: '6%', align: 'img' },
        { value: 'information', width: '60%', align: 'left'  },
        { value: 'description', width: '10%', align: 'start' },
        { value: 'price', width: '15%', align: 'left' },
        { value: 'agent', width: '19%', align: 'left' },
        { value: 'view', width: '10%', align: 'img' }
      ],
      showModal: false,
      informationModal:{
        show: false,
        message:''
      },
      confirmationModal:{
        title: '',
        message: ''
      },
      property: null,
      itemsPerPage: 5,
      reloadDataTable: 0,
      showModalExporter: false,
      showUpdateExporter: false,
    }),
    async created() {
      this.dataLoading = false
      await this.getProperties()
      this.$emit('properties-count', this.$store.state.propertiesModule.properties.length)

      // Colocar na página certa
      let p = this.$cookies.get('PROPERTY_LISTINGS_PAGINATION')
      if(p != null)
        this.pagination = p


      this.dataLoading = true
      //console.log("FILTROS")
      //console.log(this.applyFilters)
      let counter = 0
      this.$store.state.propertiesModule.properties.forEach(element => {
        if(element.highlighted == 1)
          counter++
      });
      this.highlightedCounter = counter

    },

    watch: {
      pagination: {
        async handler() {
          try {
            this.dataTableLoading = true
            this.$cookies.set('PROPERTY_LISTINGS_PAGINATION', this.pagination, "1d");
            await this.getProperties()
            this.dataTableLoading = false
          } catch (error) {
            this.dataTableLoading = false
          }
        },
        deep: true
      }
    },

    computed: {
      checkHighlightedCounter(){
        return this.highlightedCounter
      },
      items() {
        let retrievedData = []

        this.$store.state.propertiesModule.properties.forEach(element => {
          if(element.low_cost == 1)
            retrievedData.push(element)
        });

        let computeArray = []
        
        retrievedData.forEach(element => {
          if(element.archived != 1)
            computeArray.push(element)
        });


        if(this.applyFilters == null || this.filtersEmpty()){
           return Object.keys(computeArray).map(k =>
            new Property(computeArray[k])
          )
        }
         
        let filtersToApply = this.applyFilters
        
        let list = [] 
        retrievedData.map(function(value, key) {
          
          let flag = 1
          if(filtersToApply.baths > 0 && filtersToApply.baths < 6){
            if(value.bathrooms == filtersToApply.baths)
              flag = 0
            else flag = 1
          }else if(filtersToApply.baths >= 6){
            if(value.bathrooms >= filtersToApply.baths)
              flag = 0
            else flag = 1
          }else
            flag = 0

          if(filtersToApply.beds > 0 && filtersToApply.beds < 6){
            if(value.bedrooms == filtersToApply.beds)
              flag = 0
            else flag = 1
          }else if(filtersToApply.beds >= 6){
            if(value.bedrooms >= filtersToApply.beds)
              flag = 0
            else flag = 1
          }else
            flag = 0



          if(flag == 0 && filtersToApply.price != 0){
            if(value.price >= filtersToApply.price )
                flag = 0
            else flag = 1
          }

          
          if(flag == 0 && filtersToApply.name != ''){
            if(value.title != null){
              if(String(value.title).toUpperCase().match(filtersToApply.name))
                flag = 0
              else flag = 1
            }else flag = 1
          }

          if(flag == 0 && filtersToApply.type != 0){
            if(value.types_id != null){
              if(value.types_id == filtersToApply.type)
                flag = 0
              else flag = 1
            }else flag = 1
          }
          
          
          if(flag == 0){
            if(value.reference != null && filtersToApply.reference != ''){
              if(String(value.reference).match(filtersToApply.reference))
                flag = 0
              else flag = 1
            }else{
              if(filtersToApply.reference == "" && flag != 1)
                flag = 0
              else
                flag = 1
            }
          }

          if(flag == 0){
            if(filtersToApply.newDevelopment == 1){
              if(value.condominium_id =! null)
                flag = 0
              else flag = 1
            }
          }

          if(flag == 0){
            if(filtersToApply.collectionPrive == 1)
              if(value.collection != null){
                if(value.collection == 1) 
                  flag = 0
                else flag = 1
              }
          }

          if(flag == 0){
            if(filtersToApply.highlighted == 1){
              if(value.highlighted == 1)
                flag = 0
              else flag = 1
            }
          }

          if(flag == 0){
            if(filtersToApply.james_edition == 1){
              if(value.jamesEdition_uuid != '')
                flag = 0
              else flag = 1
            }
          }

          if(flag == 0){
            if(filtersToApply.idealista == 1){
              if(value.idealista_uuid != '')
                flag = 0
              else flag = 1
            }
          }

          if(flag == 0){
            if(filtersToApply.imovirtual == 1){
              if(value.imovirtual_uuid != '')
                flag = 0
              else flag = 1
            }
          }


          // CHECK PROBLEMATIC
          if(flag == 0){
            if(filtersToApply.problems == 1){
              
              if(value.lat == null && value.len == null)
                flag = 0
                else flag = 1
            }
          }

          // END PROBLEMATIC

          if(flag == 0)
            list.push(retrievedData[key])
        }
        ); 
        if(filtersToApply.locationUpdate != null){    
          
          let routeLevel = []  
          let areaLeve3Array = []  
          let areaLeve2Array = []  
          let areaDistrict = []

          // Procurar já a lista que pertence à cidade
          let cityList = []
          list.forEach(value => {
            if(value.route != null){
                filtersToApply.locationUpdate.forEach(addr => {
                  // GET DISTRICT LEVEL
                    if(addr.types[0] == "administrative_area_level_1"){
                        if(value.gmap_district != null)
                            if(value.gmap_district == addr.long_name || value.gmap_district.indexOf(addr.long_name) !== -1)
                              cityList.push(value)
                    }
                })
            }
          })

      cityList.forEach(value => {
        
        // LOCATION LEVELS        
          
            if(value.route != null){
              filtersToApply.locationUpdate.forEach(addr => {
                // GET SMALLEST LEVEL
                if(addr.types[0] == "route" || addr.types[0] == "neighborhood"){
                    if(value.route != null)
                        if(value.route == addr.long_name || value.route.indexOf(addr.long_name) !== -1)
                          routeLevel.push(value)
                }


                // GET SMALLEST LEVEL
                if(addr.types[0] == "administrative_area_level_3" || addr.types[0] == "locality"){
                    if(value.administrative_area_level_3 != null)
                        if(value.administrative_area_level_3 == addr.long_name || value.administrative_area_level_3.indexOf(addr.long_name) !== -1)
                          areaLeve3Array.push(value)
                }

                // GET SECOND SMALLEST LEVEL
                if(addr.types[0] == "administrative_area_level_2" || addr.types[0] == "locality"){
                    if(value.administrative_area_level_2 != null)
                        if(value.administrative_area_level_2 == addr.long_name || value.administrative_area_level_2.indexOf(addr.long_name) !== -1)
                          areaLeve2Array.push(value)
                }

                // GET DISTRICT LEVEL
                if(addr.types[0] == "administrative_area_level_1"){
                    if(value.gmap_district != null)
                        if(value.gmap_district == addr.long_name || value.gmap_district.indexOf(addr.long_name) !== -1)
                          areaDistrict.push(value)
                }
              })
            }
          
           });

           if(routeLevel.length != 0){
             return Object.keys(routeLevel).map(k =>
                new Property(routeLevel[k])
              )
           }
           if(areaLeve3Array.length != 0){
             return Object.keys(areaLeve3Array).map(k =>
                new Property(areaLeve3Array[k])
              )
           }
           if(areaLeve2Array.length != 0){
             return Object.keys(areaLeve2Array).map(k =>
                new Property(areaLeve2Array[k])
              )
           }
           if(areaDistrict.length != 0){
             return Object.keys(areaDistrict).map(k =>
                new Property(areaDistrict[k])
              )
           }
        }
          // END LOCATION LEVELS
          


        return Object.keys(list).map(k =>
            new Property(list[k])
          )
      }
    },
    methods: {
      async getSingleErrorFile(p){
        await this.downloadErrorFile({block:'imovirtual', uuid: p.imovirtual_uuid})

        
        let response = this.$store.state.exporterModule.exporter_log.data
        let error = 0
        try{
          var jsonPretty = JSON.stringify(JSON.parse(response),null,2);  
          jsonPretty = JSON.parse(jsonPretty, null, 2);
          console.log(jsonPretty)

          let theField = jsonPretty.data.validation[0].field 
          let theDetail = jsonPretty.data.validation[0].detail

          this.informationModal.message = this.$t('INBOX.MODAL.FIELD') + ": \n" + theField
          this.informationModal.message +=  "\n\n" + this.$t('INBOX.MODAL.PROBLEM') + ": " + "\n" + theDetail
          this.informationModal.show = true
        }catch(thrown){
          // Se deu erro, temos de indicar ao programa que houve um erro. Podemos precisar de mostrar um update
          error = 1
          console.log("ERREI")
        }

        // Vamos tentar retornar simplesmente a mensagem normal do update
        if(error == 1){
          try{
            var jsonPretty = JSON.stringify(JSON.parse(response),null,2);  
            jsonPretty = JSON.parse(jsonPretty, null, 2);
            console.log(jsonPretty)

            let theAction = jsonPretty.data.detail
            let theDescription = jsonPretty.data.moderation.description == '' ? this.$t('INBOX.MODAL.UPS') : jsonPretty.data.moderation.description
            let theReason = jsonPretty.data.moderation.reason == '' ? this.$t('INBOX.MODAL.SEEMS') + "\n\n" + this.$t('INBOX.MODAL.IMOVIRTUAL') + p.imovirtual_uuid : jsonPretty.data.moderation.reason

            this.informationModal.message = this.$t('INBOX.MODAL.ACTION') + ": \n" + theAction
            this.informationModal.message +=  "\n\n" + this.$t('INBOX.MODAL.DESCRIPTION') + ": " + "\n" + theDescription
            this.informationModal.message +=  "\n\n" + this.$t('INBOX.MODAL.REASON') + ": " + "\n" + theReason
            this.informationModal.show = true
          }catch(thrown){
            // Se deu erro, temos de indicar ao programa que houve um erro. Podemos precisar de mostrar um update
            error = 2
            console.log(thrown)
          }
        }

        if(error == 2){
          let reference = p.imovirtual_uuid
          if(reference == '' || reference == null)
            reference = p.reference

          this.informationModal.message = this.$t('INBOX.MODAL.IMPOSSIBLE') + "\n\n" + this.$t('INBOX.MODAL.IMOVIRTUAL') + reference
          this.informationModal.show = true
        }
              
      },
      getAdress(i){
        if(i.administrative_area_level_2 == '' || i.administrative_area_level_2 == null)
            return ''
        
        if(i.political != null)
            return i.political + ', ' + i.administrative_area_level_3

        if(i.route != '' && i.route != null)
          return i.route + ', ' + i.administrative_area_level_2 + ', ' + i.administrative_area_level_3

        return i.administrative_area_level_2 + ', ' + i.administrative_area_level_3
      },
      updateTagID(tag, id){
        this.quickAction(id, 'tag', tag)
      },
      serializeFalseForm(item){
        let ob = new PropertyForm({})
        ob.id = item.id
        ob.tag_id = item.tag_id
        return ob
      },
      async quickAction(id, block, value){
        await this.propertyQuickChange({id: id, block: block, value: value})
        if(block == 'highlighted'){
          let auxiliar_items = this.items
          let counter = 0
          auxiliar_items.forEach(element => {
            if(element.highlighted == 1)
              counter++
          });

          this.highlightedCounter = counter
        }
        if(block == 'low_cost'){
          await this.getProperties()
        }
      },
      async removeExportedProperty(place, id, uuid){
        await this.deleteFromPlatform({place: place, id: id, uuid: uuid, url: btoa(window.location.href)})
        await this.getProperties()

      },
      selectAll(){
        if(this.selectedRows.length > 0){
          this.selectedRows = []
          this.$refs.listingDataTable.options.itemsPerPage = this.itemsPerPage
          return
        }
        this.items.forEach(element => {
          this.selectedRows.push(element)
        });

        
        this.$refs.listingDataTable.options.itemsPerPage = -1
        this.dataTableLoading = false
      },
      async updateExporter(place){
        // Update in all platforms available
        // Imovirtual
        // Idealista
        // James Edition

        // Imovirtual
        let properties = this.items
        let IMOVIRTUAL_myArray = [];
        let IMOVIRTUAL_myArray_error_notification = [];

        let IDEALISTA_myArray = []

        let JAMES_EDITION_myArray = []


        properties.forEach(element => {

          // Propriedades para o Imovirtual
          if(element.imovirtual_state != 'error_notification' || element.imovirtual_state != 'waiting_notification')
            IMOVIRTUAL_myArray.push(element.id)
          else IMOVIRTUAL_myArray_error_notification.push(element.id)

          // Propriedades para o Idealista
          if(element.idealista_uuid != ''){
            IDEALISTA_myArray.push(element.id)
          }

          // Propriedades para a James Edition
          if(element.jamesEdition_uuid != ''){
            JAMES_EDITION_myArray.push(element.id)
          }

        });

        if(place == this.$t('Export.Imovirtual')){
          await this.exportationProcess({place: place, properties: IMOVIRTUAL_myArray_error_notification, url: btoa(window.location.href) });

          await this.updateExportationProcess({place: place, id: IMOVIRTUAL_myArray, uuid: IMOVIRTUAL_myArray, url: btoa(window.location.href)})
        }

       
        if(place == this.$t('Export.Idelista')){
          await this.exportationProcess({place: place, properties: IDEALISTA_myArray, url: btoa(window.location.href) });
        }

        if(place == this.$t('Export.James')){
          await this.exportationProcess({place: place, properties: JAMES_EDITION_myArray, url: btoa(window.location.href) });
        }

      },
      async updatePropertyAt(place, p){
        if(p.imovirtual_state == 'error_notification'){
          let myArray = [];
          myArray.push(p.id)
          console.log("EXPORTAR PRIMEIRA VEZ");
          await this.exportationProcess({place: place, properties: myArray, url: btoa(window.location.href) });
        }else{
          console.log("EXPORTAR O QUE JA ESTAVA")
          await this.updateExportationProcess({place: place, id: p.id, uuid: p.imovirtual_uuid, url: btoa(window.location.href)})
        }
      },
      async exportProperties(place){
        let myArray = [];


        this.selectedRows.forEach(element => {
          myArray.push(element.id)
        });
        
        await this.exportationProcess({place: place, properties: myArray, url: btoa(window.location.href) });
        this.exportersModal = false



        this.selectedRows = []
        console.log(" E ACABOU ");

        
        this.closeExporterModal()
      },
      closeExporterModal(){
          this.showModalExporter = false
      },
      closeUpdateExporter(){
          this.showUpdateExporter = false
      },

      
      openModalExporter(){
          if(this.selectedRows.length == 0){
            this.informationModal.message = this.$t('Export.SelectData')
            this.informationModal.show = true
            return
          }
          this.showModalExporter = true
      },
      filtersEmpty(){
        let emptyValue = true

        if(this.applyFilters.baths != 0) 
          emptyValue = false
        
        if(this.applyFilters.beds != 0) 
          emptyValue = false

        if(this.applyFilters.collectionPrive != 0) 
          emptyValue = false

        if(this.applyFilters.highlighted != 0) 
          emptyValue = false

        if(this.applyFilters.problems != 0) 
          emptyValue = false

        if(this.applyFilters.price != 0) 
          emptyValue = false

        if(this.applyFilters.name != '') 
          emptyValue = false

        if(this.applyFilters.reference != '') 
          emptyValue = false

        if(this.applyFilters.locationUpdate != '') 
          emptyValue = false
          
        if(this.applyFilters.type != 0) 
          emptyValue = false


        if(this.applyFilters.newDevelopment != 0) 
          emptyValue = false

        if(this.applyFilters.james_edition != 0)
          emptyValue = false

        if(this.applyFilters.idealista != 0)
          emptyValue = false

        if(this.applyFilters.imovirtual != 0)
          emptyValue = false
          

        return emptyValue
      },
      confirmModal(i){
        this.property = i
        this.confirmationModal.title = this.$t('REMOVING_MODAL.TITLE') + i.reference
        this.confirmationModal.message = this.$t('REMOVING_MODAL.DESCRIPTION') + "\n\n" + this.$t('REMOVING_MODAL.REFERENCE') + i.reference +"\n" + this.$t('REMOVING_MODAL.REMOVING_DESCRIPTION') + i.information.title + "\n\n" + this.$t('REMOVING_MODAL.CONTINUE')
        this.showModal = true
      },
      closeModal(){
        this.showModal = false
      },
      removeProperty: async function(){
        await this.deleteProperty({id: this.property.id})
        this.closeModal()
        this.reloadDataTable++
      },
      archiveThisProperty: async function (id){
        await this.archiveProperty({id: id})
        this.$emit('update-view')
        this.reloadDataTable++
      },
      unArchiveThisProperty: async function(id){
        await this.unArchiveProperty({id: id})
        this.$emit('update-view')
        this.reloadDataTable++
      },  
    }

  }
</script>
