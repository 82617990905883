<template>
    <v-container fluid class="pt-0">
        
        <v-row>
            <v-col xl="10" lg="8" cols="8" >
              <p class="page-title text-capitalize">{{ $t('WHO_WE_ARE.TITLE') }}</p>
              <p class="page-subtitle"> {{ $t('WHO_WE_ARE.DESCRIPTION_WHO_WE_ARE') }}</p>
            </v-col>
        </v-row>
        <v-layout row wrap class="v-manage-property">
            <v-col cols="9">
                <v-card flat :loading="loadingItem">
                    
                    <v-toolbar flat class="mode-header" style="background-color: #BE5E3B !important;">
                        <v-toolbar-title
                                v-for="(language, index) in $t('LANGUAGES')"
                                :key="index"
                                class="cursor-pointer mr-5"
                                :class="language.id === locale ? 'white--text font-weight-medium' : 'darkgray--text font-weight-light unselected-language-style'"
                                @click="locale = language.id"
                        >
                            {{ language.name }}
                        </v-toolbar-title>
                    </v-toolbar>
                    <div class="ma-8 mb-0 pb-0">
                        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
                            <form id="who_we_are-form" @submit.prevent="handleSubmit(submit)">
                                <v-row>
                                    <!-- Translatable content -->
                                    <v-col cols="12" class="pt-4 pb-0" v-if="loadingItem">
                                        <v-window
                                                v-model="locale"
                                                vertical
                                        >
                                            <v-window-item
                                                    v-for="language in $t('LANGUAGES')"
                                                    :key="language.slug"
                                                    :ref="`window_${language.id}`"
                                                    :class="`window_${language.id}`"
                                            >

                                            <div  v-bind:style="language.slug=='pt' ? 'border-color:#4CAF50;border-top-style:solid;border-radius: 5px;' : ''">

                                                  <!-- whoWeAre edscription -->
                                                  <input-textarea
                                                          :name="`name[${language.slug}]`"
                                                          :label="$t('WHO_WE_ARE.DESCRIPTION')"
                                                          :rows="6"
                                                          class="pb-1"
                                                          v-model="whoWeAre.description[language.slug]"
                                                          :fill="whoWeAre.description[language.slug]"
                                                          @blur="onBlurAction"
                                                  >
                                                  </input-textarea>
                                                  <!-- end -->
                                            </div>  

                                            </v-window-item>
                                        </v-window>
                                    </v-col>
                                </v-row>
                            </form>
                        </ValidationObserver>
                    </div>
                    <v-card flat>
                    <!-- Saving Action -->
                    <v-card-actions class="light-bg py-4 px-4">
                       <v-spacer></v-spacer>
                        <v-btn depressed color="primary" form="who_we_are-form" type="submit" @click="supportValidation">
                            {{$t('SERVICES.SAVE')}}
                        </v-btn>
                    </v-card-actions>
                    <!-- end -->
                  
                  </v-card>
                </v-card>
            </v-col>
        </v-layout>
    </v-container>
</template>

<script>
  import mixin from '@/models/whoweare/WhoWeAreModel'
  import { WhoWeAreForm } from '@/store/structs/whoweare'
  
  export default {
    name: 'SetWhoWeAre',
    mixins: [mixin],
    data(){
        return{
            loadingItem: false,
            locale: 0,
            whoWeAre: WhoWeAreForm
        }
    },
    async created(){
        await this.getWhoWeAre()
        this.whoWeAre = new WhoWeAreForm(this.$store.state.whoweareModule.who_we_are)
        this.loadingItem = true
    },
    methods:{
        supportValidation() {
        let f = this.whoWeAre
        let l = null
        this.$t('LANGUAGES').forEach(function(entry) {
          if (f.title[entry.slug].length < 5) {
            l = entry.id
          }
        })
        this.locale = l
      },
      onBlurAction(){
          this.submit()
      },
      async submit() {
        const isValid = await this.$refs.observer.validate()
        if (isValid) {
          await this.save(this.whoWeAre)
        }
      }
    }
  }
</script>
