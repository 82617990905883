import { mapActions } from 'vuex'

const mixin = {

  created: function() {
    console.debug(`Districts action called.`)
  },

  methods: {
    ...mapActions(['getDistricts', 'getDistrict', 'editDistrict', 'saveDistrict', 'deleteDistrict']),

    async deleteItem(item) {
      try {
          this.dataLoading = true

          await this.deleteDistrict(item)
          await this.getDistricts()

          this.dataLoading = false
    

      } catch (error) {
        this.dataLoading = false
      }

      return true
    },
    async save(form) {
      try {
        // Updating item
        if (form.id !== null) {
          await this.editDistrict({ 'data': form })
          await this.getDistricts()
        } else {
          // Creating new item
          await this.saveDistrict({ 'data': form })
          await this.getDistricts()
        }
      } catch (error) {
        this.dataTableLoading = false
      }
    }
  }
}

export default mixin