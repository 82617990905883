export class PropertyStatistic {
    constructor(object = null) {
      this.id = object.id || null,
      this.tag_id = object.tag_id || null,
      this.price = object.price || 0,
      this.condominium_id = object.condominium_id || null
      this.reference = object.reference || ''
      this.archived = object.archived || 0
      this.information = {
        title: object.title || '-',
        description: object.description || '-',
        location: {
          district: object.district.name || '',
          type: object.type.name || ''
        }
      }
      this.kpis = {
        beds: object.bedrooms || '-',
        baths: object.bathrooms || '-',
        parking: object.parking || '-',
        m2: object.gross_area || '-',
      }
      this.media = {
        id: object.id || null,
        cover: object.media.cover || 'https://www.keystoneproperties.net/wp-content/themes/keystoneproperties/img/property-placeholder.png'
      }
      this.agent = {
        name: {
          first: object.agent.first_name || '',
          last: object.agent.last_name || ''
        },
        avatar: object.agent.avatar || 'http://placehold.jp/20/ffffff/202225/900x250.png?text='
      }
      this.floor = object.floor || 0
      this.exterior_area = object.exterior_area || 0
      this.tag = object.tag || ''
      this.fraction = object.fraction || ''
      this.hide_price = object.hide_price
      this.total_views = object.total_views
    }
  }

  export class CondominiumStatistic {
    constructor(object = null)  
    {
      this.id = object.id
      this.starting_price = object.starting_price
      this.tag_id = object.tag_id
      this.agent_id = object.agent_id
      this.district_id  = object.district_id
      this.condo_where  = object.condo_where ?? 'no description provided'
      this.cover = object.cover || 'https://www.keystoneproperties.net/wp-content/themes/keystoneproperties/img/property-placeholder.png'
      this.information = {
        title: object.title || '-',
        slogan: object.slogan || '-',
        description: object.description || '-'
      }
      this.archived = object.archived
      this.from_gross_area  = object.from_gross_area == -1 ? '-' : object.from_gross_area
      this.to_gross_area  = object.to_gross_area == -1 ? '-' : object.to_gross_area
      this.from_bedrooms  = object.from_bedrooms == -1 ? '-' : object.from_bedrooms
      this.to_bedrooms  = object.to_bedrooms == -1 ? '-' : object.to_bedrooms
      this.from_parking  = object.from_parking == -1 ? '-' : object.from_parking
      this.to_parking  = object.to_parking == -1 ? '-' : object.to_parking
      this.hide_price  = object.hide_price
      this.total_views  = object.total_views
    }
  }

  
export default { PropertyStatistic, CondominiumStatistic }